<template>
  <div class="operations_sections mt-3 mb-3">
    <div class="validated_basket">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 13.875C7.245 13.875 5.8125 12.4425 5.8125 10.6875C5.8125 10.38 6.0675 10.125 6.375 10.125C6.6825 10.125 6.9375 10.38 6.9375 10.6875C6.9375 11.8275 7.86 12.75 9 12.75C10.14 12.75 11.0625 11.8275 11.0625 10.6875C11.0625 10.38 11.3175 10.125 11.625 10.125C11.9325 10.125 12.1875 10.38 12.1875 10.6875C12.1875 12.4425 10.755 13.875 9 13.875Z"
          fill="#49CF48"
        />
        <path
          d="M3.89241 4.78508C3.74991 4.78508 3.59991 4.72508 3.49491 4.62008C3.27741 4.40258 3.27741 4.04258 3.49491 3.82508L6.21741 1.10258C6.43491 0.885078 6.79491 0.885078 7.01241 1.10258C7.22991 1.32008 7.22991 1.68008 7.01241 1.89758L4.28991 4.62008C4.17741 4.72508 4.03491 4.78508 3.89241 4.78508Z"
          fill="#49CF48"
        />
        <path
          d="M14.1076 4.78508C13.9651 4.78508 13.8226 4.73258 13.7101 4.62008L10.9876 1.89758C10.7701 1.68008 10.7701 1.32008 10.9876 1.10258C11.2051 0.885078 11.5651 0.885078 11.7826 1.10258L14.5051 3.82508C14.7226 4.04258 14.7226 4.40258 14.5051 4.62008C14.4001 4.72508 14.2501 4.78508 14.1076 4.78508Z"
          fill="#49CF48"
        />
        <path
          d="M15.1575 7.9502C15.105 7.9502 15.0525 7.9502 15 7.9502H14.8275H3C2.475 7.9577 1.875 7.9577 1.44 7.5227C1.095 7.1852 0.9375 6.6602 0.9375 5.8877C0.9375 3.8252 2.445 3.8252 3.165 3.8252H14.835C15.555 3.8252 17.0625 3.8252 17.0625 5.8877C17.0625 6.6677 16.905 7.1852 16.56 7.5227C16.17 7.91269 15.645 7.9502 15.1575 7.9502ZM3.165 6.8252H15.0075C15.345 6.8327 15.66 6.8327 15.765 6.7277C15.8175 6.6752 15.93 6.4952 15.93 5.8877C15.93 5.0402 15.72 4.9502 14.8275 4.9502H3.165C2.2725 4.9502 2.0625 5.0402 2.0625 5.8877C2.0625 6.4952 2.1825 6.6752 2.2275 6.7277C2.3325 6.8252 2.655 6.8252 2.985 6.8252H3.165Z"
          fill="#49CF48"
        />
        <path
          d="M11.1676 17.0626H6.64506C3.96006 17.0626 3.36006 15.4651 3.12756 14.0776L2.07006 7.59006C2.01756 7.28256 2.22756 6.99756 2.53506 6.94506C2.83506 6.89256 3.12756 7.10256 3.18006 7.41006L4.23756 13.8901C4.45506 15.2176 4.90506 15.9376 6.64506 15.9376H11.1676C13.0951 15.9376 13.3126 15.2626 13.5601 13.9576L14.8201 7.39506C14.8801 7.08756 15.1726 6.88506 15.4801 6.95256C15.7876 7.01256 15.9826 7.30506 15.9226 7.61256L14.6626 14.1751C14.3701 15.6976 13.8826 17.0626 11.1676 17.0626Z"
          fill="#49CF48"
        />
      </svg>
      <span>{{ completedOrders }}</span>
    </div>
    <div class="callCenter">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.39 4.2373L10.065 2.4373C9.76504 2.1373 9.09004 1.9873 8.64004 1.9873H5.79004C4.89004 1.9873 3.91504 2.6623 3.69004 3.5623L1.89004 9.0373C1.51504 10.0873 2.19004 10.9873 3.31504 10.9873H6.31504C6.76504 10.9873 7.14004 11.3623 7.06504 11.8873L6.69004 14.2873C6.54004 14.9623 6.99004 15.7123 7.66504 15.9373C8.26504 16.1623 9.01504 15.8623 9.31504 15.4123L12.39 10.8373"
          stroke="#F8AB26"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M16.2151 4.2375V11.5875C16.2151 12.6375 15.7651 13.0125 14.7151 13.0125H13.9651C12.9151 13.0125 12.4651 12.6375 12.4651 11.5875V4.2375C12.4651 3.1875 12.9151 2.8125 13.9651 2.8125H14.7151C15.7651 2.8125 16.2151 3.1875 16.2151 4.2375Z"
          stroke="#F8AB26"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>{{ pendingOrders }}</span>
    </div>
    <div class="removed_basket">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0425 13.02L7.97998 10.9575"
          stroke="#FF2C52"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.02 10.98L7.95752 13.0425"
          stroke="#FF2C52"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.60758 1.5L3.89258 4.2225"
          stroke="#FF2C52"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.3926 1.5L14.1076 4.2225"
          stroke="#FF2C52"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.5 5.8877C1.5 4.5002 2.2425 4.3877 3.165 4.3877H14.835C15.7575 4.3877 16.5 4.5002 16.5 5.8877C16.5 7.5002 15.7575 7.3877 14.835 7.3877H3.165C2.2425 7.3877 1.5 7.5002 1.5 5.8877Z"
          stroke="#FF2C52"
          stroke-width="1.5"
        />
        <path
          d="M2.625 7.5L3.6825 13.98C3.9225 15.435 4.5 16.5 6.645 16.5H11.1675C13.5 16.5 13.845 15.48 14.115 14.07L15.375 7.5"
          stroke="#FF2C52"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
      <span>{{ canceledOrders }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OngoingOperations',
  props: {
    customerEmail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listItems: [],
      completedOrders: Number,
      pendingOrders: Number,
      canceledOrders: Number,
    };
  },
  methods: {
    async getOperationsData() {
      this.allOrders = 0;
      this.completedOrders = 0;
      this.pendingOrders = 0;
      this.canceledOrders = 0;
      let custEmail = this.customerEmail;
      await axios
        .get(
          `https://convergence-app-logic.k8s.satoripop.io/order/getorders?email=${custEmail}`,
          {
            params: this.axiosParams,
          }
        )
        .then(response => {
          this.listItems = response.data;
          for (let i = 0; i < this.listItems.length; i++) {
            this.allOrders++;
            if (this.listItems[i].status === 'complete') {
              this.completedOrders++;
            } else if (this.listItems[i].status === 'pending') {
              this.pendingOrders++;
            } else {
              this.canceledOrders++;
            }
          }
        });
    },
  },
  computed: {
    axiosParams() {
      const params = new URLSearchParams();
      params.append('currentpage', '0');
      params.append('pagesize', '20');
      return params;
    },
  },
  mounted() {
    this.getOperationsData();
  },
};
</script>

<style scoped lang="scss">
.operations_sections {
  display: flex;
  justify-content: space-evenly;
}
.validated_basket {
  background: #ffffff;
  border: 1px solid #49cf48;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 85px;
  padding-top: 5px;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #49cf48;
    margin-left: 5px;
    margin-top: 3.4px;
  }
}
.callCenter {
  background: #ffffff;
  border: 1px solid #f8ab26;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 85px;
  padding-top: 5px;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #f8ab26;
    margin-left: 5px;
    margin-top: 3.4px;
  }
}
.removed_basket {
  background: #ffffff;
  border: 1px solid #ff2c52;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 85px;
  padding-top: 5px;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ff2c52;
    margin-left: 5px;
    margin-top: 3.4px;
  }
}
</style>
