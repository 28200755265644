<template>
    <div class="column content-box">
        <woot-button
                color-scheme="primary"
                icon="add-circle"
                @click="openAddPopup()"
                class="float-right mt-2"
        >
            {{ $t('ATTRIBUTES_MGMT.HEADER_BTN_TXT') }}
        </woot-button>
        <custom-attribute />
        <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
            <add-attribute :on-close="hideAddPopup" />
        </woot-modal>
    </div>
</template>

<script>
import AddAttribute from './AddAttribute';
import CustomAttribute from './CustomAttribute';
export default {
    components: {
        AddAttribute,
        CustomAttribute,
    },
    data() {
        return {
            showAddPopup: false,
        };
    },
    methods: {
        openAddPopup() {
            this.showAddPopup = true;
        },
        hideAddPopup() {
            this.showAddPopup = false;
        },
    },
};
</script>

<style></style>
