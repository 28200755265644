<template>
  <div class="contact-conversation--panel">
    <div v-if="!uiFlags.isFetching" class="contact-conversation__wrap" style="padding: 2rem;background-image: linear-gradient( 162deg, hsl(240deg 62% 29%) 0%, hsl(248deg 46% 36%) 22%, hsl(251deg 34% 43%) 36%, hsl(254deg 27% 51%) 44%, hsl(255deg 28% 59%) 48%, hsl(256deg 28% 67%) 49%, hsl(257deg 28% 75%) 48%, hsl(258deg 29% 83%) 48%, hsl(259deg 29% 91%) 51%, hsl(0deg 0% 100%) 99% );">

        <div class="text-center">
            <h5 class="font-inter balance_txt">Total Balance </h5>
            <div>
              <span  class="font-inter balance_value">£1,855.250 </span>
            </div>
        </div>

        <div class="mt-3">
            <div class="container" :style="{'background-image': 'url(' + require('../../../assets/images/bg.png') + ')'}">
                <header>
        <span class="logo">
            <img alt="" :src="require('../../../assets/images/logo.png')">
          <h5>Master Card</h5>
        </span>
                </header>

                <div class="card-details">
                    <div class="name-number">
                        <h6>Card Number</h6>
                        <h5 class="number">8050 5040 2030 3020</h5>
                        <h5 class="name">Prem Kumar Shahi</h5>
                    </div>
                    <div class="valid-date">
                        <h6>Valid Thru</h6>
                        <h5>05/28</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <h4 class=" text-center transaction_txt">Transactions</h4>
            <h6 class="mt-2 mb-2 text-primary">Today</h6>
            <div class="d-flex mb-2 justify-space-between">
                <div>
                    <svg class="mb--5" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.74 22.75H7.64001C7.33001 22.75 7.05002 22.56 6.94002 22.26C6.83002 21.96 6.91001 21.64 7.15001 21.43C7.85001 20.81 8.26001 19.92 8.26001 18.99C8.26001 17.2 6.80001 15.74 5.01001 15.74C4.27001 15.74 3.57001 15.99 2.98001 16.46C2.75001 16.64 2.45002 16.67 2.19002 16.55C1.93002 16.43 1.77002 16.16 1.77002 15.87V11.5C1.77002 9.01 3.79001 6.98999 6.28001 6.98999H17.76C20.25 6.98999 22.27 9.01 22.27 11.5V12.94C22.27 13.35 21.93 13.69 21.52 13.69H19.5C19.15 13.69 18.83 13.82 18.6 14.06L18.59 14.07C18.31 14.34 18.18 14.71 18.21 15.09C18.27 15.75 18.9 16.28 19.62 16.28H21.52C21.93 16.28 22.27 16.62 22.27 17.03V18.22C22.25 20.73 20.23 22.75 17.74 22.75ZM9.18001 21.25H17.74C19.4 21.25 20.75 19.9 20.75 18.24V17.8H19.6C18.09 17.8 16.81 16.68 16.69 15.24C16.61 14.42 16.91 13.61 17.51 13.02C18.03 12.49 18.73 12.2 19.48 12.2H20.75V11.51C20.75 9.85001 19.4 8.5 17.74 8.5H6.26001C4.60001 8.5 3.25002 9.85001 3.25002 11.51V14.59C3.81002 14.37 4.40002 14.25 5.00002 14.25C7.62002 14.25 9.75002 16.38 9.75002 19C9.75002 19.79 9.55001 20.57 9.18001 21.25Z" fill="#292D32"/>
                        <path d="M2.5 12.2601C2.09 12.2601 1.75 11.9201 1.75 11.5101V7.84011C1.75 6.35011 2.68001 4.99005 4.07001 4.47005L12.01 1.47005C12.83 1.17005 13.75 1.28011 14.46 1.78011C15.17 2.28011 15.6 3.09009 15.6 3.95009V7.75008C15.6 8.16008 15.26 8.50008 14.85 8.50008C14.44 8.50008 14.1 8.16008 14.1 7.75008V3.95009C14.1 3.57009 13.92 3.23009 13.61 3.01009C13.29 2.79009 12.9 2.74008 12.54 2.87008L4.60001 5.87008C3.78001 6.18008 3.25 6.95011 3.25 7.84011V11.5101C3.25 11.9201 2.91 12.2601 2.5 12.2601Z" fill="#292D32"/>
                        <path d="M19.6 17.7999C18.09 17.7999 16.81 16.6799 16.69 15.2399C16.61 14.4099 16.91 13.5999 17.51 13.0099C18.02 12.4899 18.72 12.2 19.47 12.2H21.55C22.54 12.23 23.3 13.0099 23.3 13.9699V16.03C23.3 16.99 22.54 17.7699 21.58 17.7999H19.6ZM21.53 13.7H19.48C19.13 13.7 18.81 13.8299 18.58 14.0699C18.29 14.3499 18.15 14.7299 18.19 15.1099C18.25 15.7699 18.88 16.2999 19.6 16.2999H21.56C21.69 16.2999 21.81 16.18 21.81 16.03V13.9699C21.81 13.8199 21.69 13.71 21.53 13.7Z" fill="#292D32"/>
                        <path d="M14 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z" fill="#292D32"/>
                        <path d="M5 23.75C2.38 23.75 0.25 21.62 0.25 19C0.25 17.54 0.899999 16.19 2.03 15.29C2.87 14.62 3.93 14.25 5 14.25C7.62 14.25 9.75 16.38 9.75 19C9.75 20.36 9.16 21.66 8.14 22.56C7.26 23.33 6.15 23.75 5 23.75ZM5 15.75C4.26 15.75 3.56 16 2.97 16.47C2.2 17.08 1.75 18.01 1.75 19C1.75 20.79 3.21 22.25 5 22.25C5.78 22.25 6.53999 21.96 7.14999 21.44C7.84999 20.82 8.25 19.94 8.25 19C8.25 17.21 6.79 15.75 5 15.75Z" fill="#292D32"/>
                        <path d="M3.99988 20.75C3.74988 20.75 3.49988 20.62 3.35988 20.39C3.14988 20.03 3.25988 19.57 3.61988 19.36L4.50988 18.83V17.75C4.50988 17.34 4.84988 17 5.25988 17C5.66988 17 6.00988 17.34 6.00988 17.75V19.25C6.00988 19.51 5.86988 19.76 5.64988 19.89L4.39988 20.64C4.26988 20.72 4.12988 20.75 3.99988 20.75Z" fill="#292D32"/>
                    </svg>
                    <span class="service_txt">Internet Service</span>
                </div>
                <div class="reduce_price">
                    -$201.2
                </div>

            </div>
            <div class="d-flex mb-2 justify-space-between">
                <div>
                    <svg class="mb--5" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5598 22.71C9.47977 22.71 8.38977 22.4 7.54977 21.77L3.26978 18.57C2.13978 17.73 1.25977 15.96 1.25977 14.54V7.12998C1.25977 5.59998 2.37978 3.96997 3.81978 3.42997L8.79977 1.55997C9.78977 1.18997 11.3398 1.18997 12.3298 1.55997L17.2998 3.42997C18.4698 3.86997 19.4598 5.03997 19.7798 6.33997C19.8298 6.55997 19.7798 6.79998 19.6398 6.97998C19.4998 7.15998 19.2798 7.26996 19.0498 7.26996L11.1398 7.27997C9.86977 7.35997 9.55977 7.56999 9.55977 9.41999V14.85C9.55977 16.76 9.80977 17 11.7498 17H17.6698C17.9798 17 18.2598 17.19 18.3698 17.48C18.4798 17.77 18.3998 18.1 18.1698 18.31C18.0598 18.4 17.9698 18.49 17.8598 18.57L13.5798 21.78C12.7198 22.4 11.6398 22.71 10.5598 22.71ZM10.5598 2.78998C10.1098 2.78998 9.64978 2.84998 9.31978 2.97998L4.33977 4.84998C3.49977 5.16998 2.74977 6.23997 2.74977 7.14997V14.56C2.74977 15.5 3.40977 16.82 4.15977 17.38L8.43977 20.58C9.58977 21.43 11.5198 21.43 12.6698 20.58L15.4398 18.51H11.7398C8.97978 18.51 8.04977 17.59 8.04977 14.86V9.42997C8.04977 7.29997 8.55977 5.94998 11.0898 5.78998L11.7398 5.77997H17.8898C17.5898 5.34997 17.1898 5.00998 16.7698 4.84998L11.7998 2.97998C11.4598 2.84998 11.0098 2.78998 10.5598 2.78998Z" fill="#292D32"/>
                        <path d="M21.9998 11.97H8.7998C8.3898 11.97 8.0498 11.63 8.0498 11.22C8.0498 10.81 8.3898 10.47 8.7998 10.47H21.9998C22.4098 10.47 22.7498 10.81 22.7498 11.22C22.7498 11.63 22.4098 11.97 21.9998 11.97Z" fill="#292D32"/>
                        <path d="M19.0598 18.5H11.7398C8.97981 18.5 8.0498 17.58 8.0498 14.85V9.41998C8.0498 7.28998 8.5598 5.93997 11.0898 5.77997L11.7398 5.76996H19.0598C21.8198 5.76996 22.7498 6.68998 22.7498 9.41998V14.97C22.7298 17.61 21.7998 18.5 19.0598 18.5ZM11.7398 7.26996L11.1298 7.27997C9.85981 7.35997 9.5498 7.56998 9.5498 9.41998V14.85C9.5498 16.76 9.79981 17 11.7398 17H19.0598C20.9698 17 21.2298 16.75 21.2498 14.97V9.42996C21.2498 7.51996 20.9998 7.27997 19.0598 7.27997H11.7398V7.26996Z" fill="#292D32"/>
                        <path d="M12.6501 16.01H11.3101C10.9001 16.01 10.5601 15.67 10.5601 15.26C10.5601 14.85 10.9001 14.51 11.3101 14.51H12.6501C13.0601 14.51 13.4001 14.85 13.4001 15.26C13.4001 15.67 13.0701 16.01 12.6501 16.01Z" fill="#292D32"/>
                        <path d="M18.02 16.01H14.75C14.34 16.01 14 15.67 14 15.26C14 14.85 14.34 14.51 14.75 14.51H18.02C18.43 14.51 18.77 14.85 18.77 15.26C18.77 15.67 18.44 16.01 18.02 16.01Z" fill="#292D32"/>
                    </svg>
                    <span class="service_txt">Fund Payement</span>
                </div>
                <div class="aug_price">
                    +$3,421.2
                </div>

            </div>
            <h6 class="mt-2 mb-2 text-black">Yesterday</h6>
            <div class="d-flex mb-2 justify-space-between">
                <div>
                    <svg class="mb--5" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.1698 22.75C4.7898 22.75 2.83984 21.02 2.83984 18.89V16.85C2.83984 16.44 3.17984 16.1 3.58984 16.1C3.99984 16.1 4.33984 16.44 4.33984 16.85C4.33984 18.1 5.5498 19.04 7.1698 19.04C8.7898 19.04 9.99982 18.1 9.99982 16.85C9.99982 16.44 10.3398 16.1 10.7498 16.1C11.1598 16.1 11.4998 16.44 11.4998 16.85V18.89C11.4998 21.02 9.5598 22.75 7.1698 22.75ZM4.59979 19.87C5.03979 20.69 6.0298 21.25 7.1698 21.25C8.3098 21.25 9.29981 20.68 9.73981 19.87C9.02981 20.3 8.1498 20.55 7.1698 20.55C6.1898 20.55 5.30979 20.3 4.59979 19.87Z" fill="#292D32"/>
                        <path d="M7.1698 17.7999C5.5298 17.7999 4.05983 17.0499 3.32983 15.8599C3.00983 15.3399 2.83984 14.7299 2.83984 14.1099C2.83984 13.0599 3.29983 12.0799 4.13983 11.3499C5.75983 9.92992 8.54981 9.92997 10.1798 11.34C11.0198 12.08 11.4898 13.0599 11.4898 14.1099C11.4898 14.7299 11.3198 15.3399 10.9998 15.8599C10.2798 17.0499 8.8098 17.7999 7.1698 17.7999ZM7.1698 11.7499C6.3898 11.7499 5.66982 12.0099 5.12982 12.4799C4.61982 12.9199 4.33984 13.4999 4.33984 14.1099C4.33984 14.4599 4.4298 14.78 4.6098 15.08C5.0698 15.84 6.0498 16.3099 7.1698 16.3099C8.2898 16.3099 9.26979 15.84 9.71979 15.09C9.89979 14.8 9.98981 14.4699 9.98981 14.1199C9.98981 13.5099 9.70983 12.9299 9.19983 12.4799C8.66983 12.0099 7.9498 11.7499 7.1698 11.7499Z" fill="#292D32"/>
                        <path d="M7.1698 20.55C4.6998 20.55 2.83984 18.96 2.83984 16.86V14.11C2.83984 11.98 4.7798 10.25 7.1698 10.25C8.2998 10.25 9.37982 10.64 10.1898 11.34C11.0298 12.08 11.4998 13.06 11.4998 14.11V16.86C11.4998 18.96 9.6398 20.55 7.1698 20.55ZM7.1698 11.75C5.6098 11.75 4.33984 12.81 4.33984 14.11V16.86C4.33984 18.11 5.5498 19.05 7.1698 19.05C8.7898 19.05 9.99982 18.11 9.99982 16.86V14.11C9.99982 13.5 9.71984 12.92 9.20984 12.47C8.66984 12.01 7.9498 11.75 7.1698 11.75Z" fill="#292D32"/>
                        <path d="M19.0399 14.7999C17.5299 14.7999 16.2499 13.6799 16.1299 12.2399C16.0499 11.4099 16.3499 10.5999 16.9499 10.0099C17.4499 9.48995 18.1599 9.19995 18.9099 9.19995H20.9999C21.9899 9.22995 22.7499 10.0099 22.7499 10.9699V13.03C22.7499 13.99 21.9899 14.7699 21.0299 14.7999H19.0399ZM20.9699 10.7H18.9199C18.5699 10.7 18.2499 10.8299 18.0199 11.0699C17.7299 11.3499 17.5899 11.7299 17.6299 12.1099C17.6799 12.7699 18.3199 13.2999 19.0399 13.2999H20.9999C21.1299 13.2999 21.2499 13.18 21.2499 13.03V10.9699C21.2499 10.8199 21.1299 10.71 20.9699 10.7Z" fill="#292D32"/>
                        <path d="M16.0002 21.25H13.5002C13.0902 21.25 12.7502 20.91 12.7502 20.5C12.7502 20.09 13.0902 19.75 13.5002 19.75H16.0002C18.5802 19.75 20.2502 18.08 20.2502 15.5V14.8H19.0402C17.5302 14.8 16.2502 13.68 16.1302 12.24C16.0502 11.41 16.3503 10.6 16.9503 10.01C17.4503 9.49001 18.1602 9.20001 18.9102 9.20001H20.2402V8.5C20.2402 6.16 18.8703 4.54998 16.6503 4.28998C16.4103 4.24998 16.2002 4.25 15.9902 4.25H6.99023C6.75023 4.25 6.52022 4.26999 6.29022 4.29999C4.09022 4.57999 2.74023 6.18 2.74023 8.5V10.5C2.74023 10.91 2.40023 11.25 1.99023 11.25C1.58023 11.25 1.24023 10.91 1.24023 10.5V8.5C1.24023 5.42 3.14027 3.19001 6.09027 2.82001C6.36027 2.78001 6.67023 2.75 6.99023 2.75H15.9902C16.2302 2.75 16.5402 2.76 16.8602 2.81C19.8102 3.15 21.7402 5.39 21.7402 8.5V9.95001C21.7402 10.36 21.4002 10.7 20.9902 10.7H18.9102C18.5602 10.7 18.2403 10.83 18.0103 11.07C17.7203 11.35 17.5802 11.73 17.6202 12.11C17.6702 12.77 18.3103 13.3 19.0303 13.3H21.0002C21.4102 13.3 21.7502 13.64 21.7502 14.05V15.5C21.7502 18.94 19.4402 21.25 16.0002 21.25Z" fill="#292D32"/>
                    </svg>
                    <span class="service_txt font-inter">Fly Ticket</span>
                </div>
                <div class="reduce_price">
                    -$1,321.2
                </div>

            </div>
            <div class="d-flex mb-2 justify-space-between">
                <div>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.74 22.75H7.64001C7.33001 22.75 7.05002 22.56 6.94002 22.26C6.83002 21.96 6.91001 21.64 7.15001 21.43C7.85001 20.81 8.26001 19.92 8.26001 18.99C8.26001 17.2 6.80001 15.74 5.01001 15.74C4.27001 15.74 3.57001 15.99 2.98001 16.46C2.75001 16.64 2.45002 16.67 2.19002 16.55C1.93002 16.43 1.77002 16.16 1.77002 15.87V11.5C1.77002 9.01 3.79001 6.98999 6.28001 6.98999H17.76C20.25 6.98999 22.27 9.01 22.27 11.5V12.94C22.27 13.35 21.93 13.69 21.52 13.69H19.5C19.15 13.69 18.83 13.82 18.6 14.06L18.59 14.07C18.31 14.34 18.18 14.71 18.21 15.09C18.27 15.75 18.9 16.28 19.62 16.28H21.52C21.93 16.28 22.27 16.62 22.27 17.03V18.22C22.25 20.73 20.23 22.75 17.74 22.75ZM9.18001 21.25H17.74C19.4 21.25 20.75 19.9 20.75 18.24V17.8H19.6C18.09 17.8 16.81 16.68 16.69 15.24C16.61 14.42 16.91 13.61 17.51 13.02C18.03 12.49 18.73 12.2 19.48 12.2H20.75V11.51C20.75 9.85001 19.4 8.5 17.74 8.5H6.26001C4.60001 8.5 3.25002 9.85001 3.25002 11.51V14.59C3.81002 14.37 4.40002 14.25 5.00002 14.25C7.62002 14.25 9.75002 16.38 9.75002 19C9.75002 19.79 9.55001 20.57 9.18001 21.25Z" fill="#292D32"/>
                        <path d="M2.5 12.2601C2.09 12.2601 1.75 11.9201 1.75 11.5101V7.84011C1.75 6.35011 2.68001 4.99005 4.07001 4.47005L12.01 1.47005C12.83 1.17005 13.75 1.28011 14.46 1.78011C15.17 2.28011 15.6 3.09009 15.6 3.95009V7.75008C15.6 8.16008 15.26 8.50008 14.85 8.50008C14.44 8.50008 14.1 8.16008 14.1 7.75008V3.95009C14.1 3.57009 13.92 3.23009 13.61 3.01009C13.29 2.79009 12.9 2.74008 12.54 2.87008L4.60001 5.87008C3.78001 6.18008 3.25 6.95011 3.25 7.84011V11.5101C3.25 11.9201 2.91 12.2601 2.5 12.2601Z" fill="#292D32"/>
                        <path d="M19.6 17.7999C18.09 17.7999 16.81 16.6799 16.69 15.2399C16.61 14.4099 16.91 13.5999 17.51 13.0099C18.02 12.4899 18.72 12.2 19.47 12.2H21.55C22.54 12.23 23.3 13.0099 23.3 13.9699V16.03C23.3 16.99 22.54 17.7699 21.58 17.7999H19.6ZM21.53 13.7H19.48C19.13 13.7 18.81 13.8299 18.58 14.0699C18.29 14.3499 18.15 14.7299 18.19 15.1099C18.25 15.7699 18.88 16.2999 19.6 16.2999H21.56C21.69 16.2999 21.81 16.18 21.81 16.03V13.9699C21.81 13.8199 21.69 13.71 21.53 13.7Z" fill="#292D32"/>
                        <path d="M14 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z" fill="#292D32"/>
                        <path d="M5 23.75C2.38 23.75 0.25 21.62 0.25 19C0.25 17.54 0.899999 16.19 2.03 15.29C2.87 14.62 3.93 14.25 5 14.25C7.62 14.25 9.75 16.38 9.75 19C9.75 20.36 9.16 21.66 8.14 22.56C7.26 23.33 6.15 23.75 5 23.75ZM5 15.75C4.26 15.75 3.56 16 2.97 16.47C2.2 17.08 1.75 18.01 1.75 19C1.75 20.79 3.21 22.25 5 22.25C5.78 22.25 6.53999 21.96 7.14999 21.44C7.84999 20.82 8.25 19.94 8.25 19C8.25 17.21 6.79 15.75 5 15.75Z" fill="#292D32"/>
                        <path d="M3.99988 20.75C3.74988 20.75 3.49988 20.62 3.35988 20.39C3.14988 20.03 3.25988 19.57 3.61988 19.36L4.50988 18.83V17.75C4.50988 17.34 4.84988 17 5.25988 17C5.66988 17 6.00988 17.34 6.00988 17.75V19.25C6.00988 19.51 5.86988 19.76 5.64988 19.89L4.39988 20.64C4.26988 20.72 4.12988 20.75 3.99988 20.75Z" fill="#292D32"/>
                    </svg>
                    <span class="service_txt">Salary income</span>
                </div>
                <div class="aug_price">
                    +$2,421.2
                </div>

            </div>
        </div>
<!--      <div v-if="!previousConversations.length" class="no-label-message">
        <span>
          {{ $t('CONTACT_PANEL.CONVERSATIONS.NO_RECORDS_FOUND') }}
        </span>
      </div>
      <div v-else class="contact-conversation&#45;&#45;list">
        <conversation-card
          v-for="conversation in previousConversations"
          :key="conversation.id"
          :chat="conversation"
          :hide-inbox-name="false"
          :hide-thumbnail="true"
          class="compact"
        />
      </div>-->
    </>
    <spinner v-else />
  </div>
</template>

<script>
import ConversationCard from 'dashboard/components/widgets/conversation/ConversationCard';
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner';

export default {
  components: {
    ConversationCard,
    Spinner,
  },
  props: {
    contactId: {
      type: [String, Number],
      required: true,
    },
    conversationId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    conversations() {
      return this.$store.getters['contactConversations/getContactConversation'](
        this.contactId
      );
    },
    previousConversations() {
      return this.conversations.filter(
        conversation => conversation.id !== Number(this.conversationId)
      );
    },
    ...mapGetters({
      uiFlags: 'contactConversations/getUIFlags',
    }),
  },
  watch: {
    contactId(newContactId, prevContactId) {
      if (newContactId && newContactId !== prevContactId) {
        this.$store.dispatch('contactConversations/get', newContactId);
      }
    },
  },
  mounted() {
    this.$store.dispatch('contactConversations/get', this.contactId);
  },
};
</script>

<style lang="scss" scoped>
.no-label-message {
  margin-bottom: var(--space-normal);
  color: var(--b-500);
}
.container {
  position: relative;
  background-size: cover;
  padding: 25px;
  border-radius: 28px;
  max-width: 380px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
header,
.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo img {
  width: 48px;
  margin-right: 10px;
}
h5 {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
header .chip {
  width: 60px;
}
h6 {
  color: #fff;
  font-size: 10px;
  font-weight: 400;
}
h5.number {
  margin-top: 4px;
  font-size: 18px;
  letter-spacing: 1px;
}
h5.name {
  margin-top: 20px;
}
.container .card-details {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.mb--5{
  margin-bottom: -0.5rem !important;
}
.font-bold-custom{
  font-weight: bold !important;
}

.transaction_txt{
  font-size: 16px !important;
  font-weight: bold;
  color: black;
}


.reduce_price{
  color: darkseagreen;
  font-weight: 600 !important;
  font-size: 13px;
}
.aug_price{
  color: indianred;
  font-weight: 600 !important;
  font-size: 13px;
}

.service_txt{
  font-size: 14px;
  font-weight: 400 !important;
  color: black;
}

.balance_txt{
  color: white;
  font-size: 11px;
}

.balance_value{
  font-size: 26px;
  color: white;
}
</style>
