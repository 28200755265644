var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wizard-body height-auto small-9 columns"},[_c('page-header',{attrs:{"header-title":_vm.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.TITLE'),"header-content":_vm.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.DESC')}}),_vm._v(" "),(_vm.uiFlags.isCreating)?_c('woot-loading-state',{attrs:{"message":_vm.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.LOADING_MESSAGE')}}):_vm._e(),_vm._v(" "),(!_vm.uiFlags.isCreating)?_c('form',{staticClass:"row custom_padding",on:{"submit":function($event){$event.preventDefault();return _vm.createChannel($event)}}},[_c('div',{staticClass:"medium-12 columns"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inboxName),expression:"inboxName",modifiers:{"trim":true}}],staticClass:"cust_input",attrs:{"type":"text","placeholder":_vm.$t('INBOX_MGMT.ADD.WEBSITE_NAME.LABEL')},domProps:{"value":(_vm.inboxName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.inboxName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('div',{staticClass:"medium-12 columns"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.channelWebsiteUrl),expression:"channelWebsiteUrl",modifiers:{"trim":true}}],staticClass:"cust_input",attrs:{"type":"text","placeholder":_vm.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.LABEL')},domProps:{"value":(_vm.channelWebsiteUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.channelWebsiteUrl=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('div',{staticClass:"medium-12 columns"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.channelWelcomeTitle),expression:"channelWelcomeTitle",modifiers:{"trim":true}}],staticClass:"cust_input",attrs:{"type":"text","placeholder":_vm.$t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.LABEL'
              )},domProps:{"value":(_vm.channelWelcomeTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.channelWelcomeTitle=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('div',{staticClass:"medium-12 columns"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.channelWelcomeTagline),expression:"channelWelcomeTagline",modifiers:{"trim":true}}],staticClass:"cust_input",attrs:{"type":"text","placeholder":_vm.$t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL'
              )},domProps:{"value":(_vm.channelWelcomeTagline)},on:{"input":function($event){if($event.target.composing){ return; }_vm.channelWelcomeTagline=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.greetingEnabled),expression:"greetingEnabled"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.greetingEnabled=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":true}},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.ENABLED'
                ))+"\n            ")]),_vm._v(" "),_c('option',{domProps:{"value":false}},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.DISABLED'
                ))+"\n            ")])]),_vm._v(" "),_c('p',{staticClass:"help-text"},[_vm._v("\n            "+_vm._s(_vm.$t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.HELP_TEXT'
            ))+"\n        ")]),_vm._v(" "),(_vm.greetingEnabled)?_c('greetings-editor',{staticClass:"medium-12 columns",attrs:{"label":_vm.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL'),"placeholder":_vm.$t(
            'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.PLACEHOLDER'
          ),"richtext":!_vm.textAreaChannels},model:{value:(_vm.greetingMessage),callback:function ($$v) {_vm.greetingMessage=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"greetingMessage"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"medium-12 columns"},[_c('woot-submit-button',{attrs:{"loading":_vm.uiFlags.isCreating,"button-text":_vm.$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.SUBMIT_BUTTON')}})],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }