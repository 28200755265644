<template>
  <div class="medium-3 bg-white contact--panel">
    <transition name="slide-fade">
      <client-informations :contact="contact" class="slide" v-if="layout === 'gen-info'"
                           :key="currentID"></client-informations>
      <Contracts class="slide" v-if="layout === 'Fidelity'" :key="currentID"></Contracts>
      <Sinisters :customerEmail="contact.email" class="slide" v-if="layout === 'Orders'" :key="currentID"></Sinisters>
      <AssuranceComplaints class="slide" v-if="layout === 'Complaints'" :key="currentID"></AssuranceComplaints>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GeneralInfoVue from "./GeneralInfoContent";
import OrdersContent from "./OrdersContent";
import FidelityContentVue from "./FidelityContent";
import Sinisters from "../../../components/widgets/conversation/AssuranceBlock/Sinisters"
import Contracts from "../../../components/widgets/conversation/AssuranceBlock/Contracts"
import AssuranceComplaints from "../../../components/widgets/conversation/AssuranceBlock/AssuranceComplaints"
import ComplaintsContent from "./ComplaintsContent"
import ClientInformations from "../../../components/widgets/conversation/RetailBlocks/ClientInformations";
import OrdersBlock from "../../../components/widgets/conversation/RetailBlocks/OrdersBlock";
export default {
  components: {
    OrdersBlock,
    ClientInformations,
    ComplaintsContent,
    FidelityContentVue,
    OrdersContent,
    GeneralInfoVue,
    Contracts,
    Sinisters,
    AssuranceComplaints
  },
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
    inboxId: {
      type: Number,
      default: undefined,
    },
    onToggle: {
      type: Function,
      default: () => { },
    },
    layout: String,
    currentID: Number
  },
  data() {
    return {
      dragEnabled: true,
      conversationSidebarItems: [],
      dragging: false,
      layout: "gen-info"
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      uiFlags: 'inboxAssignableAgents/getUIFlags',
    }),
    conversationAdditionalAttributes() {
      return this.currentConversationMetaData.additional_attributes || {};
    },
    channelType() {
      return this.currentChat.meta?.channel;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    contactAdditionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    currentConversationMetaData() {
      return this.$store.getters[
        'conversationMetadata/getConversationMetadata'
        ](this.conversationId);
    },
    hasContactAttributes() {
      const { custom_attributes: customAttributes } = this.contact;
      return customAttributes && Object.keys(customAttributes).length;
    },
  },
  watch: {
    conversationId(newConversationId, prevConversationId) {
      if (newConversationId && newConversationId !== prevConversationId) {
        this.getContactDetails();
      }
    },
    contactId() {
      this.getContactDetails();
    },
  },
  mounted() {
    this.conversationSidebarItems = this.conversationSidebarItemsOrder;
    this.getContactDetails();
    this.$store.dispatch('attributes/get', 0);
  },
  methods: {
    onPanelToggle() {
      this.onToggle();
    },
    getContactDetails() {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId });
      }
    },
    getAttributesByModel() {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId });
      }
    },
    openTranscriptModal() {
      this.showTranscriptModal = true;
    },
    onDragEnd() {
      this.dragging = false;
      this.updateUISettings({
        conversation_sidebar_items_order: this.conversationSidebarItems,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

::-webkit-scrollbar {
  display: none;
}

.contact--panel {
  background: #FFFFFF;
  border: 0.5px solid #483A9D;
  box-shadow: 0px 3px 12px rgb(0 0 0 / 4%);
  border-radius: 8px 0px 8px 8px;
  //border-left: 1px solid var(--color-border);
  font-size: $font-size-small;
  overflow-y: auto;
  overflow: auto;
  position: relative;

  i {
    margin-right: $space-smaller;
  }
}

.list-group {
  .list-group-item {
    background-color: var(--white);
  }
}

::v-deep {
  .contact--profile {
    padding-bottom: var(--space-slab);
    border-bottom: 1px solid var(--color-border);
  }

  .conversation--actions .multiselect-wrap--small {
    .multiselect {
      padding-left: var(--space-medium);
      box-sizing: border-box;
    }

    .multiselect__element {
      span {
        width: 100%;
      }
    }
  }
}

.close-button {
  position: absolute;
  right: $space-two;
  top: $space-slab;
  font-size: $font-size-default;
  color: $color-heading;
  z-index: 9989;
}

.conversation--labels {
  padding: $space-medium;

  .icon {
    margin-right: $space-micro;
    font-size: $font-size-micro;
    color: #fff;
  }

  .label {
    color: #fff;
    padding: 0.2rem;
  }
}

.contact--mute {
  color: $alert-color;
  display: block;
  text-align: left;
}

.contact--actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info {
  margin-top: var(--space-two);
}

.contact--panel--container {
  //height: 1000px;
  height: 100%;
  width: 100%;
  border-radius: 8px 0px 8px 8px;
}

.slide {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* prefix with transition name */
.slide-fade-enter-active {
  opacity: 0;
  z-index: 10;
}

.slide-fade-leave-active {
  opacity: 0;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}</style>
