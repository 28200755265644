<template>
    <div>
        <div v-if="title === 'Enabled'" class="enabled_text">
            {{title}}
            <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M8.00016 15.1663C4.04683 15.1663 0.833496 11.953 0.833496 7.99967C0.833496 4.04634 4.04683 0.833008 8.00016 0.833008C11.9535 0.833008 15.1668 4.04634 15.1668 7.99967C15.1668 11.953 11.9535 15.1663 8.00016 15.1663ZM8.00016 1.83301C4.60016 1.83301 1.8335 4.59967 1.8335 7.99967C1.8335 11.3997 4.60016 14.1663 8.00016 14.1663C11.4002 14.1663 14.1668 11.3997 14.1668 7.99967C14.1668 4.59967 11.4002 1.83301 8.00016 1.83301Z" fill="#49CF48"/>
                         <path d="M7.05346 10.3867C6.92012 10.3867 6.79346 10.3334 6.70012 10.2401L4.81346 8.3534C4.62012 8.16007 4.62012 7.84007 4.81346 7.64673C5.00679 7.4534 5.32679 7.4534 5.52012 7.64673L7.05346 9.18007L10.4801 5.7534C10.6735 5.56007 10.9935 5.56007 11.1868 5.7534C11.3801 5.94673 11.3801 6.26673 11.1868 6.46006L7.40679 10.2401C7.31346 10.3334 7.18679 10.3867 7.05346 10.3867Z" fill="#49CF48"/>
            </svg>
        </div>


        <div v-if="title === 'Disabled'" class="disabled_text">
            {{title}}
            <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 21C16.5 21 21 16.5 21 11C21 5.5 16.5 1 11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21Z" stroke="#e8bf59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.16998 13.83L13.83 8.17" stroke="#e8bf59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.83 13.83L8.16998 8.17" stroke="#e8bf59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

    </div>
</template>

<script>
export default {
    name: "CampaignStatus",
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: '',
        },
        href: {
            type: String,
            default: '',
        },
        bgColor: {
            type: String,
            default: '',
        },
        small: {
            type: Boolean,
            default: false,
        },
        showClose: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '',
        },
        colorScheme: {
            type: String,
            default: '',
        },
        variant: {
            type: String,
            default: '',
        },
    },
}
</script>

<style scoped lang="scss">

.enabled_text{
    font-family: 'Inter';
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 13px!important;
    line-height: 19px!important;
    color: #49cf48!important;
    svg{
        margin-bottom: -2px;
        margin-left: 1px;
    }
}

.disabled_text{
    font-family: 'Inter';
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 19px !important;
    color: #e8bf59 !important;
    svg{
        margin-bottom: -2px;
        margin-left: 1px;
    }

}

</style>
