export default {
  computed: {
    hostURL() {
      return window.convergenceConfig.hostURL;
    },
    vapidPublicKey() {
      return window.convergenceConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return window.convergenceConfig.enabledLanguages;
    },
    isEnterprise() {
      return window.convergenceConfig.isEnterprise === 'true';
    },
  },
};
