<template>
  <div class="conversation-details-wrap" :class="{ 'with-border-left': !isOnExpandedLayout }">
    <conversation-header v-if="currentChat.id" :chat="currentChat" :is-contact-panel-open="isContactPanelOpen"
                         :show-back-button="isOnExpandedLayout" @contact-panel-toggle="onToggleContactPanel" />
    <woot-tabs v-if="dashboardApps.length && currentChat.id" :index="activeIndex" class="dashboard-app--tabs"
               @change="onDashboardAppTabChange">
      <woot-tabs-item v-for="tab in dashboardAppTabs" :key="tab.key" :name="tab.name" :show-badge="false" />
    </woot-tabs>
    <div v-if="!activeIndex" class="messages-and-sidebar">
      <messages-view v-if="currentChat.id" :inbox-id="inboxId" :is-contact-panel-open="isContactPanelOpen"
                     @contact-panel-toggle="onToggleContactPanel" />
      <empty-state v-else :is-on-expanded-layout="isOnExpandedLayout" />
      <div v-show="showContactPanel" class="conversation-sidebar-wrap">
        <contact-panel v-if="showContactPanel" :conversation-id="currentChat.id" :inbox-id="currentChat.inbox_id"
                       :on-toggle="onToggleContactPanel" :layout="layout" :id="currentID">
        </contact-panel>
        <div class="btn-group">
          <button class="btn-gen-info" :class="{ barActive: layout === 'gen-info' }" @click="layout = 'gen-info'">
<span class="button__content gen-info-ticket ">
General information
</span>
          </button>
          <button class="btn-Fidelity" :class="{ barActive: layout === 'Fidelity' }" @click="layout = 'Fidelity'">
<span class="button__content fid-ticket">
Contracts
</span>
          </button>
          <button class="btn-Orders" :class="{ barActive: layout === 'Orders' }" @click="layout = 'Orders'">
<span class="button__content ord-ticket">
Sinisters
</span>
          </button>
          <button class="btn-Complaints" :class="{ barActive: layout === 'Complaints' }" @click="layout = 'Complaints'">
<span class="button__content comp-ticket">
Complaints
</span>
          </button>
        </div>
      </div>
    </div>
    <dashboard-app-frame v-else :key="currentChat.id" :config="dashboardApps[activeIndex - 1].content"
                         :current-chat="currentChat" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ContactPanel from 'dashboard/routes/dashboard/conversation/ContactPanel';
import ConversationHeader from './ConversationHeader';
import DashboardAppFrame from '../DashboardApp/Frame.vue';
import EmptyState from './EmptyState';
import MessagesView from './MessagesView';
import UserBanner from "./WhatsappTemplates/UserBanner";

import router from "../../../routes";

export default {
  components: {
    UserBanner,
    ContactPanel,
    ConversationHeader,
    DashboardAppFrame,
    EmptyState,
    MessagesView,
  },
  props: {
    inboxId: {
      type: [Number, String],
      default: '',
      required: false,
    },
    isContactPanelOpen: {
      type: Boolean,
      default: true,
    },
    isOnExpandedLayout: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
      layout: "gen-info",
      currentID: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      dashboardApps: 'dashboardApps/getRecords',
    }),
    dashboardAppTabs() {
      return [
        {
          key: 'messages',
          name: this.$t('CONVERSATION.DASHBOARD_APP_TAB_MESSAGES'),
        },
        ...this.dashboardApps.map(dashboardApp => ({
          key: `dashboard-${dashboardApp.id}`,
          name: dashboardApp.title,
        })),
      ];
    },
    showContactPanel() {
      return this.isContactPanelOpen && this.currentChat.id;
    },

  },
  watch: {
    'currentChat.inbox_id'(inboxId) {
      if (inboxId) {
        this.$store.dispatch('inboxAssignableAgents/fetch', [inboxId]);
      }
    },
    'currentChat.id'() {
      this.fetchLabels();
    },
  },
  mounted() {
    this.fetchLabels();
    this.$store.dispatch('dashboardApps/get');
  },
  methods: {
    fetchLabels() {
      if (!this.currentChat.id) {
        return;
      }
      this.$store.dispatch('conversationLabels/get', this.currentChat.id);
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    onDashboardAppTabChange(index) {
      this.activeIndex = index;
    },
    toggleActions() {
      if (this.currentID < this.images.length - 1) {
        this.currentID += 1
      } else {
        this.currentID = 0
      }
    },
// openAddPopup() {
// this.showAddPopup = true;
// },
// hideAddPopup() {
// this.showAddPopup = false;
// },
// openValidatePopup(){
// this.showValidatePopup = true;
// console.log(this.showValidatePopup);
// },
// hideValidatePopup() {
// this.showValidatePopup = false;
// console.log(this.showValidatePopup);
// },

  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/woot';

.conversation-details-wrap {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  background: var(--color-background-light);

  &.with-border-left {
    border-left: 1px solid var(--color-border);
  }
}

.dashboard-app--tabs {
  background: var(--white);
  margin-top: -1px;
  min-height: var(--dashboard-app-tabs-height);
}

.messages-and-sidebar {
  display: flex;
  background: var(--color-background-light);
  margin: 0;
  height: 100%;
  min-height: 0;
}

.conversation-sidebar-wrap {
  //height: 1000px;
  flex: 0 0;
  overflow: hidden;
  overflow: auto;
  background: white;
  flex-basis: 28rem;
  border-radius: 8px 0px 8px 8px !important;


  @include breakpoint(large up) {
    flex-basis: 30em;
  }

  @include breakpoint(xlarge up) {
    flex-basis: 31em;
  }

  @include breakpoint(xxlarge up) {
    flex-basis: 33rem;
  }

  @include breakpoint(xxxlarge up) {
    flex-basis: 40rem;
  }

  &::v-deep .contact--panel {
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 8px 0px 8px 8px;
  }
}

.btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  /*height: 100%;*/
  height: 600px;
  width: 34px;
  left: 97.21%;
  top: 14.6%;
  z-index: 1;
}

.button__content {
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /*color: #A8A8A8;*/
  transform: rotate(-90deg);
}

.btn-group button {
  height: 150px;
  width: 32px;
  position: absolute;
  cursor: pointer;
  background: #ffff;
  color: #A8A8A8;
  border-radius: 0px 8px 8px 0px;
}

.btn-group button.barActive {
  background-color: #483a9d;
  color: #FFFF;
  cursor: default;
}

.btn-gen-info {
  left: 1.36%;
  top: 0%;
}

.btn-Fidelity {
  left: 1.36%;
  top: 24.9%;

}

.btn-Orders {
  left: 1.36%;
  right: 1.6%;
  top: 49.8%;
  bottom: 48.67%;
}

.btn-Complaints {
  right: 1.6%;
  top: 74.53%;
  bottom: 35.4%;
  left: 1.36%;
}

.gen-info-ticket {
  position: absolute;
  height: 17px;
  width: 133px;
  left: -51px;
  top: 66px;
  /*color: #FFFFFF;*/
}

.fid-ticket {
  position: absolute;
  left: -25.26%;
  top: 49.54%;
  bottom: 43.96%;
  height: 17px;
  width: 48px;
  /*color: #A8A8A8;*/
}

.ord-ticket {
  position: absolute;
  right: 0.07%;
  top: 46.73%;
  bottom: 51.77%;
  left: -8px;
  z-index: 1;
}

.comp-ticket {
  position: absolute;
  left: -41.26%;
  right: -1.74%;
  top: 61.15%;
  bottom: 37.35%;
}

//Responsive mode
@media (max-width: 750px) {
  .btn-group {
    left: 97.21%;
    top: 14.6%;
    z-index: 1;
  }
}

@media (min-width: 1200px) {
  .btn-group {
    left: 97.6%;
    top: 11.5%;
    z-index: 1;
  }
}

@media (min-width: 1400px) {
  .btn-group {
    left: 97.69%;
    top: 11.19%;
    z-index: 1;
  }
}

@media (min-width: 1870px) {
  .btn-group {
    left: 98.14%;
    top: 8.8%;
    z-index: 1;
  }
}
</style>
