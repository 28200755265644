<template>
  <modal :show.sync="show" :on-close="closeModal" class="p-5">
      <svg class="margin_custom" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M70.2335 17.4333C64.8669 16.9 59.5002 16.5 54.1002 16.2V16.1667L53.3669 11.8333C52.8669 8.76666 52.1335 4.16666 44.3335 4.16666H35.6002C27.8335 4.16666 27.1002 8.56666 26.5669 11.8L25.8669 16.0667C22.7669 16.2667 19.6669 16.4667 16.5669 16.7667L9.76687 17.4333C8.36687 17.5667 7.36687 18.8 7.5002 20.1667C7.63353 21.5333 8.83353 22.5333 10.2335 22.4L17.0335 21.7333C34.5002 20 52.1002 20.6667 69.7669 22.4333C69.8669 22.4333 69.9335 22.4333 70.0335 22.4333C71.3002 22.4333 72.4002 21.4667 72.5335 20.1667C72.6335 18.8 71.6335 17.5667 70.2335 17.4333Z" fill="#483A9D"/>
          <path d="M64.0998 27.1333C63.2998 26.3 62.1998 25.8333 61.0665 25.8333H18.9331C17.7998 25.8333 16.6665 26.3 15.8998 27.1333C15.1331 27.9667 14.6998 29.1 14.7665 30.2667L16.8332 64.4667C17.1998 69.5333 17.6665 75.8667 29.2998 75.8667H50.6998C62.3331 75.8667 62.7998 69.5667 63.1665 64.4667L65.2332 30.3C65.2998 29.1 64.8665 27.9667 64.0998 27.1333ZM45.5331 59.1667H34.4332C33.0665 59.1667 31.9332 58.0333 31.9332 56.6667C31.9332 55.3 33.0665 54.1667 34.4332 54.1667H45.5331C46.8998 54.1667 48.0331 55.3 48.0331 56.6667C48.0331 58.0333 46.8998 59.1667 45.5331 59.1667ZM48.3331 45.8333H31.6665C30.2998 45.8333 29.1665 44.7 29.1665 43.3333C29.1665 41.9667 30.2998 40.8333 31.6665 40.8333H48.3331C49.6998 40.8333 50.8331 41.9667 50.8331 43.3333C50.8331 44.7 49.6998 45.8333 48.3331 45.8333Z" fill="#483A9D"/>
      </svg>
      <woot-modal-header :header-title="title" :header-content="message" />
    <form @submit.prevent="onConfirm">
        <input
                v-model="value"
                type="text"
                :class="{ error: $v.value.$error }"
                :placeholder="confirmPlaceHolderText"
                @blur="$v.value.$touch"
                class="delete_input"
        >
      <div class="button-wrapper d-flex float-right pb-4">
          <woot-button class="clear mr-3"  @click.prevent="closeModal">
              Cancel
          </woot-button>
          <woot-button color-scheme="primary"  :is-disabled="$v.value.$invalid">
              Delete
          </woot-button>
      </div>
    </form>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Modal from '../../Modal';
import Input from "../../../../widget/components/Form/Input";

export default {
  components: {
      Input,
    Modal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    rejectText: {
      type: String,
      default: '',
    },
    confirmValue: {
      type: String,
      default: '',
    },
    confirmPlaceHolderText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
    };
  },
  validations: {
    value: {
      required,
      isEqual(value) {
        return value === this.confirmValue;
      },
    },
  },
  methods: {
    closeModal() {
      this.value = '';
      this.$emit('on-close');
    },
    onConfirm() {
      this.$emit('on-confirm');
    },
  },
};
</script>
<style scoped lang="scss">
    .margin_custom{
      margin: 0 auto;
      display: block;
      margin-top: 8rem !important;
    }

    .delete_input{
      width: 100% !important;
      margin-bottom: 3rem !important;
      border-radius: 5px !important;
      border: 1px solid #737373 !important;
    }
</style>
