<template>
    <div class="logo">
        <router-link :to="dashboardPath" replace>
            <!-- Logo Convergence -->
            <img :src="require('../../../assets/images/convergence_logo.png')" :alt="name" />
        </router-link>
    </div>
</template>
<script>
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  props: {
    source: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dashboardPath() {
      return frontendURL(`accounts/${this.accountId}/reports/overview`);
    },
  },
};
</script>
<style lang="scss" scoped>
$logo-size: 34px;

.logo {
  padding: var(--space-normal);

  img {
    width: $logo-size;
    height: $logo-size;
    object-fit: cover;
    object-position: left center;
  }
}
</style>
