<template>
  <div class="column content-box">
      <div class="row">
          <div class="columns">
              <woot-button
                color-scheme="primary"
                icon="add-circle"
                @click="openAddPopup"
                class="float-right mt-2 mb-5"
              >
                {{ buttonText }}
              </woot-button>
              <campaign />
          </div>
          <woot-modal  :show.sync="showAddPopup" :on-close="hideAddPopup">
              <add-campaign @on-close="hideAddPopup" />
          </woot-modal>
      </div>
  </div>
</template>

<script>
import campaignMixin from 'shared/mixins/campaignMixin';
import Campaign from './Campaign.vue';
import AddCampaign from './AddCampaign';

export default {
  components: {
    Campaign,
    AddCampaign,
  },
  mixins: [campaignMixin],
  data() {
    return { showAddPopup: false };
  },
  computed: {
    buttonText() {
      if (this.isOngoingType) {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONGOING');
      }
      return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONE_OFF');
    },
  },
  mounted() {
    this.$store.dispatch('campaigns/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
  },
};
</script>
