<template>
  <div class="notif_section ">
        <div class="alert_events">
            <h5>Notifications</h5>
            <h6 class="section_title">Audio Notifications</h6>
            <p class="parag_section mb-2">
                Enable audio notifications in dashboard for new messages and conversations.
            </p>
            <div class="d-flex">
                <div class="mr-2">
                    <input
                            id="audio_enable_alert_none"
                            v-model="enableAudioAlerts"
                            class="notification&#45;&#45;checkbox radio_notif"
                            type="radio"
                            value="none"
                            @input="handleAudioInput"
                    />
                    <label class="alert_label"  for="audio_enable_alert_none">
                        {{
                        $t(
                            'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.NONE'
                        )
                        }}
                    </label>
                </div>
                <div class="mr-2">
                    <input
                            id="audio_enable_alert_mine"
                            v-model="enableAudioAlerts"
                            class="notification&#45;&#45;checkbox radio_notif"
                            type="radio"
                            value="mine"
                            @input="handleAudioInput"
                    />
                    <label class="alert_label mb-1"  for="audio_enable_alert_mine">
                        {{
                        $t(
                            'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.ASSIGNED'
                        )
                        }}
                    </label>
                </div>
                <div class="mr-2">
                    <input
                            id="audio_enable_alert_all"
                            v-model="enableAudioAlerts"
                            class="notification&#45;&#45;checkbox  radio_notif"
                            type="radio"
                            value="all"
                            @input="handleAudioInput"
                    />
                    <label class="alert_label" for="audio_enable_alert_all">
                        {{
                        $t(
                            'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.ALL_CONVERSATIONS'
                        )
                        }}
                    </label>
                </div>
            </div>
        </div>
        <div class="email_notifications mt-4">
          <h6 class="section_title">Email Notifications</h6>
          <p class="parag_section mb-5">
              Update your email notification preferences here.
          </p>
          <div class="checkbox_notif">
              <div class="d-flex mb-4">
                  <input
                          v-model="selectedEmailFlags"
                          class="notification--checkbox"
                          type="checkbox"
                          value="email_conversation_creation"
                          @input="handleEmailInput"
                  />
                  <label class="alert_label">
                      {{
                      $t(
                          'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_CREATION'
                      )
                      }}
                  </label>
              </div>
              <div class="d-flex mb-4">
                  <input
                          v-model="selectedEmailFlags"
                          class="notification--checkbox"
                          type="checkbox"
                          value="email_conversation_assignment"
                          @input="handleEmailInput"
                  />
                  <label class="alert_label">
                      {{
                      $t(
                          'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT'
                      )
                      }}
                  </label>
              </div>
              <div class="d-flex mb-4">
                  <input
                          v-model="selectedEmailFlags"
                          class="notification--checkbox"
                          type="checkbox"
                          value="email_conversation_mention"
                          @input="handleEmailInput"
                  />
                  <label class="alert_label">
                      {{
                      $t(
                          'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_MENTION'
                      )
                      }}
                  </label>
              </div>
              <div class="d-flex mb-4">
                  <input
                          v-model="selectedEmailFlags"
                          class="notification--checkbox"
                          type="checkbox"
                          value="email_assigned_conversation_new_message"
                          @input="handleEmailInput"
                  />
                  <label class="alert_label">
                      {{
                      $t(
                          'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.ASSIGNED_CONVERSATION_NEW_MESSAGE'
                      )
                      }}
                  </label>
              </div>
          </div>
        </div>
        <div class="push_notifications mt-4">
          <h6 class="section_title">Push Notifications</h6>
          <p class="parag_section mb-5">
              Update your push notification preferences.
          </p>
          <div class="checkbox_notif">
              <div
                      v-if="vapidPublicKey && !isBrowserSafari"
              >
                 <div class="d-flex mb-4">
                     <input
                             v-model="selectedPushFlags"
                             class="notification--checkbox"
                             type="checkbox"
                             value="push_conversation_creation"
                             @input="handlePushInput"
                     />
                     <label class="alert_label" for="conversation_creation">
                         {{
                         $t(
                             'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_CREATION'
                         )
                         }}
                     </label>
                 </div>
                 <div class="d-flex mb-4">
                     <input
                             v-model="selectedPushFlags"
                             class="notification--checkbox"
                             type="checkbox"
                             value="push_conversation_assignment"
                             @input="handlePushInput"
                     />
                     <label class="alert_label" for="conversation_assignment">
                         {{
                         $t(
                             'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT'
                         )
                         }}
                     </label>
                 </div>
                 <div class="d-flex mb-4">
                     <input
                             v-model="selectedPushFlags"
                             class="notification--checkbox"
                             type="checkbox"
                             value="push_conversation_mention"
                             @input="handlePushInput"
                     />
                     <label  class="alert_label" for="conversation_mention">
                         {{
                         $t(
                             'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_MENTION'
                         )
                         }}
                     </label>
                 </div>
                 <div class="d-flex mb-4">
                          <input
                                  v-model="selectedPushFlags"
                                  class="notification--checkbox"
                                  type="checkbox"
                                  value="push_assigned_conversation_new_message"
                                  @input="handlePushInput"
                          />
                          <label  class="alert_label" for="assigned_conversation_new_message">
                              {{
                              $t(
                                  'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.ASSIGNED_CONVERSATION_NEW_MESSAGE'
                              )
                              }}
                          </label>
                      </div>
                 <p class="mt-3 text-primary" v-if="hasEnabledPushPermissions">
                     {{
                     $t(
                         'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.HAS_ENABLED_PUSH'
                     )
                     }}
                 </p>
                 <div v-else class="push-notification--button float-right mt-5">
                      <woot-submit-button
                              :button-text="
                            $t(
                              'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.REQUEST_PUSH'
                            )
                          "
                              class="button nice small float-right"
                              type="button"
                              @click="onRequestPermissions"
                      />
                  </div>
              </div>
          </div>
      </div>
  </div>
<!--    <div class="profile&#45;&#45;settings&#45;&#45;row row">
      <div class="columns small-9">
        <div class="notification-items&#45;&#45;wrapper">
          <span class="text-block-title notification-label">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.TITLE'
              )
            }}
          </span>
          <div>
            <input
              id="audio_enable_alert_none"
              v-model="enableAudioAlerts"
              class="notification&#45;&#45;checkbox"
              type="radio"
              value="none"
              @input="handleAudioInput"
            />
            <label for="audio_enable_alert_none">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.NONE'
                )
              }}
            </label>
          </div>
          <div>
            <input
              id="audio_enable_alert_mine"
              v-model="enableAudioAlerts"
              class="notification&#45;&#45;checkbox"
              type="radio"
              value="mine"
              @input="handleAudioInput"
            />
            <label for="audio_enable_alert_mine">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.ASSIGNED'
                )
              }}
            </label>
          </div>
          <div>
            <input
              id="audio_enable_alert_all"
              v-model="enableAudioAlerts"
              class="notification&#45;&#45;checkbox"
              type="radio"
              value="all"
              @input="handleAudioInput"
            />
            <label for="audio_enable_alert_all">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.ALL_CONVERSATIONS'
                )
              }}
            </label>
          </div>
        </div>
        <div class="notification-items&#45;&#45;wrapper">
          <span class="text-block-title notification-label">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.DEFAULT_TONE.TITLE'
              )
            }}
          </span>
          <div>
            <select
              v-model="notificationTone"
              class="tone-selector"
              @change="handleAudioToneChange"
            >
              <option
                v-for="tone in notificationAlertTones"
                :key="tone.value"
                :value="tone.value"
              >
                {{ tone.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="notification-items&#45;&#45;wrapper">
          <span class="text-block-title notification-label">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.TITLE'
              )
            }}
          </span>
          <div>
            <input
              id="audio_alert_when_tab_is_inactive"
              v-model="playAudioWhenTabIsInactive"
              class="notification&#45;&#45;checkbox"
              type="checkbox"
              value="tab_is_inactive"
              @input="handleAudioAlertConditions"
            />
            <label for="audio_alert_when_tab_is_inactive">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_ONE'
                )
              }}
            </label>
          </div>
          <div>
            <input
              id="audio_alert_until_all_conversations_are_read"
              v-model="alertIfUnreadConversationExist"
              class="notification&#45;&#45;checkbox"
              type="checkbox"
              value="conversations_are_read"
              @input="handleAudioAlertConditions"
            />
            <label for="audio_alert_until_all_conversations_are_read">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_TWO'
                )
              }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="profile&#45;&#45;settings&#45;&#45;row row">
      <div class="columns small-9">
        <div>
          <input
            v-model="selectedEmailFlags"
            class="notification&#45;&#45;checkbox"
            type="checkbox"
            value="email_conversation_creation"
            @input="handleEmailInput"
          />
          <label for="conversation_creation">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_CREATION'
              )
            }}
          </label>
        </div>

        <div>
          <input
            v-model="selectedEmailFlags"
            class="notification&#45;&#45;checkbox"
            type="checkbox"
            value="email_conversation_assignment"
            @input="handleEmailInput"
          />
          <label for="conversation_assignment">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT'
              )
            }}
          </label>
        </div>

        <div>
          <input
            v-model="selectedEmailFlags"
            class="notification&#45;&#45;checkbox"
            type="checkbox"
            value="email_conversation_mention"
            @input="handleEmailInput"
          />
          <label for="conversation_mention">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_MENTION'
              )
            }}
          </label>
        </div>

        <div>
          <input
            v-model="selectedEmailFlags"
            class="notification&#45;&#45;checkbox"
            type="checkbox"
            value="email_assigned_conversation_new_message"
            @input="handleEmailInput"
          />
          <label for="assigned_conversation_new_message">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.ASSIGNED_CONVERSATION_NEW_MESSAGE'
              )
            }}
          </label>
        </div>
      </div>
    </div>
    <div
      v-if="vapidPublicKey && !isBrowserSafari"
      class="profile&#45;&#45;settings&#45;&#45;row row push-row"
    >

      <div class="columns small-9">
        <p v-if="hasEnabledPushPermissions">
          {{
            $t(
              'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.HAS_ENABLED_PUSH'
            )
          }}
        </p>
        <div v-else class="push-notification&#45;&#45;button">
          <woot-submit-button
            :button-text="
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.REQUEST_PUSH'
              )
            "
            class="button nice small"
            type="button"
            @click="onRequestPermissions"
          />
        </div>
        <div>
          <input
            v-model="selectedPushFlags"
            class="notification&#45;&#45;checkbox"
            type="checkbox"
            value="push_conversation_creation"
            @input="handlePushInput"
          />
          <label for="conversation_creation">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_CREATION'
              )
            }}
          </label>
        </div>

        <div>
          <input
            v-model="selectedPushFlags"
            class="notification&#45;&#45;checkbox"
            type="checkbox"
            value="push_conversation_assignment"
            @input="handlePushInput"
          />
          <label for="conversation_assignment">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT'
              )
            }}
          </label>
        </div>

        <div>
          <input
            v-model="selectedPushFlags"
            class="notification&#45;&#45;checkbox"
            type="checkbox"
            value="push_conversation_mention"
            @input="handlePushInput"
          />
          <label for="conversation_mention">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_MENTION'
              )
            }}
          </label>
        </div>

        <div>
          <input
            v-model="selectedPushFlags"
            class="notification&#45;&#45;checkbox"
            type="checkbox"
            value="push_assigned_conversation_new_message"
            @input="handlePushInput"
          />
          <label for="assigned_conversation_new_message">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.ASSIGNED_CONVERSATION_NEW_MESSAGE'
              )
            }}
          </label>
        </div>
      </div>
    </div>-->

</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import {
  hasPushPermissions,
  requestPushPermissions,
  verifyServiceWorkerExistence,
} from '../../../../helper/pushHelper';

export default {
  mixins: [alertMixin, configMixin, uiSettingsMixin],
  data() {
    return {
      selectedEmailFlags: [],
      selectedPushFlags: [],
      enableAudioAlerts: false,
      hasEnabledPushPermissions: false,
      playAudioWhenTabIsInactive: false,
      alertIfUnreadConversationExist: false,
      notificationTone: 'ding',
      notificationAlertTones: [
        {
          value: 'ding',
          label: 'Ding',
        },
        {
          value: 'bell',
          label: 'Bell',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      emailFlags: 'userNotificationSettings/getSelectedEmailFlags',
      pushFlags: 'userNotificationSettings/getSelectedPushFlags',
      uiSettings: 'getUISettings',
    }),
    isBrowserSafari() {
      if (window.browserConfig) {
        return window.browserConfig.is_safari === 'true';
      }
      return false;
    },
  },
  watch: {
    emailFlags(value) {
      this.selectedEmailFlags = value;
    },
    pushFlags(value) {
      this.selectedPushFlags = value;
    },
    uiSettings(value) {
      this.notificationUISettings(value);
    },
  },
  mounted() {
    if (hasPushPermissions()) {
      this.getPushSubscription();
    }
    this.notificationUISettings(this.uiSettings);
    this.$store.dispatch('userNotificationSettings/get');
  },
  methods: {
    notificationUISettings(uiSettings) {
      const {
        enable_audio_alerts: enableAudio = false,
        always_play_audio_alert: alwaysPlayAudioAlert,
        alert_if_unread_assigned_conversation_exist: alertIfUnreadConversationExist,
        notification_tone: notificationTone,
      } = uiSettings;
      this.enableAudioAlerts = enableAudio;
      this.playAudioWhenTabIsInactive = !alwaysPlayAudioAlert;
      this.alertIfUnreadConversationExist = alertIfUnreadConversationExist;
      this.notificationTone = notificationTone || 'ding';
    },
    onRegistrationSuccess() {
      this.hasEnabledPushPermissions = true;
    },
    onRequestPermissions() {
      requestPushPermissions({
        onSuccess: this.onRegistrationSuccess,
      });
    },
    getPushSubscription() {
      verifyServiceWorkerExistence(registration =>
        registration.pushManager
          .getSubscription()
          .then(subscription => {
            if (!subscription) {
              this.hasEnabledPushPermissions = false;
            } else {
              this.hasEnabledPushPermissions = true;
            }
          })
          // eslint-disable-next-line no-console
          .catch(error => console.log(error))
      );
    },
    async updateNotificationSettings() {
      try {
        this.$store.dispatch('userNotificationSettings/update', {
          selectedEmailFlags: this.selectedEmailFlags,
          selectedPushFlags: this.selectedPushFlags,
        });
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_ERROR'));
      }
    },
    handleEmailInput(e) {
      this.selectedEmailFlags = this.toggleInput(
        this.selectedEmailFlags,
        e.target.value
      );

      this.updateNotificationSettings();
    },
    handlePushInput(e) {
      this.selectedPushFlags = this.toggleInput(
        this.selectedPushFlags,
        e.target.value
      );

      this.updateNotificationSettings();
    },
    handleAudioInput(e) {
      this.enableAudioAlerts = e.target.value;
      this.updateUISettings({
        enable_audio_alerts: this.enableAudioAlerts,
      });
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
    },
    handleAudioAlertConditions(e) {
      let condition = e.target.value;
      if (condition === 'tab_is_inactive') {
        this.updateUISettings({
          always_play_audio_alert: !e.target.checked,
        });
      } else if (condition === 'conversations_are_read') {
        this.updateUISettings({
          alert_if_unread_assigned_conversation_exist: e.target.checked,
        });
      }
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
    },
    handleAudioToneChange(e) {
      this.updateUISettings({ notification_tone: e.target.value });
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        const newSelectedFlags = selected.filter(flag => flag !== current);
        return newSelectedFlags;
      }
      return [...selected, current];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.notification--checkbox {
  font-size: $font-size-large;
}

.push-notification--button {
  margin-bottom: var(--space-one);
}

.notification-items--wrapper {
  margin-bottom: var(--space-smaller);
}

.notification-label {
  display: flex;
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-small);
}

.tone-selector {
  height: var(--space-large);
  padding-bottom: var(--space-micro);
  padding-top: var(--space-micro);
  width: var(--space-mega);
}

.alert_events{
  border-bottom: 0.5px solid #483A9D !important;
  padding-bottom: 1rem !important;
  h5{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #483A9D !important;
    margin-bottom: 1.5rem !important;
  }
}

.section_title{
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #656877 !important;
}

.parag_section{
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #737373 !important;
}

.radio_notif{
  &:checked {
    background-color: mix(#fff, #483a9d , 84%) !important;
    &:before {
      box-shadow: inset 0 0 0 0.4375em #483a9d !important;
    }
  }
}

.alert_label{
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 17px !important;
  color: #737373 !important;
}


.email_notifications{
  border-bottom: 0.5px solid #483A9D !important;
  padding-bottom: 1rem !important;
}

.notif_section{
  margin-left: -12rem !important;
  width: 55vw !important;
  margin-top: -3rem;
}

input[type="checkbox"] {
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

</style>
