<template>
  <div class="custom-attribute--selector">
    <div
      v-on-clickaway="closeDropdown"
      class="label-wrap"
      @keyup.esc="closeDropdown"
    >

        <svg    class="add_attributes_icon"
                @click="toggleAttributeDropDown"
                width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 9.0625H4.5C4.48694 9.0625 4.47128 9.0573 4.45699 9.04301C4.4427 9.02872 4.4375 9.01306 4.4375 9C4.4375 8.98694 4.4427 8.97128 4.45699 8.95699C4.47128 8.9427 4.48694 8.9375 4.5 8.9375H13.5C13.5131 8.9375 13.5287 8.9427 13.543 8.95699C13.5573 8.97128 13.5625 8.98694 13.5625 9C13.5625 9.01306 13.5573 9.02872 13.543 9.04301C13.5287 9.0573 13.5131 9.0625 13.5 9.0625Z" fill="#292D32" stroke="#483A9D"/>
            <path d="M9 14.0625C8.6925 14.0625 8.4375 13.8075 8.4375 13.5V4.5C8.4375 4.1925 8.6925 3.9375 9 3.9375C9.3075 3.9375 9.5625 4.1925 9.5625 4.5V13.5C9.5625 13.8075 9.3075 14.0625 9 14.0625Z" fill="#292D32"/>
        </svg>

        <div class="dropdown-wrap">
        <div
          :class="{ 'dropdown-pane--open': showAttributeDropDown }"
          class="dropdown-pane"
        >
          <custom-attribute-drop-down
            v-if="showAttributeDropDown"
            :attribute-type="attributeType"
            :contact-id="contactId"
            @add-attribute="addAttribute"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomAttributeDropDown from './CustomAttributeDropDown.vue';
import alertMixin from 'shared/mixins/alertMixin';
import attributeMixin from 'dashboard/mixins/attributeMixin';
import { mixin as clickaway } from 'vue-clickaway';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import format from 'date-fns/format';
const DATE_FORMAT = 'yyyy-MM-dd';

export default {
  components: {
    CustomAttributeDropDown,
  },
  mixins: [clickaway, alertMixin, attributeMixin],
  props: {
    attributeType: {
      type: String,
      default: 'conversation_attribute',
    },
    contactId: { type: Number, default: null },
  },
  data() {
    return {
      showAttributeDropDown: false,
    };
  },
  methods: {
    async addAttribute(attribute) {
      try {
        const { attribute_key } = attribute;
        if (this.attributeType === 'conversation_attribute') {
          await this.$store.dispatch('updateCustomAttributes', {
            conversationId: this.conversationId,
            customAttributes: {
              ...this.customAttributes,
              [attribute_key]: null,
            },
          });
        } else {
          /* Add a default value for the added attribute */
          const updatedAttributes = { ...this.customAttributes,
                                      [attribute.attribute_key]: this.defaultValue(attribute.attribute_display_type,
                                                                                   attribute.attribute_key,
                                                                                   attribute.attribute_values) };
          await this.$store.dispatch('contacts/update', {
            id: this.contactId,
            custom_attributes:  updatedAttributes,
              /*[attribute_key]: this.defaultValue(attribute_display_type),*/
          });
        }
        bus.$emit(BUS_EVENTS.FOCUS_CUSTOM_ATTRIBUTE, attribute.attribute_key);
        this.showAlert(this.$t('CUSTOM_ATTRIBUTES.FORM.ADD.SUCCESS'));
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('CUSTOM_ATTRIBUTES.FORM.ADD.ERROR');
        this.showAlert(errorMessage);
      } finally {
        this.closeDropdown();
      }
    },
    /* assign initial values to the attribute according to its type */
    defaultValue(attributeType, attributeKey, attributeValue) {
      switch (attributeType) {
        case 'number' :
          return '0';
        case 'text' :
          return attributeKey; /* set the attribute's name */
        case 'link' :
          return 'https://';
        case 'date' :
          return format(new Date(), DATE_FORMAT); /* set today's date */
        case 'checkbox' :
          return false;
        case 'list' :
          return attributeValue[0]; /* set the first item's list */
      }
    },
    toggleAttributeDropDown() {
      this.showAttributeDropDown = !this.showAttributeDropDown;
    },

    closeDropdown() {
      this.showAttributeDropDown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-attribute--selector {
  width: 100%;
  padding: var(--space-slab) var(--space-normal);
  margin-top: -6px !important;

  .label-wrap {
    line-height: var(--space-medium);
    position: relative;

    .dropdown-wrap {
      display: flex;
      left: -1px;
      margin-right: var(--space-medium);
      position: absolute;
      top: var(--space-medium);
      width: 100%;

      .dropdown-pane {
        //width: 100% !important;
        width: fit-content !important;
        box-sizing: border-box;
        margin-top: 4px;
      }
    }
  }
}

.add_attributes_icon{
  float: right !important;
}

.error {
  color: var(--r-500);
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-medium);
}
</style>
