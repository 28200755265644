<template>
  <div class="cons-history-box">
    <div class="cons-header">
      <div class="left-side">
        <p class="title">{{ title }}</p>
        <p class="amount">
          <span class="rel">{{ amount }}</span>
          <span class="currency">dt</span> <span class="rest">{{ rest }}</span>
        </p>
      </div>
      <div class="points-info">
        <p class="points">{{ points }}</p>
      </div>
    </div>
    <div class="delay">
      <div class="start-date-container">
        <svg
          class="start-date-icon"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 0.9375C6.31066 0.9375 6.5625 1.18934 6.5625 1.5V3.75C6.5625 4.06066 6.31066 4.3125 6 4.3125C5.68934 4.3125 5.4375 4.06066 5.4375 3.75V1.5C5.4375 1.18934 5.68934 0.9375 6 0.9375Z"
            fill="#A49DCF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 0.9375C12.3107 0.9375 12.5625 1.18934 12.5625 1.5V3.75C12.5625 4.06066 12.3107 4.3125 12 4.3125C11.6893 4.3125 11.4375 4.06066 11.4375 3.75V1.5C11.4375 1.18934 11.6893 0.9375 12 0.9375Z"
            fill="#A49DCF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.0625 6.81738C2.0625 6.50672 2.31434 6.25488 2.625 6.25488H15.375C15.6857 6.25488 15.9375 6.50672 15.9375 6.81738C15.9375 7.12804 15.6857 7.37988 15.375 7.37988H2.625C2.31434 7.37988 2.0625 7.12804 2.0625 6.81738Z"
            fill="#A49DCF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.5 11.8125C12.1538 11.8125 11.0625 12.9038 11.0625 14.25C11.0625 15.5962 12.1538 16.6875 13.5 16.6875C14.8462 16.6875 15.9375 15.5962 15.9375 14.25C15.9375 12.9038 14.8462 11.8125 13.5 11.8125ZM9.9375 14.25C9.9375 12.2825 11.5325 10.6875 13.5 10.6875C15.4675 10.6875 17.0625 12.2825 17.0625 14.25C17.0625 16.2175 15.4675 17.8125 13.5 17.8125C11.5325 17.8125 9.9375 16.2175 9.9375 14.25Z"
            fill="#A49DCF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.8198 14.2876C11.8198 13.9769 12.0717 13.7251 12.3823 13.7251H14.6173C14.928 13.7251 15.1798 13.9769 15.1798 14.2876C15.1798 14.5983 14.928 14.8501 14.6173 14.8501H12.3823C12.0717 14.8501 11.8198 14.5983 11.8198 14.2876Z"
            fill="#A49DCF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.5 12.6299C13.8107 12.6299 14.0625 12.8817 14.0625 13.1924V15.4349C14.0625 15.7455 13.8107 15.9974 13.5 15.9974C13.1893 15.9974 12.9375 15.7455 12.9375 15.4349V13.1924C12.9375 12.8817 13.1893 12.6299 13.5 12.6299Z"
            fill="#A49DCF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.55282 4.039C3.07227 4.55652 2.8125 5.33623 2.8125 6.375V12.75C2.8125 13.7888 3.07227 14.5685 3.55282 15.086C4.02636 15.596 4.79471 15.9375 6 15.9375H10.3673C10.0914 15.434 9.9375 14.8557 9.9375 14.25C9.9375 12.2818 11.5318 10.6875 13.5 10.6875C14.1123 10.6875 14.6866 10.8426 15.1875 11.1135V6.375C15.1875 5.33623 14.9277 4.55652 14.4472 4.039C13.9736 3.52904 13.2053 3.1875 12 3.1875H6C4.79471 3.1875 4.02636 3.52904 3.55282 4.039ZM2.72843 3.2735C3.47364 2.47096 4.58029 2.0625 6 2.0625H12C13.4197 2.0625 14.5264 2.47096 15.2716 3.2735C16.0098 4.06848 16.3125 5.16377 16.3125 6.375V12.27C16.3125 12.5041 16.1675 12.7137 15.9485 12.7963C15.7295 12.8789 15.4822 12.8172 15.3276 12.6415C14.8814 12.1341 14.2301 11.8125 13.5 11.8125C12.1532 11.8125 11.0625 12.9032 11.0625 14.25C11.0625 14.707 11.1904 15.1375 11.4138 15.4997L11.4209 15.5116C11.5461 15.7263 11.7068 15.9162 11.889 16.069C12.0702 16.221 12.137 16.4701 12.0561 16.6923C11.9752 16.9146 11.764 17.0625 11.5275 17.0625H6C4.58029 17.0625 3.47364 16.654 2.72843 15.8515C1.99023 15.0565 1.6875 13.9612 1.6875 12.75V6.375C1.6875 5.16377 1.99023 4.06848 2.72843 3.2735Z"
            fill="#A49DCF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.24658 10.2749C8.24658 9.86069 8.58237 9.5249 8.99658 9.5249H9.00332C9.41753 9.5249 9.75332 9.86069 9.75332 10.2749C9.75332 10.6891 9.41753 11.0249 9.00332 11.0249H8.99658C8.58237 11.0249 8.24658 10.6891 8.24658 10.2749Z"
            fill="#A49DCF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.4707 10.2749C5.4707 9.86069 5.80649 9.5249 6.2207 9.5249H6.22744C6.64165 9.5249 6.97744 9.86069 6.97744 10.2749C6.97744 10.6891 6.64165 11.0249 6.22744 11.0249H6.2207C5.80649 11.0249 5.4707 10.6891 5.4707 10.2749Z"
            fill="#A49DCF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.4707 12.5249C5.4707 12.1107 5.80649 11.7749 6.2207 11.7749H6.22744C6.64165 11.7749 6.97744 12.1107 6.97744 12.5249C6.97744 12.9391 6.64165 13.2749 6.22744 13.2749H6.2207C5.80649 13.2749 5.4707 12.9391 5.4707 12.5249Z"
            fill="#A49DCF"
          />
        </svg>
        <p class="start-date">{{ startDate }}</p>
      </div>
      <div class="expire-date-container">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 0.9375C6.31066 0.9375 6.5625 1.18934 6.5625 1.5V3.75C6.5625 4.06066 6.31066 4.3125 6 4.3125C5.68934 4.3125 5.4375 4.06066 5.4375 3.75V1.5C5.4375 1.18934 5.68934 0.9375 6 0.9375Z"
              fill="#FF2C52"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 0.9375C12.3107 0.9375 12.5625 1.18934 12.5625 1.5V3.75C12.5625 4.06066 12.3107 4.3125 12 4.3125C11.6893 4.3125 11.4375 4.06066 11.4375 3.75V1.5C11.4375 1.18934 11.6893 0.9375 12 0.9375Z"
              fill="#FF2C52"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.0625 6.81738C2.0625 6.50672 2.31434 6.25488 2.625 6.25488H15.375C15.6857 6.25488 15.9375 6.50672 15.9375 6.81738C15.9375 7.12804 15.6857 7.37988 15.375 7.37988H2.625C2.31434 7.37988 2.0625 7.12804 2.0625 6.81738Z"
              fill="#FF2C52"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.5 11.8125C12.1538 11.8125 11.0625 12.9038 11.0625 14.25C11.0625 15.5962 12.1538 16.6875 13.5 16.6875C14.8462 16.6875 15.9375 15.5962 15.9375 14.25C15.9375 12.9038 14.8462 11.8125 13.5 11.8125ZM9.9375 14.25C9.9375 12.2825 11.5325 10.6875 13.5 10.6875C15.4675 10.6875 17.0625 12.2825 17.0625 14.25C17.0625 16.2175 15.4675 17.8125 13.5 17.8125C11.5325 17.8125 9.9375 16.2175 9.9375 14.25Z"
              fill="#FF2C52"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.3137 13.1032C12.5329 12.883 12.889 12.8822 13.1092 13.1013L14.6992 14.6838C14.9194 14.9029 14.9202 15.2591 14.7011 15.4793C14.482 15.6995 14.1258 15.7003 13.9056 15.4812L12.3156 13.8987C12.0954 13.6795 12.0946 13.3234 12.3137 13.1032Z"
              fill="#FF2C52"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.685 13.1174C14.9047 13.3371 14.9047 13.6932 14.685 13.9129L13.095 15.5029C12.8753 15.7226 12.5192 15.7226 12.2995 15.5029C12.0798 15.2832 12.0798 14.9271 12.2995 14.7074L13.8895 13.1174C14.1092 12.8977 14.4653 12.8977 14.685 13.1174Z"
              fill="#FF2C52"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.55282 4.039C3.07227 4.55652 2.8125 5.33623 2.8125 6.375V12.75C2.8125 13.7888 3.07227 14.5685 3.55282 15.086C4.02636 15.596 4.79471 15.9375 6 15.9375H10.3673C10.0914 15.434 9.9375 14.8557 9.9375 14.25C9.9375 12.2818 11.5318 10.6875 13.5 10.6875C14.1123 10.6875 14.6866 10.8426 15.1875 11.1135V6.375C15.1875 5.33623 14.9277 4.55652 14.4472 4.039C13.9736 3.52904 13.2053 3.1875 12 3.1875H6C4.79471 3.1875 4.02636 3.52904 3.55282 4.039ZM2.72843 3.2735C3.47364 2.47096 4.58029 2.0625 6 2.0625H12C13.4197 2.0625 14.5264 2.47096 15.2716 3.2735C16.0098 4.06848 16.3125 5.16377 16.3125 6.375V12.27C16.3125 12.5041 16.1675 12.7137 15.9485 12.7963C15.7295 12.8789 15.4822 12.8172 15.3276 12.6415C14.8814 12.1341 14.2301 11.8125 13.5 11.8125C12.1532 11.8125 11.0625 12.9032 11.0625 14.25C11.0625 14.707 11.1904 15.1375 11.4138 15.4997L11.4209 15.5116C11.5461 15.7263 11.7068 15.9162 11.889 16.069C12.0702 16.221 12.137 16.4701 12.0561 16.6923C11.9752 16.9146 11.764 17.0625 11.5275 17.0625H6C4.58029 17.0625 3.47364 16.654 2.72843 15.8515C1.99023 15.0565 1.6875 13.9612 1.6875 12.75V6.375C1.6875 5.16377 1.99023 4.06848 2.72843 3.2735Z"
              fill="#FF2C52"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.24658 10.2749C8.24658 9.86069 8.58237 9.5249 8.99658 9.5249H9.00332C9.41753 9.5249 9.75332 9.86069 9.75332 10.2749C9.75332 10.6891 9.41753 11.0249 9.00332 11.0249H8.99658C8.58237 11.0249 8.24658 10.6891 8.24658 10.2749Z"
              fill="#FF2C52"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.4707 10.2749C5.4707 9.86069 5.80649 9.5249 6.2207 9.5249H6.22744C6.64165 9.5249 6.97744 9.86069 6.97744 10.2749C6.97744 10.6891 6.64165 11.0249 6.22744 11.0249H6.2207C5.80649 11.0249 5.4707 10.6891 5.4707 10.2749Z"
              fill="#FF2C52"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.4707 12.5249C5.4707 12.1107 5.80649 11.7749 6.2207 11.7749H6.22744C6.64165 11.7749 6.97744 12.1107 6.97744 12.5249C6.97744 12.9391 6.64165 13.2749 6.22744 13.2749H6.2207C5.80649 13.2749 5.4707 12.9391 5.4707 12.5249Z"
              fill="#FF2C52"
            />
          </g>
        </svg>
        <p class="expire-date">{{ expireDate }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FidelityConsumptionHistory',
  props: {
    title: String,
    expireDate: String,
    startDate: String,
    points: String,
    rest: String,
    amount: String,
  },
};
</script>

<style scoped>
.cons-history-box {
  box-sizing: border-box;
  position: relative;
  height: 69px;
  width: 298px;
  border-radius: 8px;
  background: #ffffff;
  border: 0.5px solid #a49dcf;
  border-radius: 8px;
  top: 100%;
  margin-top: 2%;
  right: 1.71%;
  padding-right: 2%;
}

.cons-header {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  position: relative;
  width: 137px;
  height: 21.5px;
  left: 10px;
  top: 14px;
}
.title {
  width: 76px;
  height: 17px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #656877;
  flex: none;
  order: 0;
  flex-grow: 0;
  white-space: nowrap;
}
.amount {
  white-space: nowrap;
}
.rel {
  width: 19px;
  height: 17px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #483a9d;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.currency {
  position: relative;
  /*left: 73.91%;*/
  right: 9.79%;
  top: -10px;
  bottom: 20.93%;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #483a9d;
}

.rest {
  position: absolute;
  top: 1%;
  width: 23px;
  height: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #483a9d;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.points-info {
  position: relative;
  height: 24px;
  width: 67px;
  top: 10px;
  border-radius: 4px;
  align-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 0px 10px;
  gap: 10px;
  background: #f9f9f9;
  border: 1px solid #656877;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 13px;
  color: #656877;
}
.points {
  width: 47px;
  height: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: #656877;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.start-date-container {
  position: relative;
  height: 18px;
  width: 100px;
  left: 3%;
  top: 18px;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.start-date {
  /* 17/07/2022 */

  position: absolute;
  left: 21.31%;
  right: 69.38%;
  top: 15.32%;
  bottom: 15.94%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #483a9d;
}
.expire-date-container {
  position: absolute;
  height: 18px;
  width: 50px;
  left: 41%;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.expire-date {
  position: absolute;
  left: 44.06%;
  right: 35.31%;
  top: 15.32%;
  bottom: 15.94%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ff2c52;
}


/*Responsive mode*/

@media (min-width: 1400px) {
  .cons-history-box {
    width: 97%;
  }
}
@media (min-width: 1870px) {
  .cons-history-box {
    width: 97%;
  }
}
</style>
