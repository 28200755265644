<template>
  <woot-button
    variant="link"
    class="current-user textDecoration"
    @click="handleClick"
  >
      <div class="profile d-flex">
          <div class="profile_photo mb-1 ">
              <thumbnail
                      :src="currentUser.avatar_url"
                      :username="currentUser.name"
                      :status="statusOfAgent"
                      should-show-status-always
                      size="38px"
              />
          </div>
          <div class="ml-3 mt--9 fullname_cust">
              <div class="user-name">{{currentUser.name}}</div>
              <div v-bind:class="classObject"  class="user-status">{{statusOfAgent}}</div>
          </div>
          <div class="ml-4 mt-4">
              <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.4401 0.134995H5.76756H1.56006C0.840055 0.134995 0.480056 1.00499 0.990056 1.51499L4.87506 5.39999C5.49756 6.02249 6.51006 6.02249 7.13256 5.39999L8.61005 3.92249L11.0176 1.51499C11.5201 1.00499 11.1601 0.134995 10.4401 0.134995Z" fill="#483A9D"></path>
              </svg>

          </div>
      </div>



  </woot-button>
</template>
<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../../widgets/Thumbnail';

export default {
  components: {
    Thumbnail,
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserAvailability: 'getCurrentUserAvailability',
    }),
    statusOfAgent() {
      return this.currentUserAvailability || 'offline';
    },
      classObject: function () {
          return {
              'text-danger': this.statusOfAgent === 'offline',
              'text-online': this.statusOfAgent === 'online',
              'text-warning': this.statusOfAgent === 'busy',
          }
      }
  },
  methods: {
    handleClick() {
      this.$emit('toggle-menu');
    },
  },
};
</script>

<style scoped lang="scss">
.current-user {
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: 2px solid var(--white);
}

.current-user:hover{
  background: transparent !important;
}

.mt--9{
  margin-top: 9px !important;
}

  .user--name:hover{
    text-decoration: none !important;
  }

  .textDecoration{
    text-decoration: none !important;
  }

</style>
