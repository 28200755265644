<template>
  <div class="preferences_block mt-3">
      <div class="location d-flex pl-3 pr-3">
          <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.2817 7.8439C19.0648 2.43512 14.3942 0 10.2915 0C10.2915 0 10.2915 0 10.2799 0C6.1888 0 1.50662 2.42341 0.289714 7.83219C-1.06627 13.8732 2.59604 18.9893 5.91065 22.2088C7.13915 23.4029 8.71533 24 10.2915 24C11.8677 24 13.4439 23.4029 14.6608 22.2088C17.9754 18.9893 21.6377 13.8849 20.2817 7.8439Z" fill="#008E43"/>
              <path d="M8.14852 8.13421L4.93669 11.273C4.77628 11.4106 4.67383 11.5721 4.67383 11.8033C4.67383 12.0335 4.77639 12.1956 4.93669 12.3343L8.14852 15.472C8.16915 15.4933 8.18908 15.5018 8.20602 15.5018C8.23645 15.5018 8.25742 15.4739 8.2565 15.441C8.25558 15.4217 8.24728 15.3995 8.22827 15.3803C7.47437 14.4341 6.92583 13.3489 6.92583 11.8147C6.92583 10.2796 7.47437 9.17317 8.22827 8.22629C8.24728 8.20731 8.25558 8.18543 8.2565 8.16564C8.25753 8.13211 8.23645 8.10499 8.20602 8.10499C8.18908 8.10466 8.16915 8.11302 8.14852 8.13421Z" fill="white"/>
              <path d="M10.6179 7.86865C9.36028 7.86865 8.8689 9.66893 8.8689 11.8147C8.8689 13.9612 9.36028 15.7375 10.6179 15.7375C11.3726 15.7375 12.0158 15.2911 12.0179 14.9241C12.0183 14.8438 11.9876 14.7669 11.9209 14.6997C11.5632 14.3506 11.4233 14.0017 11.4223 13.6938C11.4199 13.1061 11.9247 12.6682 12.39 12.6682C13.0297 12.6682 13.407 13.1761 13.407 13.8339C13.407 14.4571 13.1442 14.9762 12.8581 15.3919C12.8468 15.4089 12.8418 15.4279 12.8418 15.4445C12.842 15.4771 12.861 15.5039 12.8895 15.5039C12.9068 15.5039 12.927 15.4946 12.9492 15.4721L16.1618 12.3344C16.3222 12.1958 16.4247 12.0336 16.4247 11.8034C16.4247 11.5724 16.3221 11.4107 16.1618 11.2731L12.9492 8.1343C12.9272 8.11207 12.9069 8.10264 12.8895 8.10264C12.8608 8.10264 12.842 8.12964 12.8418 8.16235C12.8418 8.17912 12.8468 8.19786 12.8581 8.21509C13.144 8.63021 13.407 9.14986 13.407 9.77265C13.407 10.4299 13.0297 10.9383 12.39 10.9383C11.9247 10.9383 11.4199 10.5008 11.4223 9.91293C11.4233 9.60502 11.5632 9.25579 11.9209 8.90703C11.9876 8.83951 12.0183 8.76326 12.0179 8.68282C12.0158 8.31532 11.3726 7.86865 10.6179 7.86865Z" fill="white"/>
          </svg>
          <span>Sidi bou said Store</span>
      </div>
      <svg width="1" height="27" viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.5" y1="0.5" x2="0.499999" y2="26.1667" stroke="#A8A8A8" stroke-linecap="round"/>
      </svg>

      <div class="carte pl-3 pr-3">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5598 22.7097C9.47977 22.7097 8.38977 22.3997 7.54977 21.7697L3.26978 18.5697C2.13978 17.7297 1.25977 15.9597 1.25977 14.5397V7.12973C1.25977 5.59973 2.37978 3.96972 3.81978 3.42972L8.79977 1.55973C9.78977 1.18973 11.3398 1.18973 12.3298 1.55973L17.2998 3.42972C18.4698 3.86972 19.4598 5.03973 19.7798 6.33973C19.8298 6.55973 19.7798 6.79974 19.6398 6.97974C19.4998 7.15974 19.2798 7.26972 19.0498 7.26972L11.1398 7.27973C9.86977 7.35973 9.55977 7.56974 9.55977 9.41974V14.8497C9.55977 16.7597 9.80977 16.9997 11.7498 16.9997H17.6698C17.9798 16.9997 18.2598 17.1897 18.3698 17.4797C18.4798 17.7697 18.3998 18.0997 18.1698 18.3097C18.0598 18.3997 17.9698 18.4897 17.8598 18.5697L13.5798 21.7797C12.7198 22.3997 11.6398 22.7097 10.5598 22.7097ZM10.5598 2.78974C10.1098 2.78974 9.64978 2.84974 9.31978 2.97974L4.33977 4.84974C3.49977 5.16973 2.74977 6.23972 2.74977 7.14972V14.5597C2.74977 15.4997 3.40977 16.8197 4.15977 17.3797L8.43977 20.5797C9.58977 21.4297 11.5198 21.4297 12.6698 20.5797L15.4398 18.5097H11.7398C8.97978 18.5097 8.04977 17.5897 8.04977 14.8597V9.42972C8.04977 7.29972 8.55977 5.94974 11.0898 5.78974L11.7398 5.77973H17.8898C17.5898 5.34973 17.1898 5.00974 16.7698 4.84974L11.7998 2.97974C11.4598 2.84974 11.0098 2.78974 10.5598 2.78974Z" fill="#656877"/>
              <path d="M21.9998 11.9697H8.7998C8.3898 11.9697 8.0498 11.6297 8.0498 11.2197C8.0498 10.8097 8.3898 10.4697 8.7998 10.4697H21.9998C22.4098 10.4697 22.7498 10.8097 22.7498 11.2197C22.7498 11.6297 22.4098 11.9697 21.9998 11.9697Z" fill="#656877"/>
              <path d="M19.0598 18.5H11.7398C8.97981 18.5 8.0498 17.58 8.0498 14.85V9.42004C8.0498 7.29004 8.5598 5.94003 11.0898 5.78003L11.7398 5.77002H19.0598C21.8198 5.77002 22.7498 6.69004 22.7498 9.42004V14.97C22.7298 17.61 21.7998 18.5 19.0598 18.5ZM11.7398 7.27002L11.1298 7.28003C9.85981 7.36003 9.5498 7.57004 9.5498 9.42004V14.85C9.5498 16.76 9.79981 17 11.7398 17H19.0598C20.9698 17 21.2298 16.75 21.2498 14.97V9.43002C21.2498 7.52002 20.9998 7.28003 19.0598 7.28003H11.7398V7.27002Z" fill="#656877"/>
              <path d="M12.6501 16.0098H11.3101C10.9001 16.0098 10.5601 15.6698 10.5601 15.2598C10.5601 14.8498 10.9001 14.5098 11.3101 14.5098H12.6501C13.0601 14.5098 13.4001 14.8498 13.4001 15.2598C13.4001 15.6698 13.0701 16.0098 12.6501 16.0098Z" fill="#656877"/>
              <path d="M18.02 16.0098H14.75C14.34 16.0098 14 15.6698 14 15.2598C14 14.8498 14.34 14.5098 14.75 14.5098H18.02C18.43 14.5098 18.77 14.8498 18.77 15.2598C18.77 15.6698 18.44 16.0098 18.02 16.0098Z" fill="#656877"/>
          </svg>
      </div>
      <svg width="1" height="27" viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.5" y1="0.5" x2="0.499999" y2="26.1667" stroke="#A8A8A8" stroke-linecap="round"/>
      </svg>
      <div class="shipping pl-3 pr-3">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 14.75H2C1.59 14.75 1.25 14.41 1.25 14V7.62C1.25 7.29999 1.44999 7.01997 1.73999 6.90997C2.03999 6.79997 2.37001 6.89001 2.57001 7.13C3.18001 7.86 4.13001 8.26999 5.07001 8.23999C5.87001 8.21999 6.60998 7.92001 7.16998 7.39001C7.42998 7.17001 7.63999 6.91 7.79999 6.62C8.10999 6.09 8.26 5.50998 8.25 4.91998C8.23 3.99998 7.83002 3.15999 7.15002 2.54999C6.91002 2.33999 6.83 2.00997 6.94 1.71997C7.05 1.42997 7.33001 1.22998 7.64001 1.22998H15C15.41 1.22998 15.75 1.56998 15.75 1.97998V11.98C15.75 13.52 14.52 14.75 13 14.75ZM2.75 13.25H13C13.69 13.25 14.25 12.69 14.25 12V2.75H9.19C9.54 3.4 9.73 4.13001 9.75 4.89001C9.77 5.76001 9.54998 6.62 9.10999 7.37C8.86999 7.79999 8.53999 8.21001 8.17999 8.51001C7.37999 9.27001 6.27999 9.72 5.10999 9.75C4.27999 9.78 3.46001 9.57 2.76001 9.19V13.25H2.75Z" fill="#656877"/>
              <path d="M19 20.75H18C17.59 20.75 17.25 20.41 17.25 20C17.25 19.31 16.69 18.75 16 18.75C15.31 18.75 14.75 19.31 14.75 20C14.75 20.41 14.41 20.75 14 20.75H10C9.59 20.75 9.25 20.41 9.25 20C9.25 19.31 8.69 18.75 8 18.75C7.31 18.75 6.75 19.31 6.75 20C6.75 20.41 6.41 20.75 6 20.75H5C2.93 20.75 1.25 19.07 1.25 17V14C1.25 13.59 1.59 13.25 2 13.25H13C13.69 13.25 14.25 12.69 14.25 12V5C14.25 4.59 14.59 4.25 15 4.25H16.84C17.83 4.25 18.74 4.78001 19.23 5.64001L20.94 8.63C21.07 8.86 21.07 9.15 20.94 9.38C20.81 9.61 20.56 9.75 20.29 9.75H19C18.86 9.75 18.75 9.86 18.75 10V13C18.75 13.14 18.86 13.25 19 13.25H22C22.41 13.25 22.75 13.59 22.75 14V17C22.75 19.07 21.07 20.75 19 20.75ZM18.65 19.25H19C20.24 19.25 21.25 18.24 21.25 17V14.75H19C18.04 14.75 17.25 13.96 17.25 13V10C17.25 9.04 18.03 8.25 19 8.25L17.93 6.38C17.71 5.99 17.29 5.75 16.84 5.75H15.75V12C15.75 13.52 14.52 14.75 13 14.75H2.75V17C2.75 18.24 3.76 19.25 5 19.25H5.34998C5.67998 18.1 6.74 17.25 8 17.25C9.26 17.25 10.32 18.1 10.65 19.25H13.36C13.69 18.1 14.75 17.25 16.01 17.25C17.27 17.25 18.32 18.1 18.65 19.25Z" fill="#656877"/>
              <path d="M8 22.75C6.48 22.75 5.25 21.52 5.25 20C5.25 18.48 6.48 17.25 8 17.25C9.52 17.25 10.75 18.48 10.75 20C10.75 21.52 9.52 22.75 8 22.75ZM8 18.75C7.31 18.75 6.75 19.31 6.75 20C6.75 20.69 7.31 21.25 8 21.25C8.69 21.25 9.25 20.69 9.25 20C9.25 19.31 8.69 18.75 8 18.75Z" fill="#656877"/>
              <path d="M16 22.75C14.48 22.75 13.25 21.52 13.25 20C13.25 18.48 14.48 17.25 16 17.25C17.52 17.25 18.75 18.48 18.75 20C18.75 21.52 17.52 22.75 16 22.75ZM16 18.75C15.31 18.75 14.75 19.31 14.75 20C14.75 20.69 15.31 21.25 16 21.25C16.69 21.25 17.25 20.69 17.25 20C17.25 19.31 16.69 18.75 16 18.75Z" fill="#656877"/>
              <path d="M22 14.75H19C18.04 14.75 17.25 13.96 17.25 13V10C17.25 9.04 18.04 8.25 19 8.25H20.29C20.56 8.25 20.81 8.39 20.94 8.63L22.65 11.63C22.71 11.74 22.75 11.87 22.75 12V14C22.75 14.41 22.41 14.75 22 14.75ZM19 9.75C18.86 9.75 18.75 9.86 18.75 10V13C18.75 13.14 18.86 13.25 19 13.25H21.25V12.2L19.85 9.75H19Z" fill="#656877"/>
              <path d="M5 9.75C2.38 9.75 0.25 7.62 0.25 5C0.25 3.54 0.900029 2.18998 2.03003 1.28998C2.87003 0.619978 3.93 0.25 5 0.25C7.62 0.25 9.75 2.38 9.75 5C9.75 6.36 9.16 7.66 8.13 8.56C7.26 9.33 6.15 9.75 5 9.75ZM5 1.75C4.26 1.75 3.55997 1.99997 2.96997 2.46997C2.19997 3.07997 1.75 4.01 1.75 5C1.75 6.79 3.21 8.25 5 8.25C5.78 8.25 6.54002 7.96 7.15002 7.44C7.85002 6.82 8.25 5.94 8.25 5C8.25 3.21 6.79 1.75 5 1.75Z" fill="#656877"/>
              <path d="M3.99989 6.75C3.74989 6.75 3.49988 6.62001 3.35988 6.39001C3.14988 6.03001 3.25989 5.56999 3.61989 5.35999L4.5099 4.83002V3.75C4.5099 3.34 4.8499 3 5.2599 3C5.6699 3 6.0099 3.34 6.0099 3.75V5.25C6.0099 5.51 5.86992 5.76001 5.64992 5.89001L4.39992 6.64001C4.25992 6.72001 4.12989 6.75 3.99989 6.75Z" fill="#656877"/>
          </svg>
      </div>
  </div>
</template>

<script>
export default {
    name: "ClientPreferences"
}
</script>

<style scoped lang="scss">
  .preferences_block{
    display: flex;
    justify-content: space-evenly;
    .location{
      span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        margin-left: 5px;
        margin-top: 6px;
      }
    }
  }
</style>
