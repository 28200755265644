<template>
  <div>
    <div v-if="src && deleteAvatar" class="avatar-delete-btn">
      <woot-button
        size="tiny"
        type="button"
        class="delete_btn"
        @click="onAvatarDelete"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9999 4.48669C13.9866 4.48669 13.9666 4.48669 13.9466 4.48669C10.4199 4.13336 6.89994 4.00002 3.41328 4.35336L2.05328 4.48669C1.77328 4.51336 1.52661 4.31336 1.49994 4.03336C1.47328 3.75336 1.67328 3.51336 1.94661 3.48669L3.30661 3.35336C6.85328 2.99336 10.4466 3.13336 14.0466 3.48669C14.3199 3.51336 14.5199 3.76002 14.4933 4.03336C14.4733 4.29336 14.2533 4.48669 13.9999 4.48669Z"
            fill="#656877"
          />
          <path
            d="M5.66651 3.81334C5.63984 3.81334 5.61318 3.81334 5.57984 3.80668C5.31318 3.76001 5.12651 3.50001 5.17318 3.23334L5.31984 2.36001C5.42651 1.72001 5.57318 0.833344 7.12651 0.833344H8.87318C10.4332 0.833344 10.5798 1.75334 10.6798 2.36668L10.8265 3.23334C10.8732 3.50668 10.6865 3.76668 10.4198 3.80668C10.1465 3.85334 9.88651 3.66668 9.84651 3.40001L9.69984 2.53334C9.60651 1.95334 9.58651 1.84001 8.87984 1.84001H7.13318C6.42651 1.84001 6.41318 1.93334 6.31318 2.52668L6.15984 3.39334C6.11984 3.64001 5.90651 3.81334 5.66651 3.81334Z"
            fill="#656877"
          />
          <path
            d="M10.1399 15.1667H5.85985C3.53318 15.1667 3.43985 13.88 3.36652 12.84L2.93318 6.12667C2.91318 5.85334 3.12652 5.61334 3.39985 5.59334C3.67985 5.58 3.91318 5.78667 3.93318 6.06L4.36652 12.7733C4.43985 13.7867 4.46652 14.1667 5.85985 14.1667H10.1399C11.5399 14.1667 11.5665 13.7867 11.6332 12.7733L12.0665 6.06C12.0865 5.78667 12.3265 5.58 12.5999 5.59334C12.8732 5.61334 13.0865 5.84667 13.0665 6.12667L12.6332 12.84C12.5599 13.88 12.4665 15.1667 10.1399 15.1667Z"
            fill="#656877"
          />
          <path
            d="M9.10672 11.5H6.88672C6.61339 11.5 6.38672 11.2733 6.38672 11C6.38672 10.7267 6.61339 10.5 6.88672 10.5H9.10672C9.38005 10.5 9.60672 10.7267 9.60672 11C9.60672 11.2733 9.38005 11.5 9.10672 11.5Z"
            fill="#656877"
          />
          <path
            d="M9.66659 8.83334H6.33325C6.05992 8.83334 5.83325 8.60668 5.83325 8.33334C5.83325 8.06001 6.05992 7.83334 6.33325 7.83334H9.66659C9.93992 7.83334 10.1666 8.06001 10.1666 8.33334C10.1666 8.60668 9.93992 8.83334 9.66659 8.83334Z"
            fill="#656877"
          />
        </svg>
      </woot-button>
    </div>
    <woot-thumbnail
      v-if="src"
      size="80px"
      :src="src"
      :username="usernameAvatar"
    />

    <label class="img-uploader-label mb-3"
    :class="{'d-none': src}"
    >
      <input
        class="img-uploader-input"
        id="file"
        ref="file"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        @change="handleImageUpload"
      />
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.2399 6.60987L20.2398 6.6099L20.2438 6.61766C20.6774 7.45388 21.5459 8 22.5002 8C24.9252 8 26.845 10.0448 26.6888 12.4552L26.6887 12.4561L26.0387 22.781C25.9621 23.9952 25.6029 25.0415 24.9117 25.7803C24.2301 26.5087 23.1677 27 21.5502 27H8.45021C6.83255 27 5.76686 26.5086 5.08373 25.7799C4.3914 25.0414 4.03248 23.9958 3.96187 22.7834L3.96172 22.7811L3.31172 12.4561L3.31166 12.4552C3.15543 10.0448 5.07524 8 7.50021 8C8.45453 8 9.32299 7.45388 9.75659 6.61766L9.75665 6.61769L9.76054 6.60987L10.6597 4.79903C10.8969 4.33071 11.3421 3.8755 11.8901 3.53598C12.4387 3.19609 13.0454 3 13.5752 3H16.4377C16.9605 3 17.5638 3.19556 18.1114 3.53567C18.6581 3.87528 19.1034 4.33058 19.3407 4.79896C19.3409 4.7995 19.3412 4.80003 19.3415 4.80057L20.2399 6.60987ZM16.8752 8.5625H13.1252C12.3366 8.5625 11.6877 9.21136 11.6877 10C11.6877 10.7886 12.3366 11.4375 13.1252 11.4375H16.8752C17.6639 11.4375 18.3127 10.7886 18.3127 10C18.3127 9.21136 17.6639 8.5625 16.8752 8.5625ZM10.2752 18.425C10.2752 21.0396 12.4 23.15 15.0002 23.15C17.6004 23.15 19.7252 21.0396 19.7252 18.425C19.7252 15.8114 17.6139 13.7 15.0002 13.7C12.3866 13.7 10.2752 15.8114 10.2752 18.425Z"
            fill="#483A9D" stroke="#483A9D" />
        </svg>
        <span  class="img-uploader-text">
          Add {{ label }}
        </span>
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    usernameAvatar: {
      type: String,
      default: '',
    },
    deleteAvatar: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  methods: {
    handleImageUpload(event) {
      const [file] = event.target.files;

      this.$emit('change', {
        file,
        url: file ? URL.createObjectURL(file) : null,
      });
    },
    onAvatarDelete() {
      this.$refs.file.value = null;
      this.$emit('onAvatarDelete');
    },
  },
  computed : {
  }
};
</script>

<style lang="scss" scoped>

.avatar-delete-btn{
  margin-bottom: -18px !important;
    position: relative !important;
    z-index: 1 !important;
}
.delete_btn {
  height: 30px !important;
  width: 30px !important;
  padding: 0px !important;
  border-radius: 50%;
  border: 1px solid #DDDDDD;
  background: white !important;
}
.img-uploader-input {
  opacity: 0;
  z-index: -1;
}

.img-uploader-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: #DAD8EB;
  border: 1px dashed #483A9D;
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

.img-uploader-label svg {
  position: absolute;
  top: 10%;
}

.d-none{
  display : none !important;
}

.img-uploader-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #483A9D;
  width: 80%;
  position: absolute;
  top: 44%;
}
</style>
