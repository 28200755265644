<template>

  <div :class="`status-badge status-badge__${status}`" />

</template>
<script>
export default {
  props: {
    status: { type: String, default: '' },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.status-badge {
  width: 10.5px;
  height: 10.5px;
  margin-right: var(--space-micro);
  display: inline-block;
  border-radius: 50%;
  &__online {
    border: 2.5px solid #27AF34;
    background: white;
  }
  &__offline {
    border: 2.5px solid #FF0000;
    background: white;
  }
  &__busy {
    border: 2.5px solid #FFC700;
    background: white;
  }
}
</style>
