<template>
  <div>
    <div class="gen_info pb-3">
      <div class="client_info d-flex mt-4 mb-4 justify-space-between">
        <div class="thumb_client ml-4">
          <Thumbnail
            v-if="this.contact.name"
            :src="this.contact.thumbnail"
            size="55px"
            :username="this.contact.name"
            :status="this.contact.availability_status"
          />
          <Thumbnail
            v-if="!customerFullname"
            src="N/A"
            size="55px"
            username="-- --"
          />
          <span> Since {{ this.customerDate }}  </span>
        </div>
        <div class="general_infomation">
          <div>
            <span class="name" v-if='this.contact.name'>{{ this.contact.name }}</span>
            <span class="name" v-if='!this.contact.name'> Not known </span>
          </div>
          <div class="email">
            <span v-if="customerEmail">{{ contact.email }}</span>
            <span v-if="!customerEmail">Not Available</span>
          </div>
          <div class="phonenumber">
            <span v-if="customerPhone">{{ contact.phone_number }} </span>
            <span v-if="customerPhone == null">Not Available</span>
          </div>
        </div>
<!--        <div class="localisation_client">
          <country-flag
            v-if="customerAdress"
            :country="customerAdress"
            size="normal"
          />
          <svg
            v-if="!customerAdress"
            width="26"
            height="26"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.65 21.41C15.22 21.41 14.79 21.32 14.44 21.15L9.19001 18.52C8.89001 18.37 8.30001 18.38 8.01001 18.55L5.65001 19.9C4.63001 20.48 3.58001 20.56 2.79001 20.09C1.99001 19.63 1.54001 18.69 1.54001 17.51V7.79C1.54001 6.88 2.14001 5.85 2.93001 5.4L7.26001 2.92C7.99001 2.5 9.10001 2.47 9.85001 2.85L15.1 5.48C15.4 5.63 15.98 5.61 16.28 5.45L18.63 4.11C19.65 3.53 20.7 3.45 21.49 3.92C22.29 4.38 22.74 5.32 22.74 6.5V16.23C22.74 17.14 22.14 18.17 21.35 18.62L17.02 21.1C16.64 21.3 16.14 21.41 15.65 21.41ZM8.64001 16.92C9.07001 16.92 9.50001 17.01 9.85001 17.18L15.1 19.81C15.4 19.96 15.98 19.94 16.28 19.78L20.61 17.3C20.93 17.12 21.24 16.58 21.24 16.22V6.49C21.24 5.86 21.06 5.39 20.73 5.21C20.41 5.03 19.91 5.1 19.37 5.41L17.02 6.75C16.29 7.17 15.18 7.2 14.43 6.82L9.18001 4.19C8.88001 4.04 8.30001 4.06 8.00001 4.22L3.67001 6.7C3.35001 6.88 3.04001 7.42 3.04001 7.79V17.52C3.04001 18.15 3.22001 18.62 3.54001 18.8C3.86001 18.99 4.36001 18.91 4.91001 18.6L7.26001 17.26C7.65001 17.03 8.15001 16.92 8.64001 16.92Z"
              fill="#292D32"
            />
            <path
              d="M8.56 17.75C8.15 17.75 7.81 17.41 7.81 17V4C7.81 3.59 8.15 3.25 8.56 3.25C8.97 3.25 9.31 3.59 9.31 4V17C9.31 17.41 8.97 17.75 8.56 17.75Z"
              fill="#292D32"
            />
            <path
              d="M15.73 20.75C15.32 20.75 14.98 20.41 14.98 20V6.62C14.98 6.21 15.32 5.87 15.73 5.87C16.14 5.87 16.48 6.21 16.48 6.62V20C16.48 20.41 16.14 20.75 15.73 20.75Z"
              fill="#292D32"
            />
          </svg>
          <svg
            class="position_svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="7" cy="7" r="6.5" fill="white" stroke="#EDECF6" />
            <path
              d="M6.99995 7.90394C6.11245 7.90394 5.38745 7.18311 5.38745 6.29144C5.38745 5.39977 6.11245 4.68311 6.99995 4.68311C7.88745 4.68311 8.61245 5.40394 8.61245 6.29561C8.61245 7.18727 7.88745 7.90394 6.99995 7.90394ZM6.99995 5.30811C6.45828 5.30811 6.01245 5.74977 6.01245 6.29561C6.01245 6.84144 6.45412 7.28311 6.99995 7.28311C7.54578 7.28311 7.98745 6.84144 7.98745 6.29561C7.98745 5.74977 7.54162 5.30811 6.99995 5.30811Z"
              fill="#483A9D"
            />
            <path
              d="M6.99995 11.4835C6.38328 11.4835 5.76245 11.2502 5.27912 10.7877C4.04995 9.60433 2.69162 7.71683 3.20412 5.471C3.66662 3.4335 5.44578 2.521 6.99995 2.521C6.99995 2.521 6.99995 2.521 7.00412 2.521C8.55828 2.521 10.3375 3.4335 10.8 5.47516C11.3083 7.721 9.94995 9.60433 8.72078 10.7877C8.23745 11.2502 7.61662 11.4835 6.99995 11.4835ZM6.99995 3.146C5.78745 3.146 4.22912 3.79183 3.81662 5.6085C3.36662 7.571 4.59995 9.26266 5.71662 10.3335C6.43745 11.0293 7.56662 11.0293 8.28745 10.3335C9.39995 9.26266 10.6333 7.571 10.1916 5.6085C9.77495 3.79183 8.21245 3.146 6.99995 3.146Z"
              fill="#483A9D"
            />
          </svg>
        </div>-->
      </div>
      <shopping-informations
        :customer-email="contact.email"
        :canceled-orders="canceledOrders"
        :all-orders="allOrders"
      ></shopping-informations>
      <span class="header_title">Fidelity programs</span>
      <fidelity-programs></fidelity-programs>
      <span class="header_title">Ongoing operations</span>
      <ongoing-operations
        :pending-orders="pendingOrders"
        :canceled-orders="canceledOrders"
        :completed-orders="completedOrders"
        :customer-email="contact.email"
      ></ongoing-operations>
      <span class="header_title">Preferences</span>
      <client-preferences></client-preferences>
      <svg
        class="ml-3"
        width="280"
        height="1"
        viewBox="0 0 300 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0.25"
          y1="0.75"
          x2="299.75"
          y2="0.75"
          stroke="#A49DCF"
          stroke-width="0.5"
          stroke-linecap="round"
        />
      </svg>
      <contact-info :contact="contact"></contact-info>
    </div>

    <!--Conversation Actions .. -->
    <span class="accordion_title">{{
      $t('CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_ACTIONS')
    }}</span>
    <div class="conversation_act">
      <transition-group>
        <div
          v-for="element in conversationSidebarItems"
          :key="element.name"
          class="list-group-item"
        >
          <div
            v-if="element.name === 'conversation_actions'"
            class="conversation--actions"
          >
            <accordion-item
              :title="assignement"
              :is-open="isContactSidebarItemOpen('is_conv_actions_open')"
              @click="
                value => toggleSidebarUIState('is_conv_actions_open', value)
              "
            >
              <conversation-action
                :conversation-id="currentChat.id"
                :inbox-id="inboxId"
              />
            </accordion-item>
          </div>
        </div>
      </transition-group>
    </div>
    <div class="conversation_act">
      <transition-group>
        <div
          v-for="element in conversationSidebarItems"
          :key="element.name"
          class="list-group-item"
        >
          <div
            v-if="element.name === 'previous_conversation'"
            class="conversation--actions"
          >
            <accordion-item
              :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_LABELS')"
              :is-open="isContactSidebarItemOpen('is_previous_conv_open')"
              @click="
                value => toggleSidebarUIState('is_previous_conv_open', value)
              "
            >
              <conversation-labels :conversation-id="currentChat.id" />
<!--              <contact-labels :contact-id="contact.id" class="contact-labels" />-->
            </accordion-item>
          </div>
        </div>
      </transition-group>
    </div>
    <div class="conversation_act">
      <transition-group>
        <div
          v-for="element in conversationSidebarItems"
          :key="element.name"
          class="list-group-item"
        >
          <div v-if="element.name === 'macros'">
            <accordion-item
              :title="$t('CONVERSATION_SIDEBAR.ACCORDION.MACROS')"
              :is-open="isContactSidebarItemOpen('is_macro_open')"
              compact
              @click="value => toggleSidebarUIState('is_macro_open', value)"
            >
              <macros-list :conversation-id="currentChat.id" />
            </accordion-item>
          </div>
        </div>
      </transition-group>
    </div>
    <div class="conversation_act">
      <transition-group>
        <div
          v-for="element in conversationSidebarItems"
          :key="element.name"
          class="list-group-item"
        >
          <div v-if="element.name === 'conversation_participants'">
            <accordion-item
              :title="$t('CONVERSATION_PARTICIPANTS.SIDEBAR_TITLE')"
              :is-open="isContactSidebarItemOpen('is_conv_participants_open')"
              @click="
                value =>
                  toggleSidebarUIState('is_conv_participants_open', value)
              "
            >
              <conversation-participant
                :conversation-id="currentChat.id"
                :inbox-id="inboxId"
              />
            </accordion-item>
          </div>
        </div>
      </transition-group>
    </div>
    <div class="conversation_att ">
      <transition-group>
        <div
          v-for="element in conversationSidebarItems"
          :key="element.name"
          class="list-group-item"
        >
          <div v-if="element.name === 'contact_attributes'">
            <accordion-item
              :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONTACT_ATTRIBUTES')"
              :is-open="isContactSidebarItemOpen('is_contact_attributes_open')"
              compact
              @click="
                value =>
                  toggleSidebarUIState('is_contact_attributes_open', value)
              "
            >
              <custom-attributes
                attribute-type="contact_attribute"
                attribute-class="conversation--attribute"
                class="even"
                :contact-id="contact.id"
              />
              <custom-attribute-selector
                attribute-type="contact_attribute"
                :contact-id="contact.id"
              />
            </accordion-item>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import draggable from 'vuedraggable';
import FidelityPrograms from "./FidelityPrograms";
import OngoingOperations from "./OngoingOperations";
import ClientPreferences from "./ClientPreferences";
import ShoppingInformations from "./ShoppingInformations";
import Thumbnail from "../../Thumbnail";
import ConversationAction from "../../../../routes/dashboard/conversation/ConversationAction";
import CountryFlag from 'vue-country-flag'
import ContactInfo from "../../../../routes/dashboard/conversation/contact/ContactInfo";
import OrdersBlock from "./OrdersBlock";
import ContactConversations from "../../../../store/modules/contactConversations";
import ConversationInfo from "../../../../routes/dashboard/conversation/ConversationInfo";
import CustomAttributes from "../../../../routes/dashboard/conversation/customAttributes/CustomAttributes";
import CustomAttributeSelector
    from "../../../../routes/dashboard/conversation/customAttributes/CustomAttributeSelector";
import AccordionItem from "../../../Accordion/AccordionItem";
import alertMixin from "../../../../../shared/mixins/alertMixin";
import uiSettingsMixin from "../../../../../dashboard/mixins/uiSettings";
import ContactLabels from "../../../../routes/dashboard/contacts/components/ContactLabels.vue";
import MacrosList from '../../../../routes/dashboard/conversation/Macros/List.vue';
import ConversationParticipant from '../../../../routes/dashboard/conversation/ConversationParticipant.vue';
import ConversationLabels from '../../../../routes/dashboard/conversation/labels/LabelBox.vue';
export default {
    name: "ClientInformations",
    components: {
        ContactLabels,
        OrdersBlock,
        ContactInfo,
        ConversationAction,
        CountryFlag,
        Thumbnail, ShoppingInformations, ClientPreferences, OngoingOperations, FidelityPrograms,
        draggable,
        AccordionItem,
        ContactConversations,
        ConversationInfo,
        CustomAttributes,
        CustomAttributeSelector,
        ConversationLabels,
        MacrosList,
        ConversationParticipant,

    },
    mixins: [alertMixin, uiSettingsMixin],
    props : {
        contact: {
            type: Object,
            default: () => ({}),
        },
        channelType: {
            type: String,
            default: '',
        },
        onToggle: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            dragEnabled: true,
            conversationSidebarItems: [],
            dragging: false,
            completedOrders :Number,
            pendingOrders :Number,
            canceledOrders : Number,
            allOrders : Number,
            customerDetails : null,
            customerEmail : String,
            customerFullname : String,
            customerDate : String,
            customerPhone : String,
            customerAdress : null,
            assignement : 'Assignement'
        }
    },
    methods: {
        async getClientData() {
            let custEmail = this.contact.email;
            await axios.get(`https://convergence-app-logic.k8s.satoripop.io/customer/details?email=${custEmail}`).then((response) => {
                if (response.data.customerDataCustomerInterface){
                  console.log("response.data", response.data);
                    this.customerDetails = response.data.customerDataCustomerInterface;
                    this.customerFullname = this.customerDetails.firstname + ' ' + this.customerDetails.lastname;
                    this.customerDate = new Date(this.customerDetails.created_at).getFullYear();
                        this.customerDetails.addresses.forEach(country => {
                            if(country.country_id || country.telephone){
                                this.customerAdress = country.country_id.toLowerCase();
                                this.customerPhone = country.telephone;
                            }
                        });
                }
            });
        },
        createdDate() {
          const dateSec = this.contact.created_at;
          /* convert dateSec to milliseconds */
          const dateMSec = dateSec * 1000 ;
          /* return date object */
          const createdDate = new Date(dateMSec).toISOString();
          /* get contact's creation date */
          const createdYear = createdDate.slice(0,4);
          this.customerDate = createdYear;
        },
        onPanelToggle() {
            this.onToggle();
        },
        getContactDetails() {
            if (this.contactId) {
                this.$store.dispatch('contacts/show', { id: this.contactId });
            }
        },
        getAttributesByModel() {
            if (this.contactId) {
                this.$store.dispatch('contacts/show', { id: this.contactId });
            }
        },
        openTranscriptModal() {
            this.showTranscriptModal = true;
        },

        onDragEnd() {
            this.dragging = false;
            this.updateUISettings({
                conversation_sidebar_items_order: this.conversationSidebarItems,
            });
        },
    },
    computed : {
        ...mapGetters({
            currentChat: 'getSelectedChat',
            currentUser: 'getCurrentUser',
            uiFlags: 'inboxAssignableAgents/getUIFlags',
        }),
        channelType() {
            return this.currentChat.meta?.channel;
        },
        conversationAdditionalAttributes() {
            return this.currentConversationMetaData.additional_attributes || {};
        },
        contact() {
            return this.$store.getters['contacts/getContact'](this.contactId);
        },
        contactAdditionalAttributes() {
            return this.contact.additional_attributes || {};
        },
        contactId() {
            return this.currentChat.meta?.sender?.id;
        },
        currentConversationMetaData() {
            return this.$store.getters[
                'conversationMetadata/getConversationMetadata'
                ](this.conversationId);
        },
        hasContactAttributes() {
            const { custom_attributes: customAttributes } = this.contact;
            return customAttributes && Object.keys(customAttributes).length;
        },
    },
    watch: {
        conversationId(newConversationId, prevConversationId) {
            if (newConversationId && newConversationId !== prevConversationId) {
                this.getContactDetails();
            }
        },
        contactId() {
            this.getContactDetails();
        },
    },
    mounted() {
        this.getClientData();
        this.conversationSidebarItems = this.conversationSidebarItemsOrder;
        this.getContactDetails();
        this.$store.dispatch('attributes/get', 0);
        this.createdDate();
    }

}
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.gen_info {
  background: #f0eff7;
  border-radius: 8px;
  padding: 2px;
  margin: 10px;

  .header_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #483a9d;
    margin-bottom: 12px;
    margin-top: 12px;
    margin-left: 1.2rem;
  }

  .thumb_client {
    background-color: #483a9d;
    margin-top: -0.6rem;
    border-top-right-radius: 120px 130px;
    border-top-left-radius: 120px 130px;
    border-bottom-right-radius: 40px 40px;
    border-bottom-left-radius: 40px 40px;

    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 12px;
      color: #ffffff;
      margin-left: 4px;
    }
  }

  .general_infomation {
    margin-inline: auto;
    .name {
      text-transform: capitalize;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
      color: #000000;
    }

    .gender {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #000000;
    }

    .age {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-left: 8px !important;
    }

    .email {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }

    .phonenumber {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }
  }

  .localisation_client {
    display: flex !important;
    margin-right: 6px;
    margin-top: -5px;
  }
}

.btn_grp {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.position_svg {
  margin-left: -10px;
  z-index: 1;
}

.conversation_act {
  padding: 5px 14px !important;
}

.conversation_att {
  padding: 5px 14px !important;
  margin-bottom: 2.5rem !important;
}

.contact--panel {
  background: white;
  border-left: 1px solid var(--color-border);
  font-size: $font-size-small;
  overflow-y: auto;
  overflow: auto;
  position: relative;

  i {
    margin-right: $space-smaller;
  }
}

.list-group {
  .list-group-item {
    background-color: var(--white);
  }
}

::v-deep {
  .contact--profile {
    padding-bottom: var(--space-slab);
    border-bottom: 1px solid var(--color-border);
  }

  .conversation--actions .multiselect-wrap--small {
    .multiselect {
      padding-left: var(--space-medium);
      box-sizing: border-box;
    }

    .multiselect__element {
      span {
        width: 100%;
      }
    }
  }
}

.close-button {
  position: absolute;
  right: $space-two;
  top: $space-slab;
  font-size: $font-size-default;
  color: $color-heading;
  z-index: 9989;
}

.conversation--labels {
  padding: $space-medium;

  .icon {
    margin-right: $space-micro;
    font-size: $font-size-micro;
    color: #fff;
  }

  .label {
    color: #fff;
    padding: 0.2rem;
  }
}

.contact--mute {
  color: $alert-color;
  display: block;
  text-align: left;
}

.contact--actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info {
  margin-top: var(--space-two);
}

.accordion_title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #656877;
  margin: 15px;
}
</style>
