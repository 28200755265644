<template>
  <div class="fidelityContentContainer">
    <div class="fidelityBox">
      <p class="CustomerName">{{ currentContact.name }}</p>
      <div class="CinDiv">
        <p class="CIN">CIN:</p>
        <p class="CinNumber">12345678</p>
      </div>
      <div class="barCode" />
      <p class="fidelityPoints">60127<span>pts</span></p>
    </div>
    <div class="boxesWrapper">
      <conversation-actions-box
        id="gen-ticket"
        title="Generate Ticket Cash"
        btn-label="Generate"
        :show-add-amount="showAddAmount"
        :show-expiration-date="showExpirationDate"
      />
      <conversation-actions-box
        id="phone-refill"
        title="Phone Refill"
        btn-label="Refill"
        :show-phone-number="showPhoneNumber"
        :show-amount="showAmount"
      />
      <conversation-actions-box
        id="gen-voucher"
        title="Generate Voucher"
        btn-label="Generate"
        :show-add-amount="showAddAmount"
        :show-expiration-date="showExpirationDate"
      />
      <conversation-actions-box
        id="gen-credit"
        ref="genCreditChild"
        title="Generate a credit note"
        btn-label="Generate"
        :show-add-amount="showAddAmount"
        :show-fidelity-points="showFidelityPoints"
        :show-expiration-date="showExpirationDate"
      />
      <div class="fidelity-consumption-container">
        <p class="cons-title">Consumption history</p>
        <FidelityConsumptionHistory
          title="Ticket cash"
          amount="30"
          rest="890"
          points="300 pts"
          start-date="17/07/2022"
          expire-date="27/10/2022"
        />
        <FidelityConsumptionHistory
          title="Phone Refill"
          amount="3"
          rest="000"
          points="100 pts"
          start-date="17/07/2022"
          expire-date="27/10/2022"
        />
        <FidelityConsumptionHistory
          title="Voucher"
          amount="150"
          rest="000"
          points="150 pts"
          start-date="17/07/2022"
          expire-date="27/10/2022"
        />
        <FidelityConsumptionHistory
          title="Credit note"
          amount="150"
          rest="000"
          points="150 pts"
          start-date="17/07/2022"
          expire-date="27/10/2022"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ConversationActionsBox from './ConversationActionsBox';
import FidelityConsumptionHistory from './FidelityConsumptionHistory';

export default {
  name: 'FidelityContentVue',
  components: { FidelityConsumptionHistory, ConversationActionsBox },
  data() {
    return {
      showAddAmount: true,
      showExpirationDate: true,
      showPhoneNumber: true,
      showAmount: true,
      showFidelityPoints: true,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta.sender.id
      );
    },
  },
};
</script>

<style scoped>
.fidelityContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxesWrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  top: 22%;
  width: 100%;
}

.fidelityBox {
  position: absolute;
  height: 200px;
  width: 298px;
  right: 1.7%;
  top: 0.7%;
  /*width: 290px;*/
  /*left: 3.5%;*/
  /*right: 0%;*/
  /*top: 1%;*/
  bottom: 0%;
  background: #483a9d;
  box-shadow: 0px 2px 23px rgba(0, 0, 0, 0.230742);
  border-radius: 8px;
  align-items: center;
  align-text: center;
}

.CinNumber {
  height: 17px;
  width: 97px;
  position: absolute;
  left: 9.9%;
  right: 34.79%;
  top: 31%;
  bottom: 60.5%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #dad8eb;
  text-shadow: 0px 2px 4px rgb(0 0 0 / 9%);
}

.CIN {
  height: 17px;
  width: 97px;
  position: absolute;
  left: -31.1%;
  right: 34.79%;
  top: 31%;
  bottom: 60.5%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #dad8eb;
  text-shadow: 0px 2px 4px rgb(0 0 0 / 9%);
}

.CinDiv {
  left: 35.9%;
  right: 34.79%;
  top: 31%;
  bottom: 60.5%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  position: absolute;
  width: 120px;
}

.CustomerName {
  position: absolute;
  width: 120px;
  left: 31.96%;
  right: 33.85%;
  top: 16.5%;
  bottom: 71.5%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgb(0 0 0 / 9%);
  white-space: nowrap;
}
.barCode {
  position: absolute;
  left: 30.94%;
  right: 30.69%;
  top: 44.5%;
  bottom: 37.03%;
}
.fidelityPoints {
  position: absolute;
  display: inline-block;
  width: 120px;
  height: 33px;
  left: 24.96%;
  right: 45.7%;
  top: 67.97%;
  bottom: 15.53%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  white-space: nowrap;
}
.fidelityPoints span {
  position: absolute;
  top: 15.47%;
  margin-left: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.5;
}
#gen-ticket {
  top: 22%;
}
#closed-gen-ticket {
  top: 22%;
  z-index: 1;
}
#phone-refill {
  top: 40.5%;
  margin-top: 2%;
}
#closed-phone-refill {
  top: 40.5%;
  z-index: 1;
}
#gen-voucher {
  top: 59%;
  margin-top: 2%;
}
#closed-gen-voucher {
  top: 59%;
  z-index: 1;
}
#gen-credit {
  top: 77.5%;
  margin-top: 2%;
  /*height: 218px !important;*/
}
#gen-credit active {
  top: 77.5%;
  margin-top: 2%;
  height: 215px !important;
}

#closed-gen-credit {
  top: 77.5%;
  z-index: 1;
}
.fidelity-consumption-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  height: auto;
  top: 100%;
  margin-top: 8%;
}
.cons-title {
  height: 22px;
  width: 180px;
  position: relative;
  left: -117px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #656877;
}

/*Responsive mode*/

@media (max-width: 1200px) {
  .fidelity-consumption-container {
    width: 100%;
  }
  .fidelityBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 97%;
    right: 1.4%;
    top: 0.7%;
  }
  .CustomerName {
    position: relative;
    top: 17%;
  }
  .CinDiv {
    position: relative;
    left: 3.9%;
    top: 16%;
  }
  .fidelityPoints {
    position: relative;
    display: inline-block;
    top: 50%;
  }
  .fidelityPoints span {
    position: relative;
    top: 3.47%;
  }
}

@media (min-width: 1400px) {
  .fidelity-consumption-container {
    width: 100%;
  }
  .fidelityBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 97%;
    right: 1.4%;
    top: 0.7%;
  }
  .cons-title {
    width: 97%;
    left: 1%;
  }
}

@media (min-width: 1870px) {
  .fidelity-consumption-container {
    width: 100%;
  }
  .fidelityBox {
    width: 96.5%;
  }
  .cons-title {
    width: 97%;
    left: 1%;
  }
}
</style>
