<template>
  <div class="column content-box">
    <div class="row">
      <div class="columns">
        <woot-button
          color-scheme="primary"
          class-names="  "
          icon="add-circle"
          @click="openAddPopup()"
          class="float-right mt-2 mb-5"
        >
          {{ $t('AGENT_MGMT.HEADER_BTN_TXT') }}
        </woot-button>

        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('AGENT_MGMT.LOADING')"
        />
        <div v-else>
          <p v-if="!agentList.length">
            {{ $t('AGENT_MGMT.LIST.404') }}
          </p>
          <table class="table table-hover mb-4">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th class="">Actions</th>
              </tr>
              <tr v-for="(agent, index) in agentList" :key="agent.email">
                <td class="d-flex">
                  <thumbnail
                    :src="agent.thumbnail"
                    class="columns"
                    :username="agent.name"
                    size="30px"
                    :status="agent.availability_status"
                  />
                  <span class="ml-3 mt-2">{{ agent.name }}</span>
                </td>
                <td>
                  {{ agent.email }}
                </td>
                <td>
                  {{ $t(`AGENT_MGMT.AGENT_TYPES.${agent.role.toUpperCase()}`) }}
                </td>
                  <!-- Agent Verification Status -->
                <td>
                  <span v-if="agent.confirmed" class="verified-text">{{ $t('AGENT_MGMT.LIST.VERIFIED') }}
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00016 15.1667C4.04683 15.1667 0.833496 11.9534 0.833496 8.00004C0.833496 4.04671 4.04683 0.833374 8.00016 0.833374C11.9535 0.833374 15.1668 4.04671 15.1668 8.00004C15.1668 11.9534 11.9535 15.1667 8.00016 15.1667ZM8.00016 1.83337C4.60016 1.83337 1.8335 4.60004 1.8335 8.00004C1.8335 11.4 4.60016 14.1667 8.00016 14.1667C11.4002 14.1667 14.1668 11.4 14.1668 8.00004C14.1668 4.60004 11.4002 1.83337 8.00016 1.83337Z"
                        fill="#27AF34"
                      />
                      <path
                        d="M7.05346 10.3866C6.92012 10.3866 6.79346 10.3333 6.70012 10.2399L4.81346 8.35328C4.62012 8.15994 4.62012 7.83994 4.81346 7.64661C5.00679 7.45328 5.32679 7.45328 5.52012 7.64661L7.05346 9.17994L10.4801 5.75328C10.6735 5.55994 10.9935 5.55994 11.1868 5.75328C11.3801 5.94661 11.3801 6.26661 11.1868 6.45994L7.40679 10.2399C7.31346 10.3333 7.18679 10.3866 7.05346 10.3866Z"
                        fill="#27AF34"
                      />
                    </svg>
                  </span>
                  <span v-if="!agent.confirmed" class="pending-text" >{{ $t('AGENT_MGMT.LIST.VERIFICATION_PENDING') }}
                      <svg
                              width="13"
                              height="15"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                                d="M12 2.625C6.82518 2.625 2.625 6.82518 2.625 12C2.625 17.1748 6.82518 21.375 12 21.375C17.1748 21.375 21.375 17.1748 21.375 12C21.375 6.82518 17.1748 2.625 12 2.625ZM1.375 12C1.375 6.13482 6.13482 1.375 12 1.375C17.8652 1.375 22.625 6.13482 22.625 12C22.625 17.8652 17.8652 22.625 12 22.625C6.13482 22.625 1.375 17.8652 1.375 12Z"
                                fill="#f96106"
                        />
                        <path
                                d="M11.63 6.885C11.9752 6.885 12.255 7.16482 12.255 7.51V11.61C12.255 11.7905 12.3222 12.0343 12.4604 12.2766C12.5987 12.5191 12.7742 12.7008 12.9286 12.7923L12.9303 12.7933L16.0303 14.6433C16.3267 14.8202 16.4236 15.2039 16.2467 15.5003C16.0698 15.7967 15.6861 15.8936 15.3897 15.7167L12.2914 13.8677C12.291 13.8675 12.2907 13.8673 12.2904 13.8671C11.9053 13.6386 11.5911 13.2756 11.3746 12.8959C11.1578 12.5157 11.005 12.0595 11.005 11.61V7.51C11.005 7.16482 11.2848 6.885 11.63 6.885Z"
                                fill="#f96106"
                        />
                      </svg>
                  </span>
                </td>
                <td class="actions">
                  <div class="actions-icons d-flex ">
                    <div
                      class="btn icon-btn icon-btn-success"
                      @click="openEditPopup(agent)"
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.61666 16.2666C4.10832 16.2666 3.63332 16.0916 3.29166 15.7666C2.85832 15.3583 2.64999 14.7416 2.72499 14.075L3.03332 11.375C3.09166 10.8666 3.39999 10.1916 3.75832 9.82496L10.6 2.58329C12.3083 0.774959 14.0917 0.72496 15.9 2.43329C17.7083 4.14163 17.7583 5.92496 16.05 7.73329L9.20832 14.975C8.85832 15.35 8.20832 15.7 7.69999 15.7833L5.01666 16.2416C4.87499 16.25 4.74999 16.2666 4.61666 16.2666ZM13.275 2.42496C12.6333 2.42496 12.075 2.82496 11.5083 3.42496L4.66666 10.675C4.49999 10.85 4.30832 11.2666 4.27499 11.5083L3.96666 14.2083C3.93332 14.4833 3.99999 14.7083 4.14999 14.85C4.29999 14.9916 4.52499 15.0416 4.79999 15L7.48332 14.5416C7.72499 14.5 8.12499 14.2833 8.29166 14.1083L15.1333 6.86663C16.1667 5.76663 16.5417 4.74996 15.0333 3.33329C14.3667 2.69163 13.7917 2.42496 13.275 2.42496Z"
                          fill="#656877"
                        />
                        <path
                          d="M14.4502 9.12504C14.4335 9.12504 14.4085 9.12504 14.3919 9.12504C11.7919 8.8667 9.7002 6.8917 9.3002 4.30837C9.2502 3.9667 9.48353 3.65004 9.8252 3.5917C10.1669 3.5417 10.4835 3.77504 10.5419 4.1167C10.8585 6.13337 12.4919 7.68337 14.5252 7.88337C14.8669 7.9167 15.1169 8.22504 15.0835 8.5667C15.0419 8.88337 14.7669 9.12504 14.4502 9.12504Z"
                          fill="#656877"
                        />
                        <path
                          d="M17.5 18.9584H2.5C2.15833 18.9584 1.875 18.675 1.875 18.3334C1.875 17.9917 2.15833 17.7084 2.5 17.7084H17.5C17.8417 17.7084 18.125 17.9917 18.125 18.3334C18.125 18.675 17.8417 18.9584 17.5 18.9584Z"
                          fill="#656877"
                        />
                      </svg>
                    </div>
                    <div
                      class="btn icon-btn icon-btn-danger"
                      @click="openDeletePopup(agent, index)"
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 6.73001C20.98 6.73001 20.95 6.73001 20.92 6.73001C15.63 6.20001 10.35 6.00001 5.12001 6.53001L3.08001 6.73001C2.66001 6.77001 2.29001 6.47001 2.25001 6.05001C2.21001 5.63001 2.51001 5.27001 2.92001 5.23001L4.96001 5.03001C10.28 4.49001 15.67 4.70001 21.07 5.23001C21.48 5.27001 21.78 5.64001 21.74 6.05001C21.71 6.44001 21.38 6.73001 21 6.73001Z"
                          fill="#292D32"
                        ></path>
                        <path
                          d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z"
                          fill="#292D32"
                        ></path>
                        <path
                          d="M15.21 22.75H8.78999C5.29999 22.75 5.15999 20.82 5.04999 19.26L4.39999 9.19C4.36999 8.78 4.68999 8.42 5.09999 8.39C5.51999 8.37 5.86999 8.68 5.89999 9.09L6.54999 19.16C6.65999 20.68 6.69999 21.25 8.78999 21.25H15.21C17.31 21.25 17.35 20.68 17.45 19.16L18.1 9.09C18.13 8.68 18.49 8.37 18.9 8.39C19.31 8.42 19.63 8.77 19.6 9.19L18.95 19.26C18.84 20.82 18.7 22.75 15.21 22.75Z"
                          fill="#292D32"
                        ></path>
                        <path
                          d="M13.66 17.25H10.33C9.91999 17.25 9.57999 16.91 9.57999 16.5C9.57999 16.09 9.91999 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z"
                          fill="#292D32"
                        ></path>
                        <path
                          d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z"
                          fill="#292D32"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-agent :on-close="hideAddPopup" />
    </woot-modal>
    <!-- Edit Agent -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-agent
        v-if="showEditPopup"
        :id="currentAgent.id"
        :name="currentAgent.name"
        :type="currentAgent.role"
        :email="currentAgent.email"
        :availability="currentAgent.availability_status"
        :on-close="hideEditPopup"
      />
    </woot-modal>
    <!-- Delete Agent -->
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('AGENT_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Thumbnail from '../../../../components/widgets/Thumbnail';
import AddAgent from './AddAgent';
import EditAgent from './EditAgent';

export default {
  components: {
    AddAgent,
    EditAgent,
    Thumbnail,
  },
  mixins: [globalConfigMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      agentAPI: {
        message: '',
      },
      currentAgent: {},
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),
    deleteConfirmText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.YES')} ${
        this.currentAgent.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.NO')} ${
        this.currentAgent.name
      }`;
    },
    deleteMessage() {
      return ` ${this.currentAgent.name}?`;
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
  },
  methods: {
    showEditAction(agent) {
      return this.currentUserId !== agent.id;
    },
    showDeleteAction(agent) {
      if (this.currentUserId === agent.id) {
        return false;
      }

      if (!agent.confirmed) {
        return true;
      }

      if (agent.role === 'administrator') {
        return this.verifiedAdministrators().length !== 1;
      }
      return true;
    },
    verifiedAdministrators() {
      return this.agentList.filter(
        agent => agent.role === 'administrator' && agent.confirmed
      );
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Function
    openEditPopup(agent) {
      this.showEditPopup = true;
      this.currentAgent = agent;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Function
    openDeletePopup(agent) {
      this.showDeletePopup = true;
      this.currentAgent = agent;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentAgent.id] = true;
      this.closeDeletePopup();
      this.deleteAgent(this.currentAgent.id);
    },
    async deleteAgent(id) {
      try {
        await this.$store.dispatch('agents/delete', id);
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.ERROR_MESSAGE'));
      }
    },
    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.currentAgent.id] = false;
      this.currentAgent = {};
      // Show message
      this.agentAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>
