<template>
  <div>
    <div v-bind:class="{ 'ui-snackbar-info' : category.type === 'info' , 'ui-snackbar-success' : category.type === 'success'}"  class="ui-snackbar">
        <!--info-->
        <svg v-if="category.type === 'info'" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 45.8334C36.4583 45.8334 45.8333 36.4584 45.8333 25.0001C45.8333 13.5417 36.4583 4.16675 25 4.16675C13.5416 4.16675 4.16663 13.5417 4.16663 25.0001C4.16663 36.4584 13.5416 45.8334 25 45.8334Z" stroke="#483A9D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.9958 35.4167L24.9958 25.0001" stroke="#483A9D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.0072 18.75L24.9885 18.75" stroke="#483A9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <!--Warning-->
        <svg v-if="category.type === 'warning'" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 18.75V29.1667" stroke="#F8AB26" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25 44.6042H12.375C5.14586 44.6042 2.12502 39.4376 5.62502 33.1251L12.125 21.4167L18.25 10.4167C21.9584 3.72925 28.0417 3.72925 31.75 10.4167L37.875 21.4376L44.375 33.1459C47.875 39.4584 44.8334 44.6251 37.625 44.6251H25V44.6042Z" stroke="#F8AB26" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.9885 35.4167H25.0072" stroke="#F8AB26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <!--Success-->
        <svg v-if="category.type === 'success'" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 43.8334C34.4583 43.8334 43.8333 34.4584 43.8333 23.0001C43.8333 11.5417 34.4583 2.16675 23 2.16675C11.5416 2.16675 2.16663 11.5417 2.16663 23.0001C2.16663 34.4584 11.5416 43.8334 23 43.8334Z" stroke="#27AF34" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1458 23.0001L20.0416 28.8959L31.8541 17.1042" stroke="#27AF34" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <!--Aleert-->
        <svg v-if="category.type === 'alert'"  width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 43.8333C34.4583 43.8333 43.8333 34.4583 43.8333 23C43.8333 11.5416 34.4583 2.16663 23 2.16663C11.5416 2.16663 2.16663 11.5416 2.16663 23C2.16663 34.4583 11.5416 43.8333 23 43.8333Z" stroke="#FF0000" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.1041 28.8957L28.8958 17.104" stroke="#FF0000" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M28.8958 28.8957L17.1041 17.104" stroke="#FF0000" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <!--Message-->
        <div class="ui-snackbar-text">
        {{ message }}
      </div>
      <div v-if="action" class="ui-snackbar-action">
        <router-link v-if="action.type === 'link'" :to="action.to">
          {{ action.message }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: { type: String, default: '' },
    action: {
      type: Object,
      default: () => {},
    },
      category: {
          type: String,
          default: 'info',
      },
    showButton: Boolean,
    duration: {
      type: [String, Number],
      default: 3000,
    },
  },
  data() {
    return {
      toggleAfterTimeout: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>
