<template>
    <div class="row vh-100">
        <div class="columns large-6 bg-white login-section-wrapper login">
            <div class="convergence__logo">
                <img alt="" :src="require('../../assets/images/convergence_logo_log.png')">
            </div>

            <div class="login__form">
                <form @submit.prevent="login()">
                    <h5 class="fs-18 text-black font-inter">Login to Convergence</h5>
                    <div class="column log-in-form">
                        <input
                                :class="{ error: $v.credentials.email.$error }"
                                v-model.trim="credentials.email"
                                type="text"
                                data-testid="email_input"
                                :placeholder="$t('LOGIN.EMAIL.PLACEHOLDER')"
                                @input="$v.credentials.email.$touch"
                        />
                        <input
                                :class="{ error: $v.credentials.password.$error }"
                                v-model.trim="credentials.password"
                                type="password"
                                data-testid="password_input"
                                :placeholder="$t('LOGIN.PASSWORD.PLACEHOLDER')"
                                @input="$v.credentials.password.$touch"
                        />
                        <router-link class="forgot__pass fs-14 font-inter text-primary" to="auth/reset/password">Forgot Password</router-link>
                        <woot-submit-button
                                :disabled="
                                            $v.credentials.email.$invalid ||
                                            $v.credentials.password.$invalid ||
                                            loginApi.showLoading
                                          "
                                :button-text="$t('LOGIN.SUBMIT')"
                                :loading="loginApi.showLoading"
                                button-class="large expanded"
                                class="login_btn"
                        />
                    </div>
                </form>
            </div>
            <div class="copyright font-inter">
                Convergence © 2022 All rights reserved
            </div>
        </div>

        <div class="columns large-6 second-wrapper text-center "
             :style="{'background-image': `url(${require('../../assets/images/background_rect.png')})`}"
        >
            <div class="display-block">
                <img class="converTable_img" alt="" :src="require('../../assets/images/conver_tables.png')">
                <div class="description_conver">
                    <h4 class="display-10 font-inter">Convergence</h4>
                    <p class=" fs-14 mt-3 font-inter">All in one product to increase your income, handle <span>high volume</span> of requests in no time and provide <span class="text-primary"> excellent customer </span> experience through different channels.</p>
                </div>
            </div>
        </div>
    </div>
</template>



<script>

import { required, email } from 'vuelidate/lib/validators';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import WootSubmitButton from '../../components/buttons/FormSubmitButton';
import { mapGetters } from 'vuex';
import router from "../index";
import team from "../../../shared/constants/locales";

export default {
    components: {
        WootSubmitButton,
    },
    mixins: [globalConfigMixin],
    props: {
        ssoAuthToken: { type: String, default: '' },
        ssoAccountId: { type: String, default: '' },
        ssoConversationId: { type: String, default: '' },
        config: { type: String, default: '' },
        email: { type: String, default: '' },
    },
    data() {
        return {
            // We need to initialize the component with any
            // properties that will be used in it
            credentials: {
                email: '',
                password: '',
            },
            loginApi: {
                message: '',
                showLoading: false,
            },
            error: '',
        };
    },
    validations: {
        credentials: {
            password: {
                required,
            },
            email: {
                required,
                email,
            },
        },
    },
    computed: {
        ...mapGetters({
            globalConfig: 'globalConfig/get',
        }),
    },
    created() {
        if (this.ssoAuthToken) {
            this.login();
        }
    },
    methods: {
        showAlert(message,category) {
            // Reset loading, current selected agent
            this.loginApi.showLoading = false;
            this.loginApi.message = message;
            bus.$emit('newToastMessage', this.loginApi.message);
            category = ''
        },
        showSignupLink() {
            return window.convergenceConfig.signupEnabled === 'true';
        },
        login() {
            this.loginApi.showLoading = true;
            const credentials = {
                email: this.email
                    ? decodeURIComponent(this.email)
                    : this.credentials.email,
                password: this.credentials.password,
                sso_auth_token: this.ssoAuthToken,
                ssoAccountId: this.ssoAccountId,
                ssoConversationId: this.ssoConversationId,
            };
            this.$store
                .dispatch('login', credentials)
                .then(() => {
                    this.showAlert(this.$t('LOGIN.API.SUCCESS_MESSAGE'),'info');
                })
                .catch(response => {
                    // Reset URL Params if the authentication is invalid
                    if (this.email) {
                        window.location = '/app/login';
                    }

                    if (response && response.status === 401) {
                        const { errors } = response.data;
                        const hasAuthErrorMsg =
                            errors &&
                            errors.length &&
                            errors[0] &&
                            typeof errors[0] === 'string';
                        if (hasAuthErrorMsg) {
                            this.showAlert(errors[0]);
                        } else {
                            this.showAlert(this.$t('LOGIN.API.UNAUTH'));
                        }
                        return;
                    }
                    this.showAlert(this.$t('LOGIN.API.ERROR_MESSAGE'));
                });
        },
    },
};
</script>

<style scoped lang="scss">
@import "app/javascript/dashboard/assets/scss/components/_templogin.scss";
</style>
