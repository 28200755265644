<template>
  <div class="inbox--name">
    <fluent-icon class="inbox--icon" :icon="computedInboxClass" size="12" />
    {{ inbox.name }}
  </div>
</template>
<script>
import { getInboxClassByType } from 'dashboard/helper/inbox';

export default {
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    computedInboxClass() {
      const { phone_number: phoneNumber, channel_type: type } = this.inbox;
      const classByType = getInboxClassByType(type, phoneNumber);
      return classByType;
    },
  },
};
</script>
<style scoped>
.inbox--name {
  display: inline-flex;
  padding: var(--space-micro) 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #A8A8A8;
    margin-top: 2px;
}

.inbox--icon {
  margin-right: var(--space-micro);
}
</style>
