<template>
  <div class="notification-list-item--wrap h-full flex-view ">
    <woot-button
      v-for="notificationItem in notifications"
      v-show="!isLoading"
      :key="notificationItem.id"
      size="expanded"
      color-scheme="secondary"
      variant="link"
      @click="() => onClickNotification(notificationItem)"
    >
      <div class="notification-list--wrap flex-view w-full">
        <div
          v-if="!notificationItem.read_at"
          class="notification-unread--indicator"
        />
        <div v-else class="empty flex-view" />
        <div class="notification-content--wrap w-full flex-space-between">
          <div class="flex-space-between">
            <div class="title-wrap flex-view ">
              <span class="notification-title">
                {{
                  `#${
                    notificationItem.primary_actor
                      ? notificationItem.primary_actor.id
                      : $t(`NOTIFICATIONS_PAGE.DELETE_TITLE`)
                  }`
                }}
              </span>
              <span class="notification-type">
                {{
                  $t(
                    `NOTIFICATIONS_PAGE.TYPE_LABEL.${notificationItem.notification_type}`
                  )
                }}
              </span>
            </div>
            <div>
              <thumbnail
                v-if="notificationItem.primary_actor.meta.assignee"
                :src="notificationItem.primary_actor.meta.assignee.thumbnail"
                size="16px"
                :username="notificationItem.primary_actor.meta.assignee.name"
              />
            </div>
          </div>
          <div class="w-full flex-view ">
            <span class="notification-message text-truncate">
              {{ notificationItem.push_message_title }}
            </span>
          </div>
          <span class="timestamp flex-view">
            {{ dynamicTime(notificationItem.created_at) }}
          </span>
        </div>
      </div>
    </woot-button>
<!--    <empty-state

      :title="$t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.EMPTY_MESSAGE')"
    />-->

      <div v-if="showEmptyResult" class="row empty-state" :style="{'padding-top' : '16rem'}">
          <svg class="svg_cust" width="140" height="140" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M119.583 16.6667H80.4167C71.7501 16.6667 64.6667 23.6667 64.6667 32.3334V40.1667C64.6667 48.8334 71.6667 55.8334 80.3334 55.8334H119.583C128.25 55.8334 135.25 48.8334 135.25 40.1667V32.3334C135.333 23.6667 128.25 16.6667 119.583 16.6667Z" fill="#DAD8EB"/>
              <path d="M143.667 40.1667C143.667 53.4167 132.833 64.2501 119.583 64.2501H80.4167C67.1667 64.2501 56.3334 53.4167 56.3334 40.1667C56.3334 35.5001 51.3334 32.5834 47.1667 34.7501C35.4167 41.0001 27.4167 53.4167 27.4167 67.6667V146.083C27.4167 166.583 44.1667 183.333 64.6667 183.333H135.333C155.833 183.333 172.583 166.583 172.583 146.083V67.6667C172.583 53.4167 164.583 41.0001 152.833 34.7501C148.667 32.5834 143.667 35.5001 143.667 40.1667ZM127.833 106.083L94.5001 139.417C93.2501 140.667 91.6667 141.25 90.0834 141.25C88.5001 141.25 86.9167 140.667 85.6667 139.417L73.1667 126.917C70.7501 124.5 70.7501 120.5 73.1667 118.083C75.5834 115.667 79.5834 115.667 82.0001 118.083L90.0834 126.167L119 97.2501C121.417 94.8334 125.417 94.8334 127.833 97.2501C130.25 99.6667 130.25 103.667 127.833 106.083Z" fill="#DAD8EB"/>
          </svg>
          <h3 class="title">
              {{ $t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.EMPTY_MESSAGE') }}
          </h3>
      </div>

    <woot-button
      v-if="!isLoading && inLastPage"
      size="medium"
      variant="clear"
      color-scheme="primary"
      class="btn btn-primary  float-right btn_custt"
      @click="openNotificationPage"
    >
      {{ $t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.ALL_NOTIFICATIONS') }}
    </woot-button>
    <div v-if="isLoading" class="notifications-loader flex-view">
      <spinner />
      <span>{{
        $t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.LOADING_UNREAD_MESSAGE')
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import timeMixin from '../../../../mixins/time';

export default {
  components: {
    Thumbnail,
    Spinner,
    EmptyState,
  },
  mixins: [timeMixin],
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    onClickNotification: {
      type: Function,
      default: () => {},
    },
    inLastPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      notificationMetadata: 'notifications/getMeta',
    }),
    showEmptyResult() {
      return !this.isLoading && this.notifications.length === 0;
    },
  },
  methods: {
    openNotificationPage() {
      if (this.$route.name !== 'notifications_index') {
        this.$router.push({
          name: 'notifications_index',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-view {
  display: flex;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.notification-list-item--wrap {
  flex-direction: column;
  padding: var(--space-small) var(--space-slab);
  overflow: auto;
}

.empty {
  width: var(--space-small);
}

.notification-list--wrap {
  flex-direction: row;
  align-items: center;
  padding: var(--space-slab);
  line-height: 1.4;
  border-bottom: 1px solid var(--b-50);
}

.notification-list--wrap:hover {
  background: var(--b-100);
  border-radius: var(--border-radius-normal);
}

.notification-content--wrap {
  flex-direction: column;
  margin-left: var(--space-slab);
  overflow: hidden;
}

.title-wrap {
  align-items: center;
}

.notification-title {
  font-weight: var(--font-weight-black);
}

.notification-type {
  font-size: var(--font-size-micro);
  padding: var(--space-micro) var(--space-smaller);
  margin-left: var(--space-small);
  background: var(--s-50);
  border-radius: var(--border-radius-normal);
}

.notification-message {
  color: var(--color-body);
  font-weight: var(--font-weight-normal);
}

.timestamp {
  margin-top: var(--space-smaller);
  color: var(--b-500);
  font-size: var(--font-size-micro);
  font-weight: var(--font-weight-bold);
}

.notification-unread--indicator {
  width: var(--space-small);
  height: var(--space-small);
  border-radius: var(--border-radius-rounded);
  background: var(--color-woot);
}

.action-button {
  margin-top: var(--space-slab);
}

.notifications-loader {
  align-items: center;
  justify-content: center;
  margin: var(--space-larger) var(--space-small);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
}

.title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 50px;
  color: #A8A8A8;
  margin-top: 2.3rem;
}

.svg_cust{
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.btn_custt{
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 50%;
}
</style>
