<template>
    <div class="sinister">
        <button class="sinister_btn">
            Declare a sinister
        </button>
        <div class="sinister_card mt-2">
            <div class="sinister_header d-flex">
                <h3>
                    <span class="sin_type">Type :</span> Breaking of glass
                </h3>
                <div class="type_badge pending">
                    Pending
                </div>
            </div>

            <div class="sinister_body">
                <div class="sinister_position d-flex">
                    <div class="sinister_date d-flex">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5 4.3125C4.6925 4.3125 4.4375 4.0575 4.4375 3.75V1.5C4.4375 1.1925 4.6925 0.9375 5 0.9375C5.3075 0.9375 5.5625 1.1925 5.5625 1.5V3.75C5.5625 4.0575 5.3075 4.3125 5 4.3125Z"
                                fill="#483A9D" />
                            <path
                                d="M11 4.3125C10.6925 4.3125 10.4375 4.0575 10.4375 3.75V1.5C10.4375 1.1925 10.6925 0.9375 11 0.9375C11.3075 0.9375 11.5625 1.1925 11.5625 1.5V3.75C11.5625 4.0575 11.3075 4.3125 11 4.3125Z"
                                fill="#483A9D" />
                            <path
                                d="M14.375 7.37988H1.625C1.3175 7.37988 1.0625 7.12488 1.0625 6.81738C1.0625 6.50988 1.3175 6.25488 1.625 6.25488H14.375C14.6825 6.25488 14.9375 6.50988 14.9375 6.81738C14.9375 7.12488 14.6825 7.37988 14.375 7.37988Z"
                                fill="#483A9D" />
                            <path
                                d="M11 17.0625H5C2.2625 17.0625 0.6875 15.4875 0.6875 12.75V6.375C0.6875 3.6375 2.2625 2.0625 5 2.0625H11C13.7375 2.0625 15.3125 3.6375 15.3125 6.375V12.75C15.3125 15.4875 13.7375 17.0625 11 17.0625ZM5 3.1875C2.855 3.1875 1.8125 4.23 1.8125 6.375V12.75C1.8125 14.895 2.855 15.9375 5 15.9375H11C13.145 15.9375 14.1875 14.895 14.1875 12.75V6.375C14.1875 4.23 13.145 3.1875 11 3.1875H5Z"
                                fill="#483A9D" />
                            <path
                                d="M5.375 10.875C5.2775 10.875 5.18 10.8525 5.09 10.815C5 10.7775 4.91751 10.725 4.84251 10.6575C4.77501 10.5825 4.72249 10.5 4.68499 10.41C4.64749 10.32 4.625 10.2225 4.625 10.125C4.625 9.93001 4.70751 9.73502 4.84251 9.59252C4.91751 9.52502 5 9.4725 5.09 9.435C5.225 9.375 5.37501 9.36 5.52501 9.39C5.57001 9.3975 5.615 9.4125 5.66 9.435C5.705 9.45 5.75 9.47252 5.795 9.50252C5.8325 9.53252 5.86999 9.56252 5.90749 9.59252C5.93749 9.63002 5.97499 9.66751 5.99749 9.70501C6.02749 9.75001 6.05001 9.79501 6.06501 9.84001C6.08751 9.885 6.10251 9.93 6.11001 9.975C6.11751 10.0275 6.125 10.0725 6.125 10.125C6.125 10.32 6.04249 10.515 5.90749 10.6575C5.76499 10.7925 5.57 10.875 5.375 10.875Z"
                                fill="#483A9D" />
                            <path
                                d="M8 10.8751C7.805 10.8751 7.61001 10.7926 7.46751 10.6576C7.43751 10.6201 7.40751 10.5826 7.37751 10.5451C7.34751 10.5001 7.32499 10.4552 7.30999 10.4102C7.28749 10.3652 7.27249 10.3202 7.26499 10.2752C7.25749 10.2227 7.25 10.1776 7.25 10.1251C7.25 10.0276 7.27249 9.93015 7.30999 9.84015C7.34749 9.75015 7.40001 9.66766 7.46751 9.59266C7.67751 9.38266 8.015 9.31514 8.285 9.43514C8.3825 9.47264 8.45749 9.52516 8.53249 9.59266C8.66749 9.73516 8.75 9.93015 8.75 10.1251C8.75 10.1776 8.74251 10.2227 8.73501 10.2752C8.72751 10.3202 8.71251 10.3652 8.69001 10.4102C8.67501 10.4552 8.65249 10.5001 8.62249 10.5451C8.59249 10.5826 8.56249 10.6201 8.53249 10.6576C8.45749 10.7251 8.3825 10.7777 8.285 10.8152C8.195 10.8527 8.0975 10.8751 8 10.8751Z"
                                fill="#483A9D" />
                            <path
                                d="M5.375 13.5001C5.2775 13.5001 5.18 13.4777 5.09 13.4402C5 13.4027 4.91751 13.3501 4.84251 13.2826C4.77501 13.2076 4.72249 13.1327 4.68499 13.0352C4.64749 12.9452 4.625 12.8476 4.625 12.7501C4.625 12.5551 4.70751 12.3602 4.84251 12.2177C4.91751 12.1502 5 12.0976 5.09 12.0601C5.3675 11.9401 5.69749 12.0077 5.90749 12.2177C5.93749 12.2552 5.97499 12.2927 5.99749 12.3302C6.02749 12.3752 6.05001 12.4201 6.06501 12.4651C6.08751 12.5101 6.10251 12.5551 6.11001 12.6076C6.11751 12.6526 6.125 12.7051 6.125 12.7501C6.125 12.9451 6.04249 13.1401 5.90749 13.2826C5.76499 13.4176 5.57 13.5001 5.375 13.5001Z"
                                fill="#483A9D" />
                        </svg>
                        <span>
                            17/07/2022
                        </span>
                    </div>
                    <div class="sinister_placement d-flex">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.00016 10.6276C6.40266 10.6276 5.09766 9.33008 5.09766 7.72508C5.09766 6.12008 6.40266 4.83008 8.00016 4.83008C9.59766 4.83008 10.9027 6.12758 10.9027 7.73258C10.9027 9.33758 9.59766 10.6276 8.00016 10.6276ZM8.00016 5.95508C7.02516 5.95508 6.22266 6.75008 6.22266 7.73258C6.22266 8.71508 7.01766 9.51008 8.00016 9.51008C8.98266 9.51008 9.77766 8.71508 9.77766 7.73258C9.77766 6.75008 8.97516 5.95508 8.00016 5.95508Z"
                                fill="#483A9D" />
                            <path
                                d="M7.99928 17.07C6.88928 17.07 5.77178 16.65 4.90178 15.8175C2.68928 13.6875 0.244278 10.29 1.16678 6.2475C1.99928 2.58 5.20178 0.9375 7.99928 0.9375C7.99928 0.9375 7.99928 0.9375 8.00678 0.9375C10.8043 0.9375 14.0068 2.58 14.8393 6.255C15.7543 10.2975 13.3093 13.6875 11.0968 15.8175C10.2268 16.65 9.10928 17.07 7.99928 17.07ZM7.99928 2.0625C5.81678 2.0625 3.01178 3.225 2.26928 6.495C1.45928 10.0275 3.67928 13.0725 5.68928 15C6.98678 16.2525 9.01928 16.2525 10.3168 15C12.3193 13.0725 14.5393 10.0275 13.7443 6.495C12.9943 3.225 10.1818 2.0625 7.99928 2.0625Z"
                                fill="#483A9D" />
                        </svg>
                        <span>El riadh ,Sousse</span>
                    </div>
                </div>
                <div class="contract_num d-flex">
                    <span class="sub_num">Contract N° :</span>
                    <span class="num ml-2">113 687 681 00012</span>
                </div>
                <div class="sinister_documents">
                    <h4>Supporting documents: </h4>
                    <hooper :itemsToSlide="3" style="height: 45px ; width: 55%">
                        <slide>
                            <img src="https://img.freepik.com/free-photo/cracked-broken-glass-window_1385-1496.jpg?w=1060&t=st=1680917421~exp=1680918021~hmac=4c31a63ac1e06302fbc0c5eb3af05d948a22f44d7fa67d3d851abe94300daead"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/insurance-agent-working-during-site-car-accident-claim-process-people-car-insurance-claim_1150-18104.jpg?w=1060&t=st=1680917580~exp=1680918180~hmac=8c1246e52bfe0d429bdd62f5e7e186929373a0c3e720b21e1f448327f9bd61e3"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/car-damage-road-accident-car-insurance_1150-18101.jpg?w=1060&t=st=1680917629~exp=1680918229~hmac=d98c1ec2f36952f4bfd65982ad841eb58db2afefe1a29cf0905c42a066d794be"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/insurance-agent-working-during-site-car-accident-claim-process-people-car-insurance-claim_1150-18106.jpg?w=1060&t=st=1680917617~exp=1680918217~hmac=2647823e15b60bc9cd5f3f4464feaa6027555da25535b787a95b0656729931bf"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/cracked-broken-glass-window_1385-1496.jpg?w=1060&t=st=1680917421~exp=1680918021~hmac=4c31a63ac1e06302fbc0c5eb3af05d948a22f44d7fa67d3d851abe94300daead"
                                class="avatar">
                        </slide>
                        <hooper-navigation slot="hooper-addons">
                            <svg slot="hooper-prev" width="38" height="64" fill="none" viewBox="0 0 38 64">
                                <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".5"
                                    stroke-width="2" d="M35.41 1.11L1.568 31.049a1.346 1.346 0 000 2.012L35.409 63" />
                            </svg>
                            <svg slot="hooper-next" width="38" height="64" fill="none" viewBox="0 0 38 64">
                                <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".5"
                                    stroke-width="2" d="M1.988 62.89l33.84-29.939a1.346 1.346 0 000-2.012L1.988 1" />
                            </svg>
                        </hooper-navigation>
                    </hooper>
                </div>
            </div>
        </div>

        <div class="sinister_card mt-3">
            <div class="sinister_header d-flex">
                <h3>
                    <span class="sin_type">Type :</span> Breaking of glass
                </h3>
                <div class="type_badge pending">
                    Pending
                </div>
            </div>

            <div class="sinister_body">
                <div class="sinister_position d-flex">
                    <div class="sinister_date d-flex">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5 4.3125C4.6925 4.3125 4.4375 4.0575 4.4375 3.75V1.5C4.4375 1.1925 4.6925 0.9375 5 0.9375C5.3075 0.9375 5.5625 1.1925 5.5625 1.5V3.75C5.5625 4.0575 5.3075 4.3125 5 4.3125Z"
                                fill="#483A9D" />
                            <path
                                d="M11 4.3125C10.6925 4.3125 10.4375 4.0575 10.4375 3.75V1.5C10.4375 1.1925 10.6925 0.9375 11 0.9375C11.3075 0.9375 11.5625 1.1925 11.5625 1.5V3.75C11.5625 4.0575 11.3075 4.3125 11 4.3125Z"
                                fill="#483A9D" />
                            <path
                                d="M14.375 7.37988H1.625C1.3175 7.37988 1.0625 7.12488 1.0625 6.81738C1.0625 6.50988 1.3175 6.25488 1.625 6.25488H14.375C14.6825 6.25488 14.9375 6.50988 14.9375 6.81738C14.9375 7.12488 14.6825 7.37988 14.375 7.37988Z"
                                fill="#483A9D" />
                            <path
                                d="M11 17.0625H5C2.2625 17.0625 0.6875 15.4875 0.6875 12.75V6.375C0.6875 3.6375 2.2625 2.0625 5 2.0625H11C13.7375 2.0625 15.3125 3.6375 15.3125 6.375V12.75C15.3125 15.4875 13.7375 17.0625 11 17.0625ZM5 3.1875C2.855 3.1875 1.8125 4.23 1.8125 6.375V12.75C1.8125 14.895 2.855 15.9375 5 15.9375H11C13.145 15.9375 14.1875 14.895 14.1875 12.75V6.375C14.1875 4.23 13.145 3.1875 11 3.1875H5Z"
                                fill="#483A9D" />
                            <path
                                d="M5.375 10.875C5.2775 10.875 5.18 10.8525 5.09 10.815C5 10.7775 4.91751 10.725 4.84251 10.6575C4.77501 10.5825 4.72249 10.5 4.68499 10.41C4.64749 10.32 4.625 10.2225 4.625 10.125C4.625 9.93001 4.70751 9.73502 4.84251 9.59252C4.91751 9.52502 5 9.4725 5.09 9.435C5.225 9.375 5.37501 9.36 5.52501 9.39C5.57001 9.3975 5.615 9.4125 5.66 9.435C5.705 9.45 5.75 9.47252 5.795 9.50252C5.8325 9.53252 5.86999 9.56252 5.90749 9.59252C5.93749 9.63002 5.97499 9.66751 5.99749 9.70501C6.02749 9.75001 6.05001 9.79501 6.06501 9.84001C6.08751 9.885 6.10251 9.93 6.11001 9.975C6.11751 10.0275 6.125 10.0725 6.125 10.125C6.125 10.32 6.04249 10.515 5.90749 10.6575C5.76499 10.7925 5.57 10.875 5.375 10.875Z"
                                fill="#483A9D" />
                            <path
                                d="M8 10.8751C7.805 10.8751 7.61001 10.7926 7.46751 10.6576C7.43751 10.6201 7.40751 10.5826 7.37751 10.5451C7.34751 10.5001 7.32499 10.4552 7.30999 10.4102C7.28749 10.3652 7.27249 10.3202 7.26499 10.2752C7.25749 10.2227 7.25 10.1776 7.25 10.1251C7.25 10.0276 7.27249 9.93015 7.30999 9.84015C7.34749 9.75015 7.40001 9.66766 7.46751 9.59266C7.67751 9.38266 8.015 9.31514 8.285 9.43514C8.3825 9.47264 8.45749 9.52516 8.53249 9.59266C8.66749 9.73516 8.75 9.93015 8.75 10.1251C8.75 10.1776 8.74251 10.2227 8.73501 10.2752C8.72751 10.3202 8.71251 10.3652 8.69001 10.4102C8.67501 10.4552 8.65249 10.5001 8.62249 10.5451C8.59249 10.5826 8.56249 10.6201 8.53249 10.6576C8.45749 10.7251 8.3825 10.7777 8.285 10.8152C8.195 10.8527 8.0975 10.8751 8 10.8751Z"
                                fill="#483A9D" />
                            <path
                                d="M5.375 13.5001C5.2775 13.5001 5.18 13.4777 5.09 13.4402C5 13.4027 4.91751 13.3501 4.84251 13.2826C4.77501 13.2076 4.72249 13.1327 4.68499 13.0352C4.64749 12.9452 4.625 12.8476 4.625 12.7501C4.625 12.5551 4.70751 12.3602 4.84251 12.2177C4.91751 12.1502 5 12.0976 5.09 12.0601C5.3675 11.9401 5.69749 12.0077 5.90749 12.2177C5.93749 12.2552 5.97499 12.2927 5.99749 12.3302C6.02749 12.3752 6.05001 12.4201 6.06501 12.4651C6.08751 12.5101 6.10251 12.5551 6.11001 12.6076C6.11751 12.6526 6.125 12.7051 6.125 12.7501C6.125 12.9451 6.04249 13.1401 5.90749 13.2826C5.76499 13.4176 5.57 13.5001 5.375 13.5001Z"
                                fill="#483A9D" />
                        </svg>
                        <span>
                            17/07/2022
                        </span>
                    </div>
                    <div class="sinister_placement d-flex">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.00016 10.6276C6.40266 10.6276 5.09766 9.33008 5.09766 7.72508C5.09766 6.12008 6.40266 4.83008 8.00016 4.83008C9.59766 4.83008 10.9027 6.12758 10.9027 7.73258C10.9027 9.33758 9.59766 10.6276 8.00016 10.6276ZM8.00016 5.95508C7.02516 5.95508 6.22266 6.75008 6.22266 7.73258C6.22266 8.71508 7.01766 9.51008 8.00016 9.51008C8.98266 9.51008 9.77766 8.71508 9.77766 7.73258C9.77766 6.75008 8.97516 5.95508 8.00016 5.95508Z"
                                fill="#483A9D" />
                            <path
                                d="M7.99928 17.07C6.88928 17.07 5.77178 16.65 4.90178 15.8175C2.68928 13.6875 0.244278 10.29 1.16678 6.2475C1.99928 2.58 5.20178 0.9375 7.99928 0.9375C7.99928 0.9375 7.99928 0.9375 8.00678 0.9375C10.8043 0.9375 14.0068 2.58 14.8393 6.255C15.7543 10.2975 13.3093 13.6875 11.0968 15.8175C10.2268 16.65 9.10928 17.07 7.99928 17.07ZM7.99928 2.0625C5.81678 2.0625 3.01178 3.225 2.26928 6.495C1.45928 10.0275 3.67928 13.0725 5.68928 15C6.98678 16.2525 9.01928 16.2525 10.3168 15C12.3193 13.0725 14.5393 10.0275 13.7443 6.495C12.9943 3.225 10.1818 2.0625 7.99928 2.0625Z"
                                fill="#483A9D" />
                        </svg>
                        <span>El riadh ,Sousse</span>
                    </div>
                </div>
                <div class="contract_num d-flex">
                    <span class="sub_num">Contract N° :</span>
                    <span class="num ml-2">113 687 681 00012</span>
                </div>
                <div class="sinister_documents">
                    <h4>Supporting documents: </h4>
                    <hooper :itemsToSlide="3" style="height: 45px ; width: 55%">
                        <slide>
                            <img src="https://img.freepik.com/free-photo/cracked-broken-glass-window_1385-1496.jpg?w=1060&t=st=1680917421~exp=1680918021~hmac=4c31a63ac1e06302fbc0c5eb3af05d948a22f44d7fa67d3d851abe94300daead"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/insurance-agent-working-during-site-car-accident-claim-process-people-car-insurance-claim_1150-18104.jpg?w=1060&t=st=1680917580~exp=1680918180~hmac=8c1246e52bfe0d429bdd62f5e7e186929373a0c3e720b21e1f448327f9bd61e3"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/car-damage-road-accident-car-insurance_1150-18101.jpg?w=1060&t=st=1680917629~exp=1680918229~hmac=d98c1ec2f36952f4bfd65982ad841eb58db2afefe1a29cf0905c42a066d794be"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/insurance-agent-working-during-site-car-accident-claim-process-people-car-insurance-claim_1150-18106.jpg?w=1060&t=st=1680917617~exp=1680918217~hmac=2647823e15b60bc9cd5f3f4464feaa6027555da25535b787a95b0656729931bf"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/cracked-broken-glass-window_1385-1496.jpg?w=1060&t=st=1680917421~exp=1680918021~hmac=4c31a63ac1e06302fbc0c5eb3af05d948a22f44d7fa67d3d851abe94300daead"
                                class="avatar">
                        </slide>
                        <hooper-navigation slot="hooper-addons">
                            <svg slot="hooper-prev" width="38" height="64" fill="none" viewBox="0 0 38 64">
                                <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".5"
                                    stroke-width="2" d="M35.41 1.11L1.568 31.049a1.346 1.346 0 000 2.012L35.409 63" />
                            </svg>
                            <svg slot="hooper-next" width="38" height="64" fill="none" viewBox="0 0 38 64">
                                <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".5"
                                    stroke-width="2" d="M1.988 62.89l33.84-29.939a1.346 1.346 0 000-2.012L1.988 1" />
                            </svg>
                        </hooper-navigation>
                    </hooper>
                </div>
            </div>
        </div>

        <div class="sinister_card mt-3 mb-2">
            <div class="sinister_header d-flex">
                <h3>
                    <span class="sin_type">Type :</span> Breaking of glass
                </h3>
                <div class="type_badge pending">
                    Pending
                </div>
            </div>

            <div class="sinister_body">
                <div class="sinister_position d-flex">
                    <div class="sinister_date d-flex">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5 4.3125C4.6925 4.3125 4.4375 4.0575 4.4375 3.75V1.5C4.4375 1.1925 4.6925 0.9375 5 0.9375C5.3075 0.9375 5.5625 1.1925 5.5625 1.5V3.75C5.5625 4.0575 5.3075 4.3125 5 4.3125Z"
                                fill="#483A9D" />
                            <path
                                d="M11 4.3125C10.6925 4.3125 10.4375 4.0575 10.4375 3.75V1.5C10.4375 1.1925 10.6925 0.9375 11 0.9375C11.3075 0.9375 11.5625 1.1925 11.5625 1.5V3.75C11.5625 4.0575 11.3075 4.3125 11 4.3125Z"
                                fill="#483A9D" />
                            <path
                                d="M14.375 7.37988H1.625C1.3175 7.37988 1.0625 7.12488 1.0625 6.81738C1.0625 6.50988 1.3175 6.25488 1.625 6.25488H14.375C14.6825 6.25488 14.9375 6.50988 14.9375 6.81738C14.9375 7.12488 14.6825 7.37988 14.375 7.37988Z"
                                fill="#483A9D" />
                            <path
                                d="M11 17.0625H5C2.2625 17.0625 0.6875 15.4875 0.6875 12.75V6.375C0.6875 3.6375 2.2625 2.0625 5 2.0625H11C13.7375 2.0625 15.3125 3.6375 15.3125 6.375V12.75C15.3125 15.4875 13.7375 17.0625 11 17.0625ZM5 3.1875C2.855 3.1875 1.8125 4.23 1.8125 6.375V12.75C1.8125 14.895 2.855 15.9375 5 15.9375H11C13.145 15.9375 14.1875 14.895 14.1875 12.75V6.375C14.1875 4.23 13.145 3.1875 11 3.1875H5Z"
                                fill="#483A9D" />
                            <path
                                d="M5.375 10.875C5.2775 10.875 5.18 10.8525 5.09 10.815C5 10.7775 4.91751 10.725 4.84251 10.6575C4.77501 10.5825 4.72249 10.5 4.68499 10.41C4.64749 10.32 4.625 10.2225 4.625 10.125C4.625 9.93001 4.70751 9.73502 4.84251 9.59252C4.91751 9.52502 5 9.4725 5.09 9.435C5.225 9.375 5.37501 9.36 5.52501 9.39C5.57001 9.3975 5.615 9.4125 5.66 9.435C5.705 9.45 5.75 9.47252 5.795 9.50252C5.8325 9.53252 5.86999 9.56252 5.90749 9.59252C5.93749 9.63002 5.97499 9.66751 5.99749 9.70501C6.02749 9.75001 6.05001 9.79501 6.06501 9.84001C6.08751 9.885 6.10251 9.93 6.11001 9.975C6.11751 10.0275 6.125 10.0725 6.125 10.125C6.125 10.32 6.04249 10.515 5.90749 10.6575C5.76499 10.7925 5.57 10.875 5.375 10.875Z"
                                fill="#483A9D" />
                            <path
                                d="M8 10.8751C7.805 10.8751 7.61001 10.7926 7.46751 10.6576C7.43751 10.6201 7.40751 10.5826 7.37751 10.5451C7.34751 10.5001 7.32499 10.4552 7.30999 10.4102C7.28749 10.3652 7.27249 10.3202 7.26499 10.2752C7.25749 10.2227 7.25 10.1776 7.25 10.1251C7.25 10.0276 7.27249 9.93015 7.30999 9.84015C7.34749 9.75015 7.40001 9.66766 7.46751 9.59266C7.67751 9.38266 8.015 9.31514 8.285 9.43514C8.3825 9.47264 8.45749 9.52516 8.53249 9.59266C8.66749 9.73516 8.75 9.93015 8.75 10.1251C8.75 10.1776 8.74251 10.2227 8.73501 10.2752C8.72751 10.3202 8.71251 10.3652 8.69001 10.4102C8.67501 10.4552 8.65249 10.5001 8.62249 10.5451C8.59249 10.5826 8.56249 10.6201 8.53249 10.6576C8.45749 10.7251 8.3825 10.7777 8.285 10.8152C8.195 10.8527 8.0975 10.8751 8 10.8751Z"
                                fill="#483A9D" />
                            <path
                                d="M5.375 13.5001C5.2775 13.5001 5.18 13.4777 5.09 13.4402C5 13.4027 4.91751 13.3501 4.84251 13.2826C4.77501 13.2076 4.72249 13.1327 4.68499 13.0352C4.64749 12.9452 4.625 12.8476 4.625 12.7501C4.625 12.5551 4.70751 12.3602 4.84251 12.2177C4.91751 12.1502 5 12.0976 5.09 12.0601C5.3675 11.9401 5.69749 12.0077 5.90749 12.2177C5.93749 12.2552 5.97499 12.2927 5.99749 12.3302C6.02749 12.3752 6.05001 12.4201 6.06501 12.4651C6.08751 12.5101 6.10251 12.5551 6.11001 12.6076C6.11751 12.6526 6.125 12.7051 6.125 12.7501C6.125 12.9451 6.04249 13.1401 5.90749 13.2826C5.76499 13.4176 5.57 13.5001 5.375 13.5001Z"
                                fill="#483A9D" />
                        </svg>
                        <span>
                            17/07/2022
                        </span>
                    </div>
                    <div class="sinister_placement d-flex">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.00016 10.6276C6.40266 10.6276 5.09766 9.33008 5.09766 7.72508C5.09766 6.12008 6.40266 4.83008 8.00016 4.83008C9.59766 4.83008 10.9027 6.12758 10.9027 7.73258C10.9027 9.33758 9.59766 10.6276 8.00016 10.6276ZM8.00016 5.95508C7.02516 5.95508 6.22266 6.75008 6.22266 7.73258C6.22266 8.71508 7.01766 9.51008 8.00016 9.51008C8.98266 9.51008 9.77766 8.71508 9.77766 7.73258C9.77766 6.75008 8.97516 5.95508 8.00016 5.95508Z"
                                fill="#483A9D" />
                            <path
                                d="M7.99928 17.07C6.88928 17.07 5.77178 16.65 4.90178 15.8175C2.68928 13.6875 0.244278 10.29 1.16678 6.2475C1.99928 2.58 5.20178 0.9375 7.99928 0.9375C7.99928 0.9375 7.99928 0.9375 8.00678 0.9375C10.8043 0.9375 14.0068 2.58 14.8393 6.255C15.7543 10.2975 13.3093 13.6875 11.0968 15.8175C10.2268 16.65 9.10928 17.07 7.99928 17.07ZM7.99928 2.0625C5.81678 2.0625 3.01178 3.225 2.26928 6.495C1.45928 10.0275 3.67928 13.0725 5.68928 15C6.98678 16.2525 9.01928 16.2525 10.3168 15C12.3193 13.0725 14.5393 10.0275 13.7443 6.495C12.9943 3.225 10.1818 2.0625 7.99928 2.0625Z"
                                fill="#483A9D" />
                        </svg>
                        <span>El riadh ,Sousse</span>
                    </div>
                </div>
                <div class="contract_num d-flex">
                    <span class="sub_num">Contract N° :</span>
                    <span class="num ml-2">113 687 681 00012</span>
                </div>
                <div class="sinister_documents">
                    <h4>Supporting documents: </h4>
                    <hooper :itemsToSlide="3" style="height: 45px ; width: 55%">
                        <slide>
                            <img src="https://img.freepik.com/free-photo/cracked-broken-glass-window_1385-1496.jpg?w=1060&t=st=1680917421~exp=1680918021~hmac=4c31a63ac1e06302fbc0c5eb3af05d948a22f44d7fa67d3d851abe94300daead"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/insurance-agent-working-during-site-car-accident-claim-process-people-car-insurance-claim_1150-18104.jpg?w=1060&t=st=1680917580~exp=1680918180~hmac=8c1246e52bfe0d429bdd62f5e7e186929373a0c3e720b21e1f448327f9bd61e3"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/car-damage-road-accident-car-insurance_1150-18101.jpg?w=1060&t=st=1680917629~exp=1680918229~hmac=d98c1ec2f36952f4bfd65982ad841eb58db2afefe1a29cf0905c42a066d794be"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/insurance-agent-working-during-site-car-accident-claim-process-people-car-insurance-claim_1150-18106.jpg?w=1060&t=st=1680917617~exp=1680918217~hmac=2647823e15b60bc9cd5f3f4464feaa6027555da25535b787a95b0656729931bf"
                                class="avatar">
                        </slide>
                        <slide>
                            <img src="https://img.freepik.com/free-photo/cracked-broken-glass-window_1385-1496.jpg?w=1060&t=st=1680917421~exp=1680918021~hmac=4c31a63ac1e06302fbc0c5eb3af05d948a22f44d7fa67d3d851abe94300daead"
                                class="avatar">
                        </slide>
                        <hooper-navigation slot="hooper-addons">
                            <svg slot="hooper-prev" width="38" height="64" fill="none" viewBox="0 0 38 64">
                                <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".5"
                                    stroke-width="2" d="M35.41 1.11L1.568 31.049a1.346 1.346 0 000 2.012L35.409 63" />
                            </svg>
                            <svg slot="hooper-next" width="38" height="64" fill="none" viewBox="0 0 38 64">
                                <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".5"
                                    stroke-width="2" d="M1.988 62.89l33.84-29.939a1.346 1.346 0 000-2.012L1.988 1" />
                            </svg>
                        </hooper-navigation>
                    </hooper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
    name: 'Sinisters',
    components: {
        Hooper,
        Slide,
    },
};
</script>

<style scoped lang="scss">
.hooper-slide {
    margin-right: 13px !important;
    background: #FFFFFF !important;
    border-radius: 8px !important;
    width: fit-content !important;
}

.avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 10px;
}

.hooper-navigation {
    .hooper-slide {
        .is-disabled {
            display: none !important;
        }
    }
}

.sinister {
    padding: 8px;

    .sinister_btn {
        cursor: pointer;
        background: #483a9d;
        border-radius: 8px;
        height: 45px;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        text-align: center;
        width: 100%;
        margin-bottom: 12px;
        color: white;
    }

    .sinister_card {
        background: #FFFFFF;
        border: 0.5px solid #A49DCF;
        border-radius: 8px;
        padding: 12px;

        .sinister_header {
            justify-content: space-between;
            margin-bottom: 12px;

            h3 {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                color: #656877;
                margin-top: 7px !important;
            }

            .sin_type {
                color: #49CF48 !important;
            }

            .type_badge {
                padding: 6px 10px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
            }


            .accepted {
                color: #49CF48 !important;
                background: #F4FBF5 !important;
                border: 1px solid #49CF48 !important;
                border-radius: 5px !important;
            }

            .pending {
                color: #F8AB26 !important;
                background: #FFFBF4 !important;
                border: 1px solid #F8AB26 !important;
                border-radius: 5px !important;
            }
        }

        .sinister_body {
            .sinister_position {
                justify-content: space-between;
                margin-top: 12px;
                margin-bottom: 10px;

                .sinister_date {

                    span {
                        margin-left: 5px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 14px;
                        color: #656877;
                        margin-top: 4px;
                    }

                    svg {
                        opacity: .8;
                        margin-right: 2px;
                    }
                }

                .sinister_placement {
                    span {
                        margin-left: 5px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 14px;
                        color: #656877;
                        margin-top: 4px;
                    }

                    svg {
                        opacity: .8;
                        margin-right: 2px;
                    }
                }
            }

            .contract_num {
                margin-bottom: 8px;
                margin-top: 12px;

                .sub_num {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 15px;
                    color: #483A9D;
                }

                .num {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 15px;
                    letter-spacing: .1em;
                    color: #656877;
                }
            }

            .sinister_documents {
                margin-top: 15px;

                h4 {
                    font-style: normal !important;
                    font-weight: 500 !important;
                    font-size: 13px !important;
                    line-height: 13px !important;
                    color: #FA3559 !important;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
</style>