<template>
  <div class="shopping_info mb-3">
    <div class="d-flex basket_block">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 13.875C7.245 13.875 5.8125 12.4425 5.8125 10.6875C5.8125 10.38 6.0675 10.125 6.375 10.125C6.6825 10.125 6.9375 10.38 6.9375 10.6875C6.9375 11.8275 7.86 12.75 9 12.75C10.14 12.75 11.0625 11.8275 11.0625 10.6875C11.0625 10.38 11.3175 10.125 11.625 10.125C11.9325 10.125 12.1875 10.38 12.1875 10.6875C12.1875 12.4425 10.755 13.875 9 13.875Z"
          fill="#49CF48"
        />
        <path
          d="M3.89241 4.78508C3.74991 4.78508 3.59991 4.72508 3.49491 4.62008C3.27741 4.40258 3.27741 4.04258 3.49491 3.82508L6.21741 1.10258C6.43491 0.885078 6.79491 0.885078 7.01241 1.10258C7.22991 1.32008 7.22991 1.68008 7.01241 1.89758L4.28991 4.62008C4.17741 4.72508 4.03491 4.78508 3.89241 4.78508Z"
          fill="#49CF48"
        />
        <path
          d="M14.1076 4.78508C13.9651 4.78508 13.8226 4.73258 13.7101 4.62008L10.9876 1.89758C10.7701 1.68008 10.7701 1.32008 10.9876 1.10258C11.2051 0.885078 11.5651 0.885078 11.7826 1.10258L14.5051 3.82508C14.7226 4.04258 14.7226 4.40258 14.5051 4.62008C14.4001 4.72508 14.2501 4.78508 14.1076 4.78508Z"
          fill="#49CF48"
        />
        <path
          d="M15.1575 7.9502C15.105 7.9502 15.0525 7.9502 15 7.9502H14.8275H3C2.475 7.9577 1.875 7.9577 1.44 7.5227C1.095 7.1852 0.9375 6.6602 0.9375 5.8877C0.9375 3.8252 2.445 3.8252 3.165 3.8252H14.835C15.555 3.8252 17.0625 3.8252 17.0625 5.8877C17.0625 6.6677 16.905 7.1852 16.56 7.5227C16.17 7.91269 15.645 7.9502 15.1575 7.9502ZM3.165 6.8252H15.0075C15.345 6.8327 15.66 6.8327 15.765 6.7277C15.8175 6.6752 15.93 6.4952 15.93 5.8877C15.93 5.0402 15.72 4.9502 14.8275 4.9502H3.165C2.2725 4.9502 2.0625 5.0402 2.0625 5.8877C2.0625 6.4952 2.1825 6.6752 2.2275 6.7277C2.3325 6.8252 2.655 6.8252 2.985 6.8252H3.165Z"
          fill="#49CF48"
        />
        <path
          d="M11.1676 17.0626H6.64506C3.96006 17.0626 3.36006 15.4651 3.12756 14.0776L2.07006 7.59006C2.01756 7.28256 2.22756 6.99756 2.53506 6.94506C2.83506 6.89256 3.12756 7.10256 3.18006 7.41006L4.23756 13.8901C4.45506 15.2176 4.90506 15.9376 6.64506 15.9376H11.1676C13.0951 15.9376 13.3126 15.2626 13.5601 13.9576L14.8201 7.39506C14.8801 7.08756 15.1726 6.88506 15.4801 6.95256C15.7876 7.01256 15.9826 7.30506 15.9226 7.61256L14.6626 14.1751C14.3701 15.6976 13.8826 17.0626 11.1676 17.0626Z"
          fill="#49CF48"
        />
      </svg>
      <span>{{ allOrders }}</span>
    </div>
    <svg
      class="mt--4"
      width="1"
      height="27"
      viewBox="0 0 1 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.5"
        y1="0.5"
        x2="0.499999"
        y2="26.1667"
        stroke="#A8A8A8"
        stroke-linecap="round"
      />
    </svg>
    <div class="d-flex dislike_block">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.39 4.2373L10.065 2.4373C9.76504 2.1373 9.09004 1.9873 8.64004 1.9873H5.79004C4.89004 1.9873 3.91504 2.6623 3.69004 3.5623L1.89004 9.0373C1.51504 10.0873 2.19004 10.9873 3.31504 10.9873H6.31504C6.76504 10.9873 7.14004 11.3623 7.06504 11.8873L6.69004 14.2873C6.54004 14.9623 6.99004 15.7123 7.66504 15.9373C8.26504 16.1623 9.01504 15.8623 9.31504 15.4123L12.39 10.8373"
          stroke="#FF2C52"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M16.2151 4.2375V11.5875C16.2151 12.6375 15.7651 13.0125 14.7151 13.0125H13.9651C12.9151 13.0125 12.4651 12.6375 12.4651 11.5875V4.2375C12.4651 3.1875 12.9151 2.8125 13.9651 2.8125H14.7151C15.7651 2.8125 16.2151 3.1875 16.2151 4.2375Z"
          stroke="#FF2C52"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>{{ canceledOrders }}</span>
    </div>
    <svg
      class="mt--4"
      width="1"
      height="27"
      viewBox="0 0 1 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.5"
        y1="0.5"
        x2="0.499999"
        y2="26.1667"
        stroke="#A8A8A8"
        stroke-linecap="round"
      />
    </svg>
    <div class="d-flex cash_block">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.37735 17.0627C3.59235 17.0627 2.12988 15.7652 2.12988 14.1677V12.6377C2.12988 12.3302 2.38488 12.0752 2.69238 12.0752C2.99988 12.0752 3.25488 12.3302 3.25488 12.6377C3.25488 13.5752 4.16235 14.2802 5.37735 14.2802C6.59235 14.2802 7.49986 13.5752 7.49986 12.6377C7.49986 12.3302 7.75486 12.0752 8.06236 12.0752C8.36986 12.0752 8.62486 12.3302 8.62486 12.6377V14.1677C8.62486 15.7652 7.16985 17.0627 5.37735 17.0627ZM3.44984 14.9027C3.77984 15.5177 4.52235 15.9377 5.37735 15.9377C6.23235 15.9377 6.97486 15.5102 7.30486 14.9027C6.77236 15.2252 6.11235 15.4127 5.37735 15.4127C4.64235 15.4127 3.98234 15.2252 3.44984 14.9027Z"
          fill="#292D32"
        />
        <path
          d="M5.37735 13.3501C4.14735 13.3501 3.04488 12.7876 2.49738 11.8951C2.25738 11.5051 2.12988 11.0476 2.12988 10.5826C2.12988 9.79507 2.47487 9.06006 3.10487 8.51256C4.31987 7.44756 6.41236 7.4476 7.63486 8.5051C8.26486 9.0601 8.61736 9.79507 8.61736 10.5826C8.61736 11.0476 8.48986 11.5051 8.24986 11.8951C7.70986 12.7876 6.60735 13.3501 5.37735 13.3501ZM5.37735 8.81258C4.79235 8.81258 4.25237 9.00756 3.84737 9.36006C3.46487 9.69006 3.25488 10.1251 3.25488 10.5826C3.25488 10.8451 3.32235 11.0851 3.45735 11.3101C3.80235 11.8801 4.53735 12.2326 5.37735 12.2326C6.21735 12.2326 6.95234 11.8801 7.28984 11.3176C7.42484 11.1001 7.49236 10.8526 7.49236 10.5901C7.49236 10.1326 7.28237 9.69756 6.89987 9.36006C6.50237 9.00756 5.96235 8.81258 5.37735 8.81258Z"
          fill="#292D32"
        />
        <path
          d="M5.37735 15.4125C3.52485 15.4125 2.12988 14.22 2.12988 12.645V10.5825C2.12988 8.98499 3.58485 7.6875 5.37735 7.6875C6.22485 7.6875 7.03486 7.98002 7.64236 8.50502C8.27236 9.06002 8.62486 9.79499 8.62486 10.5825V12.645C8.62486 14.22 7.22985 15.4125 5.37735 15.4125ZM5.37735 8.8125C4.20735 8.8125 3.25488 9.60749 3.25488 10.5825V12.645C3.25488 13.5825 4.16235 14.2875 5.37735 14.2875C6.59235 14.2875 7.49986 13.5825 7.49986 12.645V10.5825C7.49986 10.125 7.28988 9.68998 6.90738 9.35248C6.50238 9.00748 5.96235 8.8125 5.37735 8.8125Z"
          fill="#292D32"
        />
        <path
          d="M14.2799 11.0999C13.1474 11.0999 12.1874 10.2599 12.0974 9.17989C12.0374 8.55739 12.2624 7.9499 12.7124 7.5074C13.0874 7.1174 13.6199 6.8999 14.1824 6.8999H15.7499C16.4924 6.9224 17.0624 7.50737 17.0624 8.22737V9.77242C17.0624 10.4924 16.4925 11.0774 15.7725 11.0999H14.2799ZM15.7274 8.0249H14.1899C13.9274 8.0249 13.6874 8.1224 13.5149 8.3024C13.2974 8.5124 13.1924 8.79738 13.2224 9.08238C13.2599 9.57738 13.7399 9.97488 14.2799 9.97488H15.7499C15.8474 9.97488 15.9374 9.88492 15.9374 9.77242V8.22737C15.9374 8.11487 15.8474 8.0324 15.7274 8.0249Z"
          fill="#292D32"
        />
        <path
          d="M12.0002 15.9375H10.1252C9.81768 15.9375 9.56268 15.6825 9.56268 15.375C9.56268 15.0675 9.81768 14.8125 10.1252 14.8125H12.0002C13.9352 14.8125 15.1877 13.56 15.1877 11.625V11.1H14.2802C13.1477 11.1 12.1877 10.26 12.0977 9.17999C12.0377 8.55749 12.2627 7.95001 12.7127 7.50751C13.0877 7.11751 13.6202 6.90001 14.1827 6.90001H15.1802V6.375C15.1802 4.62 14.1527 3.41248 12.4877 3.21748C12.3077 3.18748 12.1502 3.1875 11.9927 3.1875H5.24268C5.06268 3.1875 4.89017 3.20249 4.71767 3.22499C3.06767 3.43499 2.05518 4.635 2.05518 6.375V7.875C2.05518 8.1825 1.80018 8.4375 1.49268 8.4375C1.18518 8.4375 0.930176 8.1825 0.930176 7.875V6.375C0.930176 4.065 2.3552 2.39251 4.5677 2.11501C4.7702 2.08501 5.00268 2.0625 5.24268 2.0625H11.9927C12.1727 2.0625 12.4052 2.07 12.6452 2.1075C14.8577 2.3625 16.3052 4.0425 16.3052 6.375V7.46251C16.3052 7.77001 16.0502 8.02501 15.7427 8.02501H14.1827C13.9202 8.02501 13.6802 8.12251 13.5077 8.30251C13.2902 8.51251 13.1852 8.79749 13.2152 9.08249C13.2527 9.57749 13.7327 9.97499 14.2727 9.97499H15.7502C16.0577 9.97499 16.3127 10.23 16.3127 10.5375V11.625C16.3127 14.205 14.5802 15.9375 12.0002 15.9375Z"
          fill="#292D32"
        />
      </svg>
      <span>{{ this.amountAVG.toFixed(2) }}$</span>
    </div>
    <svg
      class="mt--4"
      width="1"
      height="27"
      viewBox="0 0 1 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.5"
        y1="0.5"
        x2="0.499999"
        y2="26.1667"
        stroke="#A8A8A8"
        stroke-linecap="round"
      />
    </svg>
    <div class="d-flex vip_block">
      <span>Customer</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ShoppingInformations',
  props: {
    customerEmail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listItems: [],
      allOrders: Number,
      completedOrders: Number,
      canceledOrders: Number,
      totalAmount: Number,
      amountAVG: Number,
    };
  },
  methods: {
    async getShoppingData() {
      this.allOrders = 0;
      this.completedOrders = 0;
      this.canceledOrders = 0;
      this.totalAmount = 0;
      let custEmail = this.customerEmail;
      await axios
        .get(
          `https://convergence-app-logic.k8s.satoripop.io/order/getorders?email=${custEmail}`,
          {
            params: this.axiosParams,
          }
        )
        .then(response => {
          this.listItems = response.data;
          for (let i = 0; i < this.listItems.length; i++) {
            this.allOrders++;
            this.totalAmount = this.totalAmount + this.listItems[i].total;
            if (this.listItems[i].status === 'canceled') {
              this.canceledOrders++;
            }
          }
          this.amountAVG = this.totalAmount.toFixed(2) / this.allOrders;
        });
    },
  },
  computed: {
    axiosParams() {
      const params = new URLSearchParams();
      params.append('currentpage', '0');
      params.append('pagesize', '20');
      return params;
    },
  },
  mounted() {
    this.getShoppingData();
  },
};
</script>

<style scoped lang="scss">
.shopping_info {
  background: #fff;
  border-radius: 8px;
  width: auto;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
  margin: 0 10px;
  .basket_block {
    justify-content: space-evenly;
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #49cf48;
      margin-left: 5px;
      margin-top: 3.4px;
    }
  }

  .dislike_block {
    justify-content: space-evenly;
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #ff2c52;
      margin-left: 5px;
      margin-top: 3.4px;
    }
  }

  .cash_block {
    justify-content: space-evenly;
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-left: 5px;
      margin-top: 3.4px;
    }
  }

  .vip_block {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    margin-top: 3px;
  }

  .mt--4 {
    margin-top: -4px;
  }
}
</style>
