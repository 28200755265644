<template>
  <header class="header shadow_custom">
    <div class="settings-header d-flex justify-space-between">
      <div class="d-flex">
        <woot-sidemenu-icon style="margin-top: 15px;" />
      <div class="breadcrumbs ml-2 p-2">
        <h2 class="display-10">{{ headerTitle }} Management</h2>
        <div aria-label="Breadcrumb" class="breadcrumb-fl">
          <ul>
            <li><span class="font-inter display-ul" ref="#">Settings</span></li>
            <li><span class="font-inter display-ul" ref="#">{{ headerTitle }} Management</span></li>
            <li><span class="bread_active display-ul font-inter" ref="#">Details</span></li>
          </ul>
        </div>
      </div>
      </div>


      <div class="notif_block">

        <agent-details @toggle-menu="toggleOptions" />
        <options-menu :show="showOptionsMenu" @toggle-accounts="toggleAccountModal"
          @show-support-chat-window="toggleSupportChatWindow" @key-shortcut-modal="$emit('key-shortcut-modal')"
          @close="toggleOptions" />

      </div>

    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingsHeader from "../../settings/SettingsHeader";
import OptionsMenu from "../../../../components/layout/sidebarComponents/OptionsMenu";
import AgentDetails from "../../../../components/layout/sidebarComponents/AgentDetails";
import NotificationBell from "../../../../components/layout/sidebarComponents/NotificationBell";

export default {
  components: {
    NotificationBell, SettingsHeader,
    OptionsMenu,
    AgentDetails
  },
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    searchQuery: {
      type: String,
      default: '',
    },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
    onInputSearch: {
      type: Function,
      default: () => { },
    },
    onSearchSubmit: {
      type: Function,
      default: () => { },
    },
    onToggleCreate: {
      type: Function,
      default: () => { },
    },
    onToggleImport: {
      type: Function,
      default: () => { },
    },
    onToggleFilter: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      showCreateModal: false,
      showImportModal: false,
      showOptionsMenu: false,
    };
  },
  computed: {
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    ...mapGetters({
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
    }),
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    hasActiveSegments() {
      return this.segmentsId !== 0;
    },
  },
  methods: {
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },
    onToggleSegmentsModal() {
      this.$emit('on-toggle-save-filter');
    },
    onToggleDeleteSegmentsModal() {
      this.$emit('on-toggle-delete-filter');
    },
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$convergence.toggle();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  margin: 0;
}

.table-actions-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--space-small) var(--space-normal) var(--space-small) var(--space-normal);
}

.left-aligned-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  min-width: var(--space-mega);

  .header-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 var(--space-small);
  }
}

.right-aligned-wrap {
  display: flex;
}

.search-wrap {
  max-width: 400px;
  min-width: 150px;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: var(--space-small);
  margin-left: var(--space-small);

  .search-icon {
    position: absolute;
    top: 1px;
    left: var(--space-one);
    height: 3.8rem;
    line-height: 3.6rem;
    font-size: var(--font-size-medium);
    color: var(--b-700);
  }

  .contact-search {
    margin: 0;
    height: 3.8rem;
    width: 100%;
    padding-left: var(--space-large);
    padding-right: 6rem;
    border-color: var(--s-100);
  }

  .button {
    margin-left: var(--space-small);
    height: 3.2rem;
    right: var(--space-smaller);
    position: absolute;
    padding: 0 var(--space-small);
    transition: transform 100ms linear;
    opacity: 0;
    transform: translateX(-1px);
    visibility: hidden;
  }

  .button.show {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}

.filters__button-wrap {
  position: relative;

  .filters__applied-indicator {
    position: absolute;
    height: var(--space-small);
    width: var(--space-small);
    top: var(--space-smaller);
    right: var(--space-slab);
    background-color: var(--s-500);
    border-radius: var(--border-radius-rounded);
  }
}

.btn_block {
  display: flex;
  margin-top: -1rem;
  margin-right: 1rem;
}

.shadow_custom {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05) !important;
}
</style>
