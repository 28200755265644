<template>
  <div
    :class="{ active: isActive }"
    class="action-box"
    @submit.prevent="addAgents"
  >
    <div class="closed-action-box">
      <p class="title">{{ title }}</p>
      <svg
        v-if="showContent"
        class="drop-down-icon"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="toggleMenu"
      >
        <path
          d="M14.88 5.92687L9.99 10.8169C9.4125 11.3944 8.4675 11.3944 7.89 10.8169L3 5.92687"
          stroke="#483A9D"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        v-else
        class="drop-down-icon"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#483A9D"
        @click="toggleMenu"
      >
        <path
          d="M3.12 12.0731L8.01 7.18312C8.5875 6.60562 9.5325 6.60562 10.11 7.18312L15 12.0731"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-show="!showContent" class="open-action-box">
      <div class="inputHolder">
        <woot-input
          v-show="showAddAmount"
          class="action-box-input"
          type="text"
          placeholder="Add amount"
        />
        <woot-input
          v-show="showFidelityPoints"
          class="action-box-input"
          type="text"
          placeholder="Fidelity Points"
        />
        <ExpirationDateInput v-show="showExpirationDate" />
        <woot-input
          v-show="showPhoneNumber"
          class="action-box-input"
          type="text"
          placeholder="Phone Number"
        />
        <woot-input
          v-show="showAmount"
          class="action-box-input"
          type="text"
          placeholder="amount"
        />
      </div>
      <woot-button class="action-box-btn" @click="openValidatePopup">
        <span class="button__content">{{ btnLabel }}</span>
      </woot-button>
    </div>
    <woot-modal :show.sync="showValidatePopup" :on-close="hideValidatePopup">
      <div class="cust_modal">
        <div class="text-center mb-5">
          <svg
            width="60"
            height="68"
            viewBox="0 0 60 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50 0.666626H10C4.46667 0.666626 0 5.09996 0 10.5666V46.9333C0 52.4 4.46667 56.8333 10 56.8333H12.5333C15.2 56.8333 17.7333 57.8666 19.6 59.7333L25.3 65.3666C27.9 67.9333 32.1333 67.9333 34.7333 65.3666L40.4333 59.7333C42.3 57.8666 44.8667 56.8333 47.5 56.8333H50C55.5333 56.8333 60 52.4 60 46.9333V10.5666C60 5.09996 55.5333 0.666626 50 0.666626ZM45.6 29L41.7 40.8666C41.2 42.8333 39.1 44.4333 36.9667 44.4333H30.8C29.7333 44.4333 28.2333 44.0666 27.5667 43.4L22.6667 39.5666C22.5667 41.7 21.6 42.6 19.2333 42.6H17.6333C15.1667 42.6 14.1667 41.6333 14.1667 39.3V23.4C14.1667 21.0666 15.1667 20.1 17.6333 20.1H19.2667C21.7333 20.1 22.7333 21.0666 22.7333 23.4V24.6L29.2 15C29.8667 13.9666 31.5667 13.2333 33 13.8C34.5667 14.3333 35.5667 16.0666 35.2333 17.6L34.4333 22.8C34.3333 23.2333 34.4333 23.7 34.7333 24C35 24.3 35.4 24.5 35.8333 24.5H42.3333C43.6 24.5 44.6667 25 45.3 25.9C45.9 26.7666 46 27.8666 45.6 29Z"
              fill="#27AF34"
            />
          </svg>
        </div>
        <div class="text-center mb-5">
          <!--        <h3 class="mb-3">Your team is ready!</h3>-->
          <span class="description_cust">
            Your Voucher has been generated succesfully !
          </span>
        </div>
        <woot-submit-button
          :button-text="finish_button"
          class="ml-2 float-center center_cust"
          @click="hideValidatePopup"
        />
      </div>
    </woot-modal>
  </div>
</template>

<script>
import ExpirationDateInput from './ExpirationDateInput';
import router from '../../index';
export default {
  name: 'ConversationActionsBox',
  components: { ExpirationDateInput },
  props: {
    title: String,
    btnLabel: String,
    showAmount: Boolean,
    showPhoneNumber: Boolean,
    showExpirationDate: Boolean,
    showFidelityPoints: Boolean,
    showAddAmount: Boolean,
  },
  data() {
    return {
      showContent: false,
      isActive: false,
      showValidatePopup: false,
      finish_button: 'Thanks',
    };
  },
  methods: {
    toggleMenu() {
      this.showContent = !this.showContent;
      this.isActive = !this.isActive;
    },
    // async addAgents() {
    //   // this.isCreating = true;
    //   // const { teamId, selectedAgents } = this;
    //   try {
    //     // await this.$store.dispatch('teamMembers/create', {
    //     //     teamId,
    //     //     agentsList: selectedAgents,
    //     // });
    //     this.openValidatePopup();
    //     // this.$store.dispatch('teams/get');
    //   } catch (error) {
    //     this.showAlert(error.message);
    //   }
    //   // this.isCreating = false;
    // },
    openValidatePopup() {
      this.showValidatePopup = true;
    },
    hideValidatePopup() {
      this.showValidatePopup = false;
    },
  },
};
</script>
<style scoped>
.closed-action-box {
  /*position: absolute;*/
  width: 100%;
  padding: 0px 10px 0px 5px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.open-action-box {
  height: fit-content;
  min-height: 116px;
}

.action-box {
  position: relative;
  right: 1.7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  height: auto;
  width: 298px;
  border-radius: 8px;
  padding: 10px 0px;
  background: #f9f9f9;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.active {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 52px !important;
}

.action-box .title {
  position: absolute;
  white-space: nowrap;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  height: 19px;
  width: 166px;
  color: #656877;
}

.drop-down-icon {
  left: 92%;
  position: absolute;
  cursor: pointer;
}

.action-box-btn {
  float: right;
  margin-right: 10px;
  height: 32px;
  width: 104px;
  bottom: auto;
  border-radius: 8px;
}

.action-box-btn span {
  height: 16px;
  width: 68px;
  /*font-family: Avenir;*/
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  left: 21.82%;
  right: 6.46%;
  top: 21.05%;
  bottom: 57.66%;
}

.inputHolder {
  position: relative;
  left: 2.2%;
  top: 20%;
}

.action-box-input {
  height: 45px;
  width: 285px;
  border-radius: 8px;
}
.center_cust {
    display: block !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

.description_cust {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 25px !important;
    line-height: 30px !important;
    text-align: center !important;
    color: #483A9D !important;
}

h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #456987;
}

.cust_modal {
    padding: 9rem !important;
}
/*Responsive mode*/

@media (max-width: 1400px) {
    .action-box {
        position: relative;
        right: 1.7%;
        width: 97%;
    }
    .action-box-input {
        height: 45px;
        width: 95%;
        border-radius: 8px;
    }
    .action-box-btn {
        left: 65%;
    }
}
@media (min-width: 1870px) {
    .action-box {
        position: relative;
        right: 1.7%;
        width: 97%;
    }
    .action-box-input {
        height: 45px;
        width: 95.5%;
        border-radius: 8px;
    }
    .action-box-btn {
        left: 71%;
    }
}


</style>
