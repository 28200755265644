<template>
    <span>
      Orders Content Goes Here
    </span>
</template>

<script>
export default {
  name: 'OrdersContent',
};
</script>

<style scoped>
span {
  position: absolute;
  left: 14%;
  top: 5%;
}
</style>
