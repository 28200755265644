import { frontendURL } from '../../../../helper/URLHelper';

const primaryMenuItems = accountId => [
    {
        icon: 'arrow-trending-lines',
        key: 'dashboard',
        label: 'DASHBOARD',
        toState: frontendURL(`accounts/${accountId}/reports/overview`),
        toStateName: 'home',
        roles: ['administrator'],
    },
  {
    icon: 'chat',
    key: 'conversations',
    label: 'CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'book-contacts',
    key: 'contacts',
    label: 'CONTACTS',
    toState: frontendURL(`accounts/${accountId}/contacts`),
    toStateName: 'contacts_dashboard',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'reports-nav',
    key: 'reports',
    label: 'REPORTS',
    toState: frontendURL(`accounts/${accountId}/reports/conversation`),
    toStateName: 'settings_account_reports',
    roles: ['administrator'],
  },
  {
    icon: 'megaphone',
    key: 'campaigns',
    label: 'CAMPAIGNS',
    toState: frontendURL(`accounts/${accountId}/campaigns`),
    toStateName: 'settings_account_campaigns',
    roles: ['administrator'],
  },
/*  {
    icon: 'library',
    key: 'helpcenter',
    label: 'HELP_CENTER.TITLE',
    featureFlag: 'help_center',
    toState: frontendURL(`accounts/${accountId}/portals`),
    toStateName: 'list_all_portals',
    roles: ['administrator'],
  },*/
  {
    icon: 'settings',
    key: 'settings',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings/agents/list`), /* to display agent list at first */
    toStateName: 'settings_home',
    roles: ['administrator', 'agent'],
  },
];

export default primaryMenuItems;
