<template>
    <span>
      General Informations Goes Here
    </span>
</template>

<script>
export default {
  name: 'GeneralInfoVue',
};
</script>

<style scoped>
span {
  position: absolute;
  left: 14%;
  top: 5%;
}
</style>
