<template>
  <span>
    Complaints Content Goes Here
  </span>
</template>

<script>
export default {
  name: 'ComplaintsContent',
};
</script>

<style scoped>
span {
  position: absolute;
  left: 14%;
  top: 5%;
}
</style>
