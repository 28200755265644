<template>
  <div class="fidelity_block mt-3 mb-3">
    <div class="voucher">
      <div class="ticket">
        <svg
          class="ticket_svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7395 20.0198L5.43618 14.7165C3.18228 12.4626 3.11864 11.0519 5.16571 8.89876C5.27178 8.7927 5.41497 8.72375 5.56346 8.72375C5.71195 8.72375 5.86044 8.77678 5.97181 8.88815C6.77261 9.68895 8.08253 9.68365 8.88332 8.88285C9.68412 8.08205 9.68943 6.77214 8.88863 5.97134C8.78256 5.86527 8.72422 5.72208 8.72423 5.56298C8.72423 5.40389 8.78787 5.266 8.89923 5.16524C11.0524 3.11816 12.4631 3.1818 14.717 5.43571L20.0203 10.739C22.359 13.0778 22.359 14.499 20.0203 16.8378L16.8383 20.0198C14.4995 22.3585 13.0782 22.3585 10.7395 20.0198ZM5.6271 10.0602C4.37022 11.4974 4.54522 12.2345 6.23167 13.921L11.535 19.2243C13.4336 21.1229 14.1495 21.1176 16.0428 19.2243L19.2248 16.0423C21.118 14.149 21.1233 13.4331 19.2248 11.5345L13.9215 6.2312C12.235 4.54475 11.4925 4.37504 10.0607 5.62662C10.9039 6.8729 10.7766 8.58056 9.67882 9.67835C8.58104 10.7761 6.87337 10.9034 5.6271 10.0602Z"
            fill="#483A9D"
          />
          <path
            d="M13.126 9.14803C12.9085 8.9306 12.9085 8.56997 13.126 8.35254L14.9821 6.49638C15.1996 6.27895 15.5602 6.27895 15.7776 6.49638C15.9951 6.71382 15.9951 7.07444 15.7776 7.29188L13.9215 9.14803C13.704 9.36547 13.3434 9.36547 13.126 9.14803Z"
            fill="#483A9D"
          />
          <path
            d="M6.49659 15.7774C6.27915 15.56 6.27915 15.1994 6.49659 14.9819L8.35274 13.1258C8.57018 12.9083 8.9308 12.9083 9.14824 13.1258C9.36567 13.3432 9.36567 13.7038 9.14824 13.9213L7.29208 15.7774C7.07465 15.9949 6.71402 15.9949 6.49659 15.7774Z"
            fill="#483A9D"
          />
          <path
            d="M11.3439 15.2151C11.2166 15.0878 11.1317 14.9181 11.0999 14.7378C11.0469 14.409 11.1848 14.0802 11.4605 13.8893L12.203 13.3695L12.1924 12.4574C12.1871 12.1233 12.3727 11.821 12.6644 11.6672C12.9614 11.5187 13.3167 11.5452 13.5819 11.7467L14.3137 12.2877L15.1781 11.996C15.4963 11.8899 15.8411 11.9695 16.0744 12.2028C16.3131 12.4415 16.3926 12.7862 16.2812 13.0991L15.9895 13.9635L16.5305 14.6954C16.732 14.9605 16.7585 15.3159 16.61 15.6128C16.4615 15.9098 16.154 16.0901 15.8198 16.0848L14.9077 16.0742L14.388 16.8167C14.197 17.0925 13.8682 17.2303 13.5394 17.1773C13.2106 17.1243 12.9455 16.8909 12.8447 16.578L12.5742 15.7136L11.7045 15.4378C11.5666 15.3954 11.4446 15.3159 11.3439 15.2151ZM13.322 12.9506L13.3273 13.4862C13.3326 13.7779 13.1947 14.0431 12.9614 14.2128L12.5265 14.5204L13.0356 14.6795C13.3114 14.7643 13.5235 14.9764 13.6084 15.2522L13.7675 15.7613L14.0751 15.3265C14.2501 15.0878 14.5152 14.9499 14.8016 14.9605L15.3372 14.9658L15.019 14.5416C14.8493 14.3082 14.8016 14.0059 14.8918 13.7355L15.0668 13.2317L14.563 13.4067C14.2925 13.4968 13.9902 13.4491 13.7569 13.2794L13.322 12.9506Z"
            fill="#483A9D"
          />
        </svg>
      </div>
      <div class="voucher_value">
        <div class="d-flex">
          <span class="voucher_title">Voucher :</span>
          <div class="value">
            <span class="price">300</span>
            <span class="currency">dt</span>
          </div>
        </div>
        <div class="expiry_date">
          Expiry : 17/12/22
        </div>
      </div>
    </div>
    <div class="fidelity_points">
      <span class="points">60 121</span>
      <span class="pts">pts</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FidelityPrograms',
};
</script>

<style scoped lang="scss">
h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--cv-primary);
}
.fidelity_block {
  display: flex;
  padding: 5px 10px;
  justify-content: space-around;
  .voucher {
    background: #fff;
    box-shadow: 0 2px rgb(0 78 159 / 5%);
    border-radius: 10px;
    display: flex;
    padding: 5px 10px;
    height: 40px;
    .ticket {
      border-right: 1px dashed #dad8eb;
      .ticket_svg {
        margin-top: 5px;
        margin-right: 5px;
      }
    }

    .value {
      .price {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--cv-primary);
      }
      .currency {
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 12px;
        color: var(--cv-primary);
        position: absolute;
        margin-left: 1px;
      }
    }

    .voucher_value {
      padding: 0 5px;
    }

    .voucher_title {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin-top: 2px;
      margin-right: 5px;
      margin-left: 5px;
      color: var(--cv-primary);
    }

    .expiry_date {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: #ff2c52;
      margin-left: 6px;
    }
  }

  .fidelity_points {
    background: #ffffff;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 12px 18px;
    height: 40px;

    .points {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: var(--cv-primary);
    }
    .pts {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--cv-primary);
    }
  }
}
</style>
