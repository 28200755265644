import { render, staticRenderFns } from "./AvatarUploader.vue?vue&type=template&id=75989c94&scoped=true&"
import script from "./AvatarUploader.vue?vue&type=script&lang=js&"
export * from "./AvatarUploader.vue?vue&type=script&lang=js&"
import style0 from "./AvatarUploader.vue?vue&type=style&index=0&id=75989c94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75989c94",
  null
  
)

export default component.exports